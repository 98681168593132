import { all } from 'redux-saga/effects';
import setTableInputSaga from './setTableInputValuesSaga';
import setFormInputSaga from './setFormInputValuesSaga';
import linkToCustomerTabSaga from './linkToCustomerTabSaga';

const sagas = [setTableInputSaga, setFormInputSaga, linkToCustomerTabSaga];

export default function*() {
  yield all(sagas.map(s => s()));
}
