import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import PieChartTemplate from './PieChartTemplate';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { pieWidgetInfo } from '../data';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { ResponsiveContainer } from 'recharts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      backgroundColor: '#FFF'
    },
    cardHeader: {
      paddingBottom: '0px',
      paddingTop: '10px',
      alignItems: 'flex-start'
    },
    cardHeaderController: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFF'
    },
    kpiValue: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.5em',
      backgroundColor: '#FFF'
    },
    updateLink: {
      color: '#00a3e0 ',
      textDecoration: 'none',
      fontSize: '0.75em',
      padding: '0px 10px 0px 0px',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: '20px'
    },
    chart: {
      backgroundColor: '#FFF',
      paddingBottom: '10px'
    },
    percentChange: {
      display: 'flex',
      backgroundColor: '#FFF',
      paddingRight: '0px',
      paddingTop: '8px',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  })
);

const getAccountPlanPieColors = (firstValue: number, secondValue: number) => {
  return firstValue >= secondValue
    ? ['#84bd00', '#F5F5F5']
    : ['#EB5858', '#F5F5F5'];
};

export const getSOWPieColors = (firstValue: number) => {
  if (firstValue <= 25) {
    return ['#EB5858', '#F5F5F5'];
  } else if (firstValue > 25 && firstValue < 39) {
    return ['#ffbf3f', '#F5F5F5'];
  } else if (firstValue >= 39) {
    return ['#84bd00', '#F5F5F5'];
  } else return ['#EB5858', '#F5F5F5'];
};

const getAccountPlanLabelColors = (firstValue: number, secondValue: number) => {
  return firstValue >= secondValue ? '#84bd00' : '#EB5858';
};

const getSOWLabelColors = (firstValue: number) => {
  return firstValue >= 25 ? '#84bd00' : '#EB5858';
};

// TODO: remove hardcoding for colors in favor of getting this from config
const SmallWidgetTemplate = (props: any) => {
  const widgetData = props.getData();
  const updateLink = widgetData.updateLink;
  const kpiTitle = widgetData.kpiTitle;
  const pieColor =
    widgetData.id === 'accountPlanning'
      ? getAccountPlanPieColors(
          widgetData.widgetData[1].value,
          widgetData.widgetData[0].value
        )
      : getSOWPieColors(widgetData.widgetData[0].value);
  const kpiValue = widgetData.widgetData[0].value;
  const data = widgetData.widgetData;
  const labelColor =
    widgetData.id === 'accountPlanning'
      ? getAccountPlanLabelColors(
          widgetData.widgetData[1].value,
          widgetData.widgetData[0].value
        )
      : getSOWLabelColors(widgetData.widgetData[0].value);
  const showPercentChange = widgetData.showPercentChange;

  const classes = useStyles();

  return (
    <Card>
      <div className={classes.cardHeaderController}>
        <CardHeader
          className={classes.cardHeader}
          titleTypographyProps={{
            style: {
              fontSize: '.75em',
              display: 'flex',
              paddingBottom: '0px'
            }
          }}
          title={kpiTitle}
        />
        <div className={classes.percentChange}>
          <Link
            className={classes.updateLink}
            component={RouterLink}
            to={updateLink}
          >
            Update
          </Link>
          {showPercentChange ? (
            <span
              style={{
                color: labelColor,
                fontSize: '1.2em',
                paddingTop: '7px',
                marginLeft: '10px'
              }}
            >
              {labelColor === '#84bd00' ? (
                <ArrowUpward style={{ fontSize: '1em' }} />
              ) : (
                <ArrowDownward style={{ fontSize: '1em' }} />
              )}
              {pieWidgetInfo[1].percentChange}
            </span>
          ) : (
            <span style={{ paddingTop: '36px' }}> </span>
          )}
        </div>
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer height={150}>
          <PieChartTemplate
            data={data}
            kpiValue={kpiValue}
            pieColor={pieColor}
            labelColor={labelColor}
            pieSize={{ innerRadius: 45, outerRadius: 70 }}
            pieContainerSize={{ width: 350, height: 150 }}
            label={true}
          />
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default SmallWidgetTemplate;
