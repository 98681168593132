import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import '../index.css';

import Grid from '@material-ui/core/Grid';

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: '#FFF',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#000',
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `0px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#000'
  },
  checked: {}
}))(Switch);

const DollarsUnitsToggleTemplate = (props: any) => {
  const setSelectedDollarsUnitsToggle = props.setSelectedDollarsUnitsToggle;
  const selectedDollarsUnitsToggle = props.selectedDollarsUnitsToggle;

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDollarsUnitsToggle({
      ...selectedDollarsUnitsToggle,
      [name]: event.target.checked
    });
  };

  return (
    <Grid component="label" container alignItems="flex-end" spacing={1}>
      <Grid
        style={{
          height: '24px',
          fontSize: '.6em',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
        }}
        item
      >
        Units
      </Grid>
      <Grid item>
        <AntSwitch
          checked={selectedDollarsUnitsToggle.checked}
          onChange={handleChange('checked')}
          value="checked"
        />
      </Grid>
      <Grid
        style={{
          height: '24px',
          fontSize: '.60em',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
        }}
        item
      >
        $
      </Grid>
    </Grid>
  );
};

export default DollarsUnitsToggleTemplate;
