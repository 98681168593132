import React, { useState } from 'react';
import {
  Modal,
  createStyles,
  makeStyles,
  List,
  ListItem,
  ListSubheader
} from '@material-ui/core';
import { CustomerFilterData, getAllGrowerInfo } from '../data';
import AlphabetIndex from './AlphabetIndex';
import FilterRadio from './FilterRadio';
import * as _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

const growerInfo = _.uniqBy(getAllGrowerInfo(), 'growerName');

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      boxShawdow: 'none !important',
      textAlign: 'left'
    },
    filter: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    modal: {
      display: 'flex',
      width: '65%',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      scrollBehavior: 'smooth'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    listSection: {
      fontSize: '3em'
    },
    options: {
      color: '#808080',
      fontSize: '.24em',
      cursor: 'pointer',
      '&:hover': {
        color: 'black'
      },
      textTransoform: 'lowercase'
    },
    ul: {
      padding: '0px'
    }
  })
);

const makeOptions = (actorType: any) => {
  // TODO: abstract out hardcoded retailer vs grower when data and config are available
  return actorType === 'RETAILER'
    ? alphabet.filter((letter: string) =>
        _.flatten(CustomerFilterData.map((item: any) => item.locations)).find(
          (item: any) => item[0] === letter
        )
      )
    : alphabet.filter((letter: string) =>
        growerInfo.find((item: any) => item.growerName[0] === letter)
      );
};

const CustomerTitleFilterTemplate = (props: any) => {
  const selectedFilter = props.selectedFilter;
  const setSelectedCustomerTitleFilter = props.setSelectedCustomerTitleFilter;

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [actorType, setActorType] = useState('RETAILER');
  const [selectedAlphabetIndex, setSelectedAlphabetIndex] = useState('');
  const [alphabetIndexOptions, setAlphabetIndexOptions] = useState(
    makeOptions(actorType)
  );

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleFilterClick = (location: string, actorType: string) => {
    setSelectedCustomerTitleFilter(location, actorType);
    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <div onClick={handleModalOpen} style={{ wordBreak: 'keep-all' }}>
        <span
          className={classes.filter}
          style={{ fontSize: '2em', textTransform: 'capitalize' }}
        >
          {(selectedFilter
            ? selectedFilter
            : CustomerFilterData[4].locations[0]
          ).toLowerCase()}{' '}
          <ExpandMoreIcon />
        </span>
        {selectedFilter ? null : (
          <p style={{ color: '#B7B7B7', margin: '0px' }}>Default Customer</p>
        )}
      </div>
      <Modal open={isOpen} onClose={handleModalClose} className={classes.modal}>
        <div className={classes.paper}>
          <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '2em', textTransform: 'capitalize' }}>
                {(selectedFilter
                  ? selectedFilter
                  : CustomerFilterData[4].locations[0]
                ).toLowerCase()}
              </span>
              {selectedFilter ? null : (
                <p style={{ color: '#B7B7B7', margin: '0px' }}>
                  Default Customer
                </p>
              )}
            </div>
            <div style={{ display: 'grid', justifyItems: 'end' }}>
              <FilterRadio
                filter={actorType}
                setFilter={setActorType}
                setOptions={setAlphabetIndexOptions}
                makeOptions={makeOptions}
              />
            </div>
          </div>
          <AlphabetIndex
            changeIndex={setSelectedAlphabetIndex}
            options={alphabetIndexOptions}
          />
          {actorType === 'RETAILER' ? (
            <List className={classes.root} subheader={<li />}>
              {CustomerFilterData.filter((item: any) => {
                return selectedAlphabetIndex !== ''
                  ? item.customerName[0] === selectedAlphabetIndex
                  : true;
              }).map((customer: any, customerIndex: number) => (
                <li
                  key={`${customer.customerName}`}
                  className={classes.listSection}
                >
                  <ul className={classes.ul}>
                    <ListSubheader
                      style={{
                        fontSize: '.40em',
                        color: 'black',
                        fontWeight: 500,
                        padding: '0'
                      }}
                    >{`${customer.customerName}`}</ListSubheader>
                    {customer.locations.map(
                      (location: any, locationIndex: number) => (
                        <ListItem
                          className={classes.options}
                          dense
                          key={`${customerIndex}-${locationIndex}`}
                          value={location}
                          onClick={() => handleFilterClick(location, actorType)}
                        >
                          <div style={{ textTransform: 'capitalize' }}>
                            {location.toLowerCase()}
                          </div>
                        </ListItem>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </List>
          ) : (
            <List className={classes.root}>
              {growerInfo
                .filter((item: any) => {
                  return selectedAlphabetIndex !== ''
                    ? _.isUndefined(item.name)
                      ? true
                      : item.name[0] === selectedAlphabetIndex
                    : true;
                })
                .map((customer: any, customerIndex: number) => (
                  <ListItem
                    className={classes.options}
                    dense
                    key={customerIndex}
                    value={customer.growerName}
                    onClick={() => {
                      handleFilterClick(customer.growerName, actorType);
                    }}
                  >
                    <div style={{ textTransform: 'capitalize' }}>
                      {customer.growerName.toLowerCase()}
                    </div>
                  </ListItem>
                ))}
            </List>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomerTitleFilterTemplate;
