import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { getProductInfo } from '../data/generatedBaseData';
import YearToggleTemplate from './YearToggleTemplate';
import YearToggleContentTemplate, {
  getBarColors
} from './YearToggleContentTemplate';
import DollarsUnitsToggleTemplate from './DollarsUnitsToggleTemplate';
import Link from '@material-ui/core/Link';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 800,
      backgroundColor: '#FFF'
    },
    cardHeader: {
      paddingBottom: '0px'
    },
    cardHeaderController: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFF'
    },
    kpiValue: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.5em',
      backgroundColor: '#FFF'
    },
    number: {
      color: 'black',
      fontWeight: 'bold',
      paddingLeft: '16px'
    },
    productPercentChange: {
      display: 'flex',
      flexDirection: 'column'
    },
    toggleControler: {
      display: 'flex',
      width: '100%',
      backgroundColor: '#FFF'
    },
    reportLink: {
      color: '#00a3e0 ',
      textDecoration: 'none',
      fontSize: '0.75em',
      display: 'flex',
      padding: '16px 10px 0px 0px'
    },
    chart: {
      display: 'flex',
      width: '100%',
      paddingLeft: '2%'
    }
  })
);

const LargeWidgetTemplate = (props: any) => {
  const { widget, title } = props;
  const [selectedYearToggle, setSelectedYearToggle] = useState(0);
  const [selectedDollarsUnitsToggle, setSelectedDollarsUnitsToggle] = useState({
    checked: true
  });

  const widgetId = widget.id;
  const kpiTotalValues = widget.kpiValue;
  const showDollarsUnitsToggle = widget.dollarsUnitsToggle;
  const reportLink = widget.reportLink;

  const [stateData, setStateData] = useState(
    props.getData(
      widgetId,
      selectedYearToggle,
      selectedDollarsUnitsToggle,
      props.selectedFilter
    )
  );
  const [productInfo, setProductInfo] = useState(
    props.getData(
      widgetId,
      selectedYearToggle,
      selectedDollarsUnitsToggle,
      props.selectedFilter
    )
  );

  const getTotalValue = () => {
    if (selectedYearToggle === 0) {
      if (selectedDollarsUnitsToggle.checked) {
        return Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        }).format(kpiTotalValues.currentDollarNumber);
      }
      return Intl.NumberFormat('en').format(kpiTotalValues.currentUnitNumber);
    }
    if (selectedYearToggle === 1) {
      if (selectedDollarsUnitsToggle.checked) {
        return Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        }).format(kpiTotalValues.previousDollarNumber);
      }
      return Intl.NumberFormat('en').format(kpiTotalValues.previousUnitNumber);
    }
  };
  const getTotalPercentChange = () => {
    if (selectedYearToggle === 0) {
      if (selectedDollarsUnitsToggle.checked) {
        return kpiTotalValues.currentDollarPercentChange;
      }
      return kpiTotalValues.currentUnitPercentChange;
    }
    if (selectedYearToggle === 1) {
      if (selectedDollarsUnitsToggle.checked) {
        return kpiTotalValues.previousDollarPercentChange;
      }
      return kpiTotalValues.previousUnitPercentChange;
    }
  };

  const getComparables = () => {
    if (selectedYearToggle === 0) {
      if (selectedDollarsUnitsToggle.checked) {
        return [
          kpiTotalValues.currentDollarNumber,
          kpiTotalValues.previousDollarNumber
        ];
      }
      return [
        kpiTotalValues.currentUnitsNumber,
        kpiTotalValues.previousUnitsNumber
      ];
    }
    if (selectedYearToggle === 1) {
      if (selectedDollarsUnitsToggle.checked) {
        return [
          kpiTotalValues.previousDollarNumber,
          kpiTotalValues.currentDollarNumber
        ];
      }
    }
    return [
      kpiTotalValues.previousUnitNumber,
      kpiTotalValues.currentUnitNumber
    ];
  };
  const [comparator_one, comparator_two] = getComparables();
  const percentColor = getBarColors(comparator_one, comparator_two);

  useEffect(() => {
    setStateData(
      props.getData(
        widgetId,
        selectedYearToggle,
        selectedDollarsUnitsToggle,
        props.selectedFilter
      )
    );
    setProductInfo(getProductInfo(props.selectedFilter));
  }, [
    selectedDollarsUnitsToggle,
    selectedYearToggle,
    widgetId,
    props.selectedFilter
  ]);
  const classes = useStyles();
  return (
    <Card id={widgetId} className={classes.card}>
      <div className={classes.cardHeaderController}>
        <CardHeader
          className={classes.cardHeader}
          titleTypographyProps={{
            style: {
              fontSize: '.75em',
              display: 'flex',
              paddingBottom: '0px'
            }
          }}
          title={
            selectedDollarsUnitsToggle.checked
              ? title === 'Diagnostic Spend'
                ? `${title}`
                : `${title} Dollars`
              : `${title} Units`
          }
        />
        <Link
          className={classes.reportLink}
          component={RouterLink}
          to={reportLink}
        >
          View Report
        </Link>
      </div>

      <div className={classes.kpiValue}>
        <span className={classes.number}>
          {selectedDollarsUnitsToggle.checked
            ? getTotalValue()
            : getTotalValue()}
        </span>
        <span
          style={{
            color: percentColor,
            fontSize: '.75em',
            padding: '5px 10px 0 0'
          }}
        >
          {percentColor === '#84bd00' ? (
            <ArrowUpward style={{ fontSize: '1em' }} />
          ) : (
            <ArrowDownward style={{ fontSize: '1em' }} />
          )}
          {getTotalPercentChange().toFixed(0) + '%'}
        </span>
      </div>
      <div className={classes.toggleControler}>
        <YearToggleTemplate
          selectedYearToggle={selectedYearToggle}
          setSelectedYearToggle={setSelectedYearToggle}
        />
        {showDollarsUnitsToggle ? (
          <DollarsUnitsToggleTemplate
            selectedDollarsUnitsToggle={selectedDollarsUnitsToggle}
            setSelectedDollarsUnitsToggle={setSelectedDollarsUnitsToggle}
          />
        ) : null}
      </div>
      <YearToggleContentTemplate
        selectedYearToggle={selectedYearToggle}
        stateData={stateData}
        selectedDollarsUnitsToggle={selectedDollarsUnitsToggle}
        showDollarsUnitsToggle={widget.dollarsUnitsToggle}
        selectedFilter={props.selectedFilter}
        productInfo={productInfo}
      />
    </Card>
  );
};

export default LargeWidgetTemplate;
