import { select, takeEvery, put } from 'redux-saga/effects';
import { setStagedFormInputValuesState } from '../actions';
import { getApplicationForms } from '../selectors';
import _ from 'lodash';

function* setFormInput({ formId, formField, value, isRequired }: any) {
  const applicationForms = yield select(getApplicationForms);
  const stagedApplicationForms = applicationForms.stagedApplicationForms;
  const form = _.has(stagedApplicationForms, `${formId}`)
    ? stagedApplicationForms[formId]
    : {};
  const otherFormFields = _.has(stagedApplicationForms, `${formId}.formFields`)
    ? stagedApplicationForms[formId].formFields
    : {};
  const newState = yield Object.assign(
    {},
    {
      ...applicationForms,
      stagedApplicationForms: {
        ...stagedApplicationForms,
        [formId]: {
          ...form,
          formFields: {
            ...otherFormFields,
            [formField]: { value: value, required: isRequired }
          }
        }
      }
    }
  );

  //check old state (row array and length). assign ID as the length of the array.

  const newStateRow = _.has(
    newState,
    `stagedApplicationForms.${formId}.formFields`
  )
    ? newState.stagedApplicationForms[formId].formFields
    : {};

  const newStateRowValues = Object.values(newStateRow);
  const newStateRowValuesArray = newStateRowValues.map(
    (values: any) => values.value
  );
  const newStateWithUpdatedRows = yield Object.assign(
    {},
    {
      ...newState,
      stagedApplicationForms: {
        ...newState.stagedApplicationForms,
        [formId]: {
          ...(_.has(newState, `stagedApplicationForms.${formId}`)
            ? newState.stagedApplicationForms[formId]
            : {}),
          rowArray: [...newStateRowValuesArray]
        }
      }
    }
  );

  yield put(setStagedFormInputValuesState(newStateWithUpdatedRows));
}

function* setFormInputSaga() {
  // yield debounce(500, 'SET_FORM_INPUT_VALUES', setFormInput);
  yield takeEvery('SET_FORM_INPUT_VALUES', setFormInput);
}

export default setFormInputSaga;
