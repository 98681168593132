import { connect } from 'react-redux';
import { setTableInputValues, linkToCustomerTab } from '../actions';
import TableTemplate from './TableTemplate';
import { getApplicationTables } from '../selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    applicationTables: getApplicationTables(state),
    selectedFilter: state.filters.selectedFilter,
    data: ownProps.data
      ? ownProps.data
      : ownProps.getData(state.filters.selectedFilter)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setTableInputValues: (
    tableId: string,
    rowId: string,
    columnId: string,
    value: string
  ) => dispatch(setTableInputValues(tableId, rowId, columnId, value)),
  linkToCustomerTab: (topNavTab: number, subNavTab: number, filterValue: any) =>
    dispatch(linkToCustomerTab(topNavTab, subNavTab, filterValue))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableTemplate);
