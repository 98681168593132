import { connect } from 'react-redux';
import FilterToggleTemplate from './FilterToggleTemplate';
import { setFilter } from '../../actions';
import * as config from '../../config/config.json';

const mapStateToProps = (state: any, ownProps: any) => ({
  filters: state.filters,
  selected: state.filters[ownProps.filterName].selected,
  filterConfig: config.filters.find(
    (filterConfig: any) => ownProps.filterName === filterConfig.name
  )
});

const mapDispatchToProps = (dispatch: any) => ({
  setFilter: (filterName: string, value: any) =>
    dispatch(setFilter(filterName, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterToggleTemplate);
