import { connect } from 'react-redux';
import { setSelectedCustomerTab } from '../actions';
import CustomerTabTemplate from './CustomerTabTemplate';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: any) => ({
  selectedCustomerTab: state.customerTab.selectedCustomerTab
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedCustomerTab: (newCustomerTab: any) =>
    dispatch(setSelectedCustomerTab(newCustomerTab))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerTabTemplate));
