import { connect } from 'react-redux';
import { setSubscription } from '../actions';
import SubscribeToProgramsTemplate from './SubscribeToProgramsTemplate';

const mapStateToProps = (state: any, ownProps: any) => ({
  subscriptions: state.programs.subscriptions,
  selectedFilter: state.filters.selectedFilter,
  data: ownProps.data
    ? ownProps.data
    : ownProps.getData(state.filters.selectedFilter)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSubscription: (subscriptionId: number, subscribed: boolean) =>
    dispatch(setSubscription(subscriptionId, subscribed))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeToProgramsTemplate);
