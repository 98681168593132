import React, { Fragment } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, Link } from 'react-router-dom';
// import routes from './Routes';
import { TabHorizontalPanel } from './Routes';
import _ from 'lodash';
// import config from '../config/config.json';

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: '#F5F5F5'
    }
  })
);

const CustomerTabTemplate = (props: any) => {
  const setSelectedCustomerTab = props.setSelectedCustomerTab;
  const navId = props.navId;
  const location = props.location;
  const nestedRoutes = props.nestedRoutes;
  // TODO: (Oct 2019 - CEP) - refactor
  // so that we can use actions triggered elsewhere (such as a link from another module)
  // to link to this module and choose the topnav and subnav to land on...
  // probably wanna use redux state, but maybe there is a solution using
  // location.state or both together that we need to find
  if (!location.state || !location.state.selectedTopNavTab) {
    Object.assign(location, {
      state: {
        ..._.get(location, 'state', null),
        selectedTopNavTab: 0
      }
    });
  }
  if (!location.state || !location.state.selectedSubNavTab) {
    Object.assign(location, {
      state: {
        ..._.get(location, 'state', null),
        selectedSubNavTab: 0
      }
    });
  }
  const match = props.match;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedCustomerTab(newValue);
  };

  const setTabsState = (index: number) => {
    // const customerEnablementIndex = _.findIndex(
    //   config.routes,
    //   (item: any) => item.title === 'Customer Enablement'
    // );
    // const accountPlanningIndex = config.routes[2].routes.findIndex(
    //   (item: any) => item.title === 'Account Planning'
    // );
    // const programPaymentsIndex = config.routes[2].routes.findIndex(
    //   (item: any) => item.title === 'ProgramPayments'
    // );

    switch (navId) {
      case 'topNav':
        return {
          selectedTopNavTab: index,
          selectedSubNavTab: 0
        };
      case 'accountSubNav':
        return {
          selectedSubNavTab: index
        };
      case 'paymentSubNav':
        return {
          selectedSubNavTab: index
        };
      default:
        return 0;
    }
  };

  // const getNestedRoutes = (navId: string) => {
  //   switch (navId) {
  //     case 'topNav':
  //       return routes[3].nestedRoutes;
  //     case 'accountSubNav':
  //       return routes[3].nestedRoutes[2].nestedRoutes;
  //     case 'paymentSubNav':
  //       return routes[3].nestedRoutes[6].nestedRoutes;
  //     default:
  //       return routes[3].nestedRoutes;
  //   }
  // };

  return (
    <div
      className={classes.root}
      style={
        navId === 'topNav'
          ? { width: '100%' }
          : { width: '100%', margin: 0, display: 'flex' }
      }
    >
      <div style={{ marginRight: '10px', width: '89vw' }}>
        <Fragment>
          <Tabs
            value={
              navId === 'topNav'
                ? location.state.selectedTopNavTab
                : location.state.selectedSubNavTab
            }
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#046a39'
              }
            }}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            style={
              navId === 'topNav'
                ? { borderBottom: ' 1px solid #dbdbdb', width: '100%' }
                : { borderBottom: 'none', width: '50%' }
            }
          >
            {nestedRoutes.map((route: any, index: any) => {
              return (
                <Tab
                  style={{ display: 'flex', flexGrow: 1 }}
                  key={route.tabLabel}
                  label={
                    <span
                      style={
                        navId === 'topNav'
                          ? { fontSize: '2em', textTransform: 'capitalize' }
                          : { fontSize: '1.5em', textTransform: 'capitalize' }
                      }
                    >
                      {route.tabLabel}
                    </span>
                  }
                  component={Link}
                  to={{
                    pathname: `${match.path}${route.path}`,
                    state: { ...location.state, ...setTabsState(index) }
                  }}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
          <Switch>
            {nestedRoutes.map((route: any, index: any) => {
              return (
                <Route
                  key={`${match.path}${route.path}`}
                  exact={route.exact}
                  path={`${match.path}${route.path}`}
                  component={() => (
                    <TabHorizontalPanel
                      value={index}
                      index={index}
                      children={route.children}
                    />
                  )}
                />
              );
            })}
          </Switch>
        </Fragment>
      </div>
    </div>
  );
};
export default CustomerTabTemplate;
