import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import aggioLogo from './../assets/aggioLogo.png';
// import aggioLogo from './../assets/BI.png';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { userInfo, userDropDownItems, logo } from '../data';

const userName = userInfo[0].firstName + ' ' + userInfo[0].lastName;
const userInitials = () => {
  return userInfo.map(
    (initial: any) => initial.firstName.charAt(0) + initial.lastName.charAt(0)
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 3,
    marginLeft: '6em',
    width: '100vw'
  },
  logo: {
    height: '2.5em'
  },
  userAccountMenu: {
    width: '60%',
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'lowercase'
  },
  avatar: {
    margin: 10
  },
  search: {
    width: '90%',
    flexGrow: 2,
    padding: '1px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10%',
    justifyContent: 'flex-end'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#046a39 !important'
  },
  cssFocused: {
    color: '#046a39 !important'
  },
  cssLabel: {
    color: 'grey'
  }
});

const TopLevelHeaderTemplate = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchClick = () => {
    return window.location.reload();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          <img className={classes.logo} src={logo} alt="logo" />
          <div className={classes.search}>
            <TextField
              style={{ borderRadius: '0px', margin: '0px', width: '30em' }}
              id="filled-search"
              label={<span>Search</span>}
              type="search"
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  focused: classes.cssFocused
                }
              }}
            />
            <SearchIcon
              onClick={searchClick}
              style={{
                padding: '5px 7px 9px 9px',
                backgroundColor: '#046a39',
                borderRadius: '0px 5px 5px 0px',
                border: 'solid 1px grey',
                cursor: 'pointer'
              }}
            />
          </div>
          <div className={classes.userAccountMenu}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ textTransform: 'none' }}
            >
              <Avatar
                className={classes.avatar}
                style={{ textTransform: 'uppercase' }}
              >
                <span>{userInitials()}</span>
              </Avatar>
              <div style={{ wordBreak: 'keep-all' }}>
                <p style={{ fontSize: '14px', margin: '0px' }}>{userName}</p>
                {/* <p
                  style={{ fontSize: '10px', margin: '0px', textAlign: 'left' }}
                >
                  {userRole}
                </p> */}
              </div>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: '10px' }}
              />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ margin: '50px 0px 0px 50px', width: '200px' }}
            >
              {userDropDownItems.map((item: any) => (
                <MenuItem
                  style={{ fontSize: '14px', width: '200px' }}
                  key={item.label}
                  onClick={handleClose}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopLevelHeaderTemplate;
