import * as React from 'react';
import * as d3 from 'd3';

interface IProps {
  rowIndex: number;
  tableId: any;
  max: number;
  value: number;
  label: any;
  width: number;
  color: any;
}

class HorizontalBar extends React.Component<IProps, any> {
  public componentDidMount() {
    this.makeBar();
  }
  private makeBar() {
    const svg = d3
      .select(`#horizontal-bar-${this.props.tableId}-${this.props.rowIndex}`)
      .append('svg')
      .attr('width', this.props.width)
      .attr('height', 20);
    svg.selectAll('*').remove();

    svg
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('x', 0)
      .attr('y', 4)
      .attr('height', 10)
      .attr('width', (this.props.value / this.props.max) * this.props.width * 4)
      .attr('fill', this.props.color);

    svg
      .append('text')
      .attr('x', (this.props.value / this.props.max) * this.props.width * 4 + 2)
      .attr('y', 16)
      .attr('text-anchor', 'left')
      .text(this.props.label);
  }

  public render() {
    return (
      <div id={`horizontal-bar-${this.props.tableId}-${this.props.rowIndex}`} />
    );
  }
}

export default HorizontalBar;
