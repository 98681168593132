import salesData from './demo/sales.json';
import programJson from './demo/programs.json';
import _ from 'lodash';

function roundToPrecision(value: number) {
  const roundedString = value.toFixed(2);
  return parseFloat(roundedString);
}

export const getProductInfo = (retailer: string) => {
  const isRetailer =
    salesData.find((sale: any) => sale.retailer === retailer) !== undefined;
  const retailerData = salesData.filter(
    (sale: any) => (isRetailer ? sale.retailer : sale.grower) === retailer
  );
  const products: string[] = [];
  retailerData
    .map((sale: any) => sale.productType)
    .forEach((productType: string) => {
      if (products.indexOf(productType) < 0) {
        products.push(productType);
      }
    });
  // products = ['grain corn', 'soybeans' etc]

  const productInfo = products.map((productType: string, index: number) => {
    const productSales = retailerData.filter(
      (sale: any) => sale.productType === productType
    );
    const baseSums = {
      id: index,
      productType: productType,
      shareOfWallet: roundToPrecision(
        _.sumBy(productSales, (sale: any) => sale.shareOfWallet) /
          productSales.length
      ),
      opportunity: _.sumBy(productSales, (sale: any) => sale.opportunity),
      currentYearUnits: _.sumBy(productSales, (sale: any) => sale.cy_orders),
      currentYearDollars: _.sumBy(productSales, (sale: any) => sale.cy_dollars),
      previousYearUnits: _.sumBy(productSales, (sale: any) => sale.py_units),
      previousYearDollars: _.sumBy(
        productSales,
        (sale: any) => sale.py_dollars
      ),
      currentYearShipped: _.sumBy(productSales, (sale: any) => sale.cy_shipped)
    };

    return {
      ...baseSums,
      currentYearUnitPercentChange: Math.round(
        (baseSums.currentYearUnits / baseSums.previousYearUnits - 1) * 100
      ),
      currentYearDollarPercentChange: Math.round(
        (baseSums.currentYearDollars / baseSums.previousYearDollars - 1) * 100
      ),
      previousYearUnitPercentChange: Math.round(
        (baseSums.previousYearUnits / baseSums.currentYearUnits - 1) * 100
      ),
      previousYearDollarPercentChange: Math.round(
        (baseSums.previousYearDollars / baseSums.currentYearDollars - 1) * 100
      ),
      aggioEstimatedPurchases: _.sumBy(
        productSales,
        (sale: any) => sale.aggioEstimatedPurchases
      )
    };
  });
  return productInfo;
};

export const getGrowerProductInfo = (grower: string) => {
  const retailerData = salesData.filter((sale: any) => sale.grower === grower);
  const products: string[] = [];
  retailerData
    .map((sale: any) => sale.productType)
    .forEach((productType: string) => {
      if (products.indexOf(productType) < 0) {
        products.push(productType);
      }
    });
  // products = ['grain corn', 'soybeans' etc]

  const productInfo = products.map((productType: string, index: number) => {
    const productSales = retailerData.filter(
      (sale: any) => sale.productType === productType
    );
    const baseSums = {
      id: index,
      productType: productType,
      currentYearUnits: _.sumBy(productSales, (sale: any) => sale.cy_orders),
      currentYearDollars: _.sumBy(productSales, (sale: any) => sale.cy_dollars),
      previousYearUnits: _.sumBy(productSales, (sale: any) => sale.py_units),
      previousYearDollars: _.sumBy(
        productSales,
        (sale: any) => sale.py_dollars
      ),
      currentYearShipped: _.sumBy(productSales, (sale: any) => sale.cy_shipped),
      aggioEstimatedPurchases: _.sumBy(
        productSales,
        (sale: any) => sale.aggioEstimatedPurchases
      )
    };

    return {
      ...baseSums,
      currentYearUnitPercentChange: Math.round(
        (baseSums.currentYearUnits / baseSums.previousYearUnits - 1) * 100
      ),
      currentYearDollarPercentChange: Math.round(
        (baseSums.currentYearDollars / baseSums.previousYearDollars - 1) * 100
      ),
      previousYearUnitPercentChange: Math.round(
        (baseSums.previousYearUnits / baseSums.currentYearUnits - 1) * 100
      ),
      previousYearDollarPercentChange: Math.round(
        (baseSums.previousYearDollars / baseSums.currentYearDollars - 1) * 100
      )
    };
  });
  return productInfo;
};

export const getRetailerProductCategoryByProductType = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const productGroup = _.groupBy(filteredData, (sale: any) => sale.productType);

  const groupedData: any = {};
  Object.keys(productGroup).forEach(
    (retailer: any) =>
      (groupedData[retailer] = _.groupBy(
        productGroup[retailer],
        (sale: any) => sale.productCategory
      ))
  );
  const productGrouping = Object.keys(groupedData).map((product: any) => ({
    productType: product,
    productCategory: Object.keys(groupedData[product]).map((category: any) => ({
      name: category,
      previousYearSales: _.sumBy(
        groupedData[product][category],
        (sale: any) => sale.py_dollars
      ),
      previousYearUnits: _.sumBy(
        groupedData[product][category],
        (sale: any) => sale.py_units
      ),
      currentYearOrders: _.sumBy(
        groupedData[product][category],
        (sale: any) => sale.cy_orders
      ),
      currentYearShipped: _.sumBy(
        groupedData[product][category],
        (sale: any) => sale.cy_shipped
      ),
      currentYearDollars: _.sumBy(
        groupedData[product][category],
        (sale: any) => sale.cy_dollars
      )
    }))
  }));
  return productGrouping;
};

export const getRetailerAllProductInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const productGroup = _.groupBy(filteredData, (sale: any) => sale.productType);

  const groupedData: any = {};
  Object.keys(productGroup).forEach(
    (retailer: any) =>
      (groupedData[retailer] = _.groupBy(
        productGroup[retailer],
        (sale: any) => sale.productCategory
      ))
  );

  const productGrouping = Object.keys(groupedData).map((product: any) => ({
    productType: product,
    productCategory: Object.keys(groupedData[product]).map((category: any) => ({
      name: category,
      productDetails: _(groupedData[product][category])
        .groupBy('productName')
        .map((product: any, name: string) => ({
          productName: name,
          previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
          previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
          currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
          currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
          currentYearDollars: _.sumBy(product, (sale: any) => sale.cy_dollars)
        }))
        .value()
    }))
  }));
  return productGrouping;
};

export const getGrowerProductNameInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const groupedData = _.groupBy(filteredData, (sale: any) => sale.grower);

  const productGrouping = Object.keys(groupedData).map((grower: any) => ({
    name: grower,
    products: _(groupedData[grower])
      .groupBy('productType')
      .map((product: any, name: string) => ({
        productType: name,
        productName: _(product)
          .groupBy('productName')
          .map((prodName: any, name: string) => ({
            name: name,
            aggioEstimatedPurchases: _.sumBy(
              prodName,
              (sale: any) => sale.aggioEstimatedPurchases
            ),
            shareOfWallet: roundToPrecision(
              _.sumBy(prodName, (sale: any) => sale.shareOfWallet) /
                prodName.length
            ),
            opportunity: _.sumBy(prodName, (sale: any) => sale.opportunity),
            previousYearSales: _.sumBy(
              prodName,
              (sale: any) => sale.py_dollars
            ),
            previousYearUnits: _.sumBy(prodName, (sale: any) => sale.py_units),
            currentYearOrders: _.sumBy(prodName, (sale: any) => sale.cy_orders),
            currentYearShipped: _.sumBy(
              prodName,
              (sale: any) => sale.cy_shipped
            ),
            currentYearValue: _.sumBy(prodName, (sale: any) => sale.cy_dollars)
          }))
          .value()
      }))
      .value()
  }));
  return productGrouping;
};

export const getSellerProductNameInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const groupedData = _.groupBy(filteredData, (sale: any) => sale.seller);

  const productGrouping = Object.keys(groupedData).map((seller: any) => ({
    name: seller,
    products: _(groupedData[seller])
      .groupBy('productType')
      .map((product: any, name: string) => ({
        productType: name,
        productName: _(product)
          .groupBy('productName')
          .map((prodName: any, name: string) => ({
            name: name,
            aggioEstimatedPurchases: _.sumBy(
              prodName,
              (sale: any) => sale.aggioEstimatedPurchases
            ),
            shareOfWallet: roundToPrecision(
              _.sumBy(prodName, (sale: any) => sale.shareOfWallet) /
                prodName.length
            ),
            opportunity: _.sumBy(prodName, (sale: any) => sale.opportunity),
            previousYearSales: _.sumBy(
              prodName,
              (sale: any) => sale.py_dollars
            ),
            previousYearUnits: _.sumBy(prodName, (sale: any) => sale.py_units),
            currentYearOrders: _.sumBy(prodName, (sale: any) => sale.cy_orders),
            currentYearShipped: _.sumBy(
              prodName,
              (sale: any) => sale.cy_shipped
            ),
            currentYearValue: _.sumBy(prodName, (sale: any) => sale.cy_dollars)
          }))
          .value()
      }))
      .value()
  }));
  return productGrouping;
};

export const getSellerAndGrowerProductNameInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const groupedSellerData = _.groupBy(filteredData, (sale: any) => sale.seller);
  const groupedGrowerData = _.groupBy(filteredData, (sale: any) => sale.grower);

  const productGrouping = Object.keys(groupedSellerData)
    .map((seller: any) =>
      Object.keys(groupedGrowerData).map((grower: any) => ({
        sellerName: seller,
        growerName: grower,
        products: _(filteredData)
          .groupBy('productType')
          .map((product: any, name: string) => ({
            productType: name,
            productName: _(product)
              .groupBy('productName')
              .map((prodName: any, name: string) => ({
                name: name,
                aggioEstimatedPurchases: _.sumBy(
                  prodName,
                  (sale: any) => sale.aggioEstimatedPurchases
                ),
                shareOfWallet: roundToPrecision(
                  _.sumBy(prodName, (sale: any) => sale.shareOfWallet) /
                    prodName.length
                ),
                opportunity: _.sumBy(prodName, (sale: any) => sale.opportunity),
                previousYearSales: _.sumBy(
                  prodName,
                  (sale: any) => sale.py_dollars
                ),
                previousYearUnits: _.sumBy(
                  prodName,
                  (sale: any) => sale.py_units
                ),
                currentYearOrders: _.sumBy(
                  prodName,
                  (sale: any) => sale.cy_orders
                ),
                currentYearShipped: _.sumBy(
                  prodName,
                  (sale: any) => sale.cy_shipped
                ),
                currentYearValue: _.sumBy(
                  prodName,
                  (sale: any) => sale.cy_dollars
                )
              }))
              .value()
          }))
          .value()
      }))
    )
    .flat();
  return productGrouping;
};

getSellerAndGrowerProductNameInfo('NATURCHEM INC (LEXINGTON, KY)');

export const getGrowerProductRetailerInfo = (grower: string) => {
  const filteredData = salesData.filter((sale: any) => sale.grower === grower);
  const retailerGroup = _.groupBy(filteredData, (sale: any) => sale.retailer);
  const groupedData: any = {};
  Object.keys(retailerGroup).forEach(
    (retailer: any) =>
      (groupedData[retailer] = _.groupBy(
        retailerGroup[retailer],
        (sale: any) => sale.grower
      ))
  );
  const sellerGrouping = Object.keys(groupedData).map((retailer: any) => ({
    name: retailer,
    toWhom: Object.keys(groupedData[retailer]).map((grower: any) => ({
      name: grower,
      products: _(groupedData[retailer][grower])
        .groupBy('productType')
        .map((product: any, name: string) => ({
          productType: name,
          previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
          previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
          currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
          currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
          currentYearDollars: _.sumBy(product, (sale: any) => sale.cy_dollars)
        }))
        .value()
    })),
    products: _(retailerGroup[retailer])
      .groupBy('productType')
      .map((product: any, name: string) => ({
        productType: name,
        previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
        previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
        currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
        currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
        currentYearValue: _.sumBy(product, (sale: any) => sale.cy_dollars)
      }))
      .value()
  }));
  return sellerGrouping;
};

export const getGrowerInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const groupedData = _.groupBy(filteredData, (sale: any) => sale.grower);

  const productGrouping = Object.keys(groupedData).map((grower: any) => ({
    name: grower,
    products: _(groupedData[grower])
      .groupBy('productType')
      .map((product: any, name: string) => ({
        productType: name,
        aggioEstimatedPurchases: _.sumBy(
          product,
          (sale: any) => sale.aggioEstimatedPurchases
        ),
        shareOfWallet: roundToPrecision(
          _.sumBy(product, (sale: any) => sale.shareOfWallet) / product.length
        ),
        opportunity: _.sumBy(product, (sale: any) => sale.opportunity),
        previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
        previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
        currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
        currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
        currentYearValue: _.sumBy(product, (sale: any) => sale.cy_dollars)
      }))
      .value()
  }));
  return productGrouping;
};

export const getAllGrowerInfo = () => {
  const groupedData = _.groupBy(salesData, (sale: any) => sale.grower);
  return Object.keys(groupedData)
    .map((growerName: string) => {
      const products = groupedData[growerName].map((sale: any) => sale.product);

      return products.map((productType: string) => {
        const locationWithClosingParenthesis =
          groupedData[growerName][0].retailer.split('(').length === 2
            ? groupedData[growerName][0].retailer.split('(')[1]
            : '';
        const location =
          locationWithClosingParenthesis.split(')').length === 2
            ? locationWithClosingParenthesis.split(')')[0]
            : '';

        const product = groupedData[growerName].filter(
          (sale: any) => sale.productType === productType
        );
        return {
          growerName: growerName,
          productType: productType,
          location: location,
          aggioEstimatedPurchases: _.sumBy(
            product,
            (sale: any) => sale.aggioEstimatedPurchases
          ),
          shareOfWallet: roundToPrecision(
            _.sumBy(product, (sale: any) => sale.shareOfWallet) / product.length
          ),
          opportunity: _.sumBy(product, (sale: any) => sale.opportunity),
          previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
          previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
          currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
          currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
          currentYearValue: _.sumBy(product, (sale: any) => sale.cy_dollars)
        };
      });
    })
    .flat();
};

export const getSellerInfo = (retailer: string) => {
  const filteredData = salesData.filter(
    (sale: any) => sale.retailer === retailer
  );
  const sellerGroup = _.groupBy(filteredData, (sale: any) => sale.seller);
  const groupedData: any = {};
  Object.keys(sellerGroup).forEach(
    (seller: any) =>
      (groupedData[seller] = _.groupBy(
        sellerGroup[seller],
        (sale: any) => sale.grower
      ))
  );
  const sellerGrouping = Object.keys(groupedData).map((seller: any) => ({
    name: seller,
    toWhom: Object.keys(groupedData[seller]).map((grower: any) => ({
      name: grower,
      products: _(groupedData[seller][grower])
        .groupBy('productType')
        .map((product: any, name: string) => ({
          productType: name,
          previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
          previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
          currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
          currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
          currentYearDollars: _.sumBy(product, (sale: any) => sale.cy_dollars)
        }))
        .value()
    })),
    products: _(sellerGroup[seller])
      .groupBy('productType')
      .map((product: any, name: string) => ({
        productType: name,
        previousYearSales: _.sumBy(product, (sale: any) => sale.py_dollars),
        previousYearUnits: _.sumBy(product, (sale: any) => sale.py_units),
        currentYearOrders: _.sumBy(product, (sale: any) => sale.cy_orders),
        currentYearShipped: _.sumBy(product, (sale: any) => sale.cy_shipped),
        currentYearValue: _.sumBy(product, (sale: any) => sale.cy_dollars)
      }))
      .value()
  }));

  return sellerGrouping;
};

export const getProgramData = (filterBy?: string) => {
  let programs = programJson;
  if (filterBy === 'tiered') {
    programs = programs.filter(
      (unfilteredProgram: any) => unfilteredProgram.tier !== 'none'
    );
  }
  return programs.map((program: any, index: number) => ({
    id: index,
    product: program.product,
    name: program.tier,
    reward: program.reward,
    rewardLabel: `$${program.reward}/Unit`,
    minUnits: program.minUnits,
    description: program.description,
    programName: program.name,
    subscribed: index === 0
  }));
};

export const getRetailerInfo = () => {
  const byRetailer = _.groupBy(salesData, (sale: any) => sale.retailer);
  const retailerInfo = Object.keys(byRetailer)
    .map((retailerName: string) => {
      const retailerData = byRetailer[retailerName];
      const byProduct = _.groupBy(retailerData, (sale: any) => sale.product);
      return Object.keys(byProduct).map((productType: string) => {
        const productData = byProduct[productType];
        const sampleSale = productData[0];
        const someNumber = Math.floor(Math.random() * Math.floor(100));
        const opportunity = Math.floor(
          (_.sumBy(productData, (sale: any) => sale.cy_dollars) * 100) /
            ((someNumber !== 0 ? someNumber : 1) / 100)
        );
        const locationWithClosingParenthesis =
          sampleSale.retailer.split('(').length === 2
            ? sampleSale.retailer.split('(')[1]
            : '';
        const location =
          locationWithClosingParenthesis.split(')').length === 2
            ? locationWithClosingParenthesis.split(')')[0]
            : '';

        return {
          productType: sampleSale.productType,
          segment: someNumber < 50 && opportunity > 2000 ? 'GROW' : 'MAINTAIN',
          retailerName: sampleSale.rollup,
          currentYearUnits: _.sumBy(productData, (sale: any) => sale.cy_orders),
          currentYearOrders: _.sumBy(
            productData,
            (sale: any) => sale.cy_orders
          ),
          previousYearUnits: _.sumBy(productData, (sale: any) => sale.py_units),
          currentYearValue: _.sumBy(
            productData,
            (sale: any) => sale.cy_dollars
          ),
          previousYearValue: _.sumBy(
            productData,
            (sale: any) => sale.py_dollars
          ),
          previousYearSales: _.sumBy(
            productData,
            (sale: any) => sale.py_dollars
          ),
          shareOfWallet: someNumber,
          currentYearShipped: _.sumBy(
            productData,
            (sale: any) => sale.cy_shipped
          ),
          opportunity: opportunity,
          location: location,
          retailer: sampleSale.retailer
        };
      });
    })
    .flat();

  return retailerInfo;
};
