import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';

const WidgetGridTemplate = (props: any) => {
  const { components, metadata } = props;
  const layout =
    metadata && metadata.layout
      ? metadata.layout.flat()
      : [[6, 6], [3, 3, 6]].flat();
  return (
    <Fragment>
      <Grid container spacing={2}>
        {components.map((component: any, index: number) => {
          return (
            <Grid item xs={12} sm={layout[index]}>
              {component}
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default WidgetGridTemplate;
