import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      margin: '0px 10px 0px 10px',
      fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
    }
  })
);

const InputTableItemTemplate = (props: any) => {
  const classes = useStyles(props);
  const id = props.id;
  const tableId = props.tableId;
  const rowId = props.rowId;
  const columnId = props.columnId;
  const numericalOnly = props.numericalOnly;
  const defaultValue = props.defaultValue || '';
  const setTableInputValues = props.setTableInputValues;
  const applicationTables = props.applicationTables;
  const value = _.get(applicationTables, `${tableId}.${rowId}.${columnId}`, defaultValue);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numericalOnly) {
      if (!isNaN(Number(event.target.value))) {
        setInputValue(event.target.value);
        return setTableInputValues(tableId, rowId, columnId, event.target.value);
      }
    } else {
      setInputValue(event.target.value);
      return setTableInputValues(tableId, rowId, columnId, event.target.value);
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id={id}
        className={classes.textField}
        margin="dense"
        variant="outlined"
        placeholder={numericalOnly ? '0' : defaultValue}
        value={inputValue}
        onChange={handleChange()}
      />
    </form>
  );
};

export default InputTableItemTemplate;
