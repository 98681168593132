import {
  // getSellerInfo,
  getGrowerInfo
} from './generatedBaseData';
import config from '../config/config.json';
import _ from 'lodash';
export const getPlanTableData = (customerFilter: string) => {
  const customerTab = config.routes.find(
    (route: any) => route.title === 'Customer Enablement'
  );
  const customerTabRoutes: any = customerTab ? customerTab.routes : [];
  const accountPlanningRoute = customerTabRoutes
    ? customerTabRoutes.find((route: any) => route.title === 'Account Planning')
    : {};
  const operationsRoute = accountPlanningRoute
    ? accountPlanningRoute.routes.find(
        (route: any) => route.title === 'Plan & Tactics'
      )
    : {};
  const metadata =
    operationsRoute &&
    operationsRoute.components &&
    operationsRoute.components.length > 0
      ? operationsRoute.components[0].metadata
      : {};
  if (_.isNil(metadata)) {
    console.log('could not find metadata for operationsTable');
    return null;
  }
  // const sellerProducts = getSellerInfo(customerFilter);
  const growerProducts = getGrowerInfo(customerFilter);
  // const customers: any = sellerProducts.length > 0 ? sellerProducts : growerProducts;
  const customers: any = growerProducts;
  const colTitles = customers.map((customer: any) => customer.name);
  // const colTitles: any = [];
  // customers.forEach((customer: any) =>
  //   customer.products.forEach((product: any) => {
  //     if (colTitles.indexOf(product.productType) < 0) {
  //       colTitles.push(product.productType);
  //     }
  //   })
  // );
  const rowTitles: any = [];
  customers.forEach((customer: any) =>
    customer.products.forEach((product: any) => {
      if (rowTitles.indexOf(product.productType) < 0) {
        rowTitles.push(product.productType);
      }
    })
  );

  const getValues = (customer: any, withTotal: boolean) => {
    // const customerValues = customer.products.map((product: any) => ({
    //   productType: product.productType,
    //   units: product.currentYearUnits
    // }));

    // const groupByProduct =
    // customer.map((name: any) =>
    // _.groupBy(customer.products, ((item: any)=> item.productType))
    // .find((item: any) => item.productType === rowTitle)

    // console.log('groupByProduct', groupByProduct)

    const baseValues = colTitles.map((customerName: string, index: number) => {
      const customerValues = rowTitles.map((productType: any) => {
        return customer.products.find(
          (product: any) => product.productType === productType
        );
      });

      return !_.isNil(customerValues[index])
        ? customerValues[index].currentYearOrders
        : 0;
    });
    return withTotal ? baseValues.concat(_.sum(baseValues)) : baseValues;
  };

  // const rowTitles = customers.map((customer: any) => customer.name);
  // const rowTitles: any = [];
  // customers.forEach((customer: any) =>
  //   customer.products.forEach((product: any) => {
  //     if (rowTitles.indexOf(product.productType) < 0) {
  //       rowTitles.push(product.productType);
  //     }
  //   })
  // );
  // const groupByProduct = customers.map((name: any) =>
  //   _.groupBy(name.products, (item: any) => item.productType)
  // );

  const rowInfo = colTitles.map((colTitle: string, index: any) => {
    const columnConfig =
      metadata.columnInfo && metadata.columnInfo.length === 1
        ? metadata.columnInfo[0]
        : {};

    // return rowTitles.map((item: any)=> {
    return {
      dependency: 'independent',
      rowType: columnConfig.renderFormat,
      boldLastColumn: true,
      rowTitle: rowTitles[index],
      values: getValues(
        customers.find((customer: any) => customer.name === colTitle),
        metadata.hasRowTotals
      )
    };
    // })
  });

  if (metadata.hasRowTotals) {
    colTitles.push('Total');
  }
  if (metadata.hasColumnTotals) {
    let totalRowValues: any = {};
    rowInfo.map((row: any) => {
      row.values.forEach((value: number, colIndex: number) => {
        const newValue = (totalRowValues[colIndex] || 0) + value;
        totalRowValues[colIndex] = newValue;
      });
    });
    rowInfo.push({
      dependency: 'independent',
      rowType: 'columnTotals',
      isBold: true,
      boldLastColumn: true,
      rowTitle: 'Total',
      values: Object.values(totalRowValues)
    });
  }
  return {
    colTitles: [''].concat(colTitles),
    rowInfo,
    canSortColumns: metadata.canSortColumns
  };
};
