import _ from 'lodash';
import salesPerformanceJson from '../data/demo/salesPerformance.json';

const makeSalesPerformanceeTableData = (dataArray: any, metadata: any) => {
  if (dataArray.length === 0) {
    return {};
  }
  const colTitles = (metadata: any) => {
    return dataArray[0] === undefined
      ? dataArray
      : Object.keys(dataArray[0])
          .map((column: any) => {
            const getrelevantColumnInfo = metadata.columnInfo.find(
              (item: any) => item.field === column
            );
            switch (column) {
              case 'units':
              case 'dollars':
                return '';
              case 'retailer':
              case 'grower':
              case 'productType':
              case 'productCategory':
              case 'productName':
              case 'cyFNSDollars':
              case 'cyFNSUnits':
              case 'pyFNSDollars':
              case 'pyFNSUnits':
              case 'growerOrdersDollars':
              case 'growerOrdersUnits':
              case 'totalOrdersDollars':
              case 'totalOrdersUnits':
              case 'grossShippedDollars':
              case 'grossShippedUnits':
              case 'unitPlan':
              case 'vsPlan':
              case 'vsSTPY':
                return {
                  title: getrelevantColumnInfo.header,
                  cellFormats: getrelevantColumnInfo.renderFormat.type,
                  column: column
                };
            }
          })
          .filter((colTitle: any) => typeof colTitle === 'object');
  };
  const getSalesRowData = (dataPoint: any, metadata: any) => {
    return colTitles(metadata).map(
      (colTitleObj: any) => dataPoint[colTitleObj.column]
    );
  };

  const getCellFormat = (metadata: any) => {
    return colTitles(metadata).map((cell: any) => cell.cellFormats);
  };
  return {
    colTitles: colTitles(metadata),
    canSortColumns: true,
    metadata,
    rowInfo: dataArray.map((dataPoint: any) => {
      return {
        dependency: 'independent',
        rowType: 'multiType',
        cellFormats: getCellFormat(metadata),
        rowTitle: null,
        boldLastColumn: true,
        values: getSalesRowData(dataPoint, metadata)
      };
    })
  };
};

const getOptionalFieldsFromGroup = (filterArray: any[], dataRow: any): any => {
  if (filterArray.length > 0) {
    const filterArrayToRemoveFrom = [...filterArray];
    const filter = filterArrayToRemoveFrom.pop();
    const filterValue = dataRow.group.split(':').slice(-1);
    const newGroup = dataRow.group
      .split(':')
      .slice(0, -1)
      .join(':');
    return getOptionalFieldsFromGroup(filterArrayToRemoveFrom, {
      [filter.field]: filterValue[0],
      ...dataRow,
      group: newGroup
    });
  }
  return dataRow;
};

export const makeDynamicSalesData = (
  filterArray: any[],
  data: any,
  tabName: string
) => {
  const filterFieldNames = filterArray.map((filter: any) => filter.field);

  switch (tabName) {
    case 'products':
      if (_.includes(filterFieldNames, 'units')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => ({
            group: name,
            cyFNSUnits: _.sumBy(group, (sale: any) => sale.cyFNSUnits),
            pyFNSUnits: _.sumBy(group, (sale: any) => sale.pyFNSUnits),
            growerOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.growerOrdersUnits
            ),
            totalOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.totalOrdersUnits
            ),
            grossShippedUnits: _.sumBy(
              group,
              (sale: any) => sale.grossShippedUnits
            ),
            unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
            vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
            vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
          }))
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );
        return returnData;
      } else if (_.includes(filterFieldNames, 'dollars')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => ({
            group: name,
            cyFNSDollars: _.sumBy(group, (sale: any) => sale.cyFNSDollars),
            pyFNSDollars: _.sumBy(group, (sale: any) => sale.pyFNSDollars),
            growerOrdersDollars: _.sumBy(
              group,
              (sale: any) => sale.growerOrdersDollars
            ),
            totalOrdersDollars: _.sumBy(
              group,
              (sale: any) => sale.totalOrdersDollars
            ),
            grossShippedDollars: _.sumBy(
              group,
              (sale: any) => sale.grossShippedDollars
            ),
            unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
            vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
            vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
          }))
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );

        return returnData;
      }
    case 'retailers':
      if (_.includes(filterFieldNames, 'units')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => ({
            group: name,
            cyFNSUnits: _.sumBy(group, (sale: any) => sale.cyFNSUnits),
            pyFNSUnits: _.sumBy(group, (sale: any) => sale.pyFNSUnits),
            growerOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.growerOrdersUnits
            ),
            totalOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.totalOrdersUnits
            ),
            grossShippedUnits: _.sumBy(
              group,
              (sale: any) => sale.grossShippedUnits
            ),
            unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
            vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
            vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
          }))
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );

        return returnData;
      } else if (_.includes(filterFieldNames, 'dollars')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => ({
            group: name,
            cyFNSDollars: _.sumBy(group, (sale: any) => sale.cyFNSDollars),
            pyFNSDollars: _.sumBy(group, (sale: any) => sale.pyFNSDollars),
            growerOrdersDollars: _.sumBy(
              group,
              (sale: any) => sale.growerOrdersDollars
            ),
            totalOrdersDollars: _.sumBy(
              group,
              (sale: any) => sale.totalOrdersDollars
            ),
            grossShippedDollars: _.sumBy(
              group,
              (sale: any) => sale.grossShippedDollars
            ),
            unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
            vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
            vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
          }))
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );

        return returnData;
      }
    case 'growers':
      if (_.includes(filterFieldNames, 'units')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => ({
            group: name,
            cyFNSUnits: _.sumBy(group, (sale: any) => sale.cyFNSUnits),
            pyFNSUnits: _.sumBy(group, (sale: any) => sale.pyFNSUnits),
            growerOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.growerOrdersUnits
            ),
            totalOrdersUnits: _.sumBy(
              group,
              (sale: any) => sale.totalOrdersUnits
            ),
            grossShippedUnits: _.sumBy(
              group,
              (sale: any) => sale.grossShippedUnits
            ),
            unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
            vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
            vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
          }))
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );

        return returnData;
      } else if (_.includes(filterFieldNames, 'dollars')) {
        const newData = _(data)
          .groupBy((row: any) =>
            filterArray.map((filter: any) => row[filter.field]).join(':')
          )
          .map((group: any, name: string) => {
            return {
              group: name,
              cyFNSDollars: _.sumBy(group, (sale: any) => sale.cyFNSDollars),
              pyFNSDollars: _.sumBy(group, (sale: any) => sale.pyFNSDollars),
              growerOrdersDollars: _.sumBy(
                group,
                (sale: any) => sale.growerOrdersDollars
              ),
              totalOrdersDollars: _.sumBy(
                group,
                (sale: any) => sale.totalOrdersDollars
              ),
              grossShippedDollars: _.sumBy(
                group,
                (sale: any) => sale.grossShippedDollars
              ),
              unitPlan: _.sumBy(group, (sale: any) => sale.unitPlan),
              vsPlan: _.sumBy(group, (sale: any) => sale.vsPlan) / group.length,
              vsSTPY: _.sumBy(group, (sale: any) => sale.vsSTPY) / group.length
            };
          })
          .value();

        const returnData = newData.map((row: any) =>
          getOptionalFieldsFromGroup(filterArray, row)
        );

        return returnData;
      }
  }
};

const getSalesPerformanceRetailersTableData = (metadata: any) => (
  filters: any
) => {
  const [
    salesPerformanceProductTypeToggle,
    salesPerformanceProductCategoryToggle,
    salesPerformanceProductNameToggle,
    salesPerformanceUnitsToggle,
    salesPerformanceDollarsToggle
  ] = filters;
  const filterForMap = [{ field: 'retailer' }];
  if (salesPerformanceProductTypeToggle) {
    filterForMap.push({ field: 'productType' });
  }
  if (salesPerformanceProductCategoryToggle) {
    filterForMap.push({ field: 'productCategory' });
  }
  if (salesPerformanceProductNameToggle) {
    filterForMap.push({ field: 'productName' });
  }
  if (salesPerformanceUnitsToggle) {
    filterForMap.push({ field: 'units' });
  }
  if (salesPerformanceDollarsToggle) {
    filterForMap.push({ field: 'dollars' });
  }

  const retailersDataBeforeColumns = makeDynamicSalesData(
    filterForMap,
    salesPerformanceJson,
    'retailers'
  );
  return makeSalesPerformanceeTableData(retailersDataBeforeColumns, metadata);
};

const getSalesPerformanceGrowersTableData = (metadata: any) => (
  filters: any
) => {
  const [
    salesPerformanceProductTypeToggle,
    salesPerformanceProductCategoryToggle,
    salesPerformanceProductNameToggle,
    salesPerformanceUnitsToggle,
    salesPerformanceDollarsToggle
  ] = filters;
  const filterForMap = [{ field: 'grower' }];
  if (salesPerformanceProductTypeToggle) {
    filterForMap.push({ field: 'productType' });
  }
  if (salesPerformanceProductCategoryToggle) {
    filterForMap.push({ field: 'productCategory' });
  }
  if (salesPerformanceProductNameToggle) {
    filterForMap.push({ field: 'productName' });
  }
  if (salesPerformanceUnitsToggle) {
    filterForMap.push({ field: 'units' });
  }
  if (salesPerformanceDollarsToggle) {
    filterForMap.push({ field: 'dollars' });
  }

  const growersDataBeforeColumns = makeDynamicSalesData(
    filterForMap,
    salesPerformanceJson,
    'growers'
  );
  return makeSalesPerformanceeTableData(growersDataBeforeColumns, metadata);
};

const getSalesPerformanceProductsTableData = (metadata: any) => (
  filters: any
) => {
  const [
    salesPerformanceProductTypeToggle,
    salesPerformanceProductCategoryToggle,
    salesPerformanceProductNameToggle,
    salesPerformanceUnitsToggle,
    salesPerformanceDollarsToggle
  ] = filters;
  const filterForMap = [{ field: 'productType' }];
  if (salesPerformanceProductTypeToggle) {
    filterForMap.push({ field: 'productType' });
  }
  if (salesPerformanceProductCategoryToggle) {
    filterForMap.push({ field: 'productCategory' });
  }
  if (salesPerformanceProductNameToggle) {
    filterForMap.push({ field: 'productName' });
  }
  if (salesPerformanceUnitsToggle) {
    filterForMap.push({ field: 'units' });
  }
  if (salesPerformanceDollarsToggle) {
    filterForMap.push({ field: 'dollars' });
  }

  const productDataBeforeColumns = makeDynamicSalesData(
    filterForMap,
    salesPerformanceJson,
    'products'
  );
  console.log("product table data",  makeSalesPerformanceeTableData(productDataBeforeColumns, metadata), productDataBeforeColumns )
  return makeSalesPerformanceeTableData(productDataBeforeColumns, metadata);
};

export {
  getSalesPerformanceRetailersTableData,
  getSalesPerformanceGrowersTableData,
  getSalesPerformanceProductsTableData
};
