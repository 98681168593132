import { getProductInfo } from './generatedBaseData';
import config from '../config/config.json';
import _ from 'lodash';

export const getOperationsTableData = (customerFilter: string) => {
  const customerTab = config.routes.find(
    (route: any) => route.title === 'Customer Enablement'
  );
  const customerTabRoutes: any = customerTab ? customerTab.routes : [];
  const accountPlanningRoute = customerTabRoutes
    ? customerTabRoutes.find((route: any) => route.title === 'Account Planning')
    : {};
  const operationsRoute = accountPlanningRoute
    ? accountPlanningRoute.routes.find(
        (route: any) => route.title === 'Operations Data'
      )
    : {};
  const metadata =
    operationsRoute &&
    operationsRoute.components &&
    operationsRoute.components.length === 1
      ? operationsRoute.components[0].metadata
      : {};

  if (_.isNil(metadata)) {
    console.log('could not find metadata for operationsTable');
    return null;
  }
  const products = getProductInfo(customerFilter);

  products.map((item: any) =>
    Object.assign(item, {
      calulatedSOW: Number(
        ((item.previousYearUnits / item.aggioEstimatedPurchases) * 100).toFixed(
          2
        )
      )
    })
  );

  const productTypes = products.map((product: any) => product.productType);
  // const aggioSOWValues = products.map((product: any) => Number(((product.previousYearUnits/product.aggioEstimatedPurchases)*100).toFixed(2)))

  const colTitles = metadata.hasRowTotals
    ? [''].concat(productTypes).concat(['Total'])
    : [''].concat(productTypes);
  const getValues = (field: string, withTotal: boolean) => {
      const baseValues = products.map((product: any) => product[field]);
      const prevYearDollarPerProdcut: number[] = []
      products.forEach((product: any)=> prevYearDollarPerProdcut.push(product.previousYearUnits))
      const aggioEstimatedPurchasesPerProdcut: number[] = []
      products.forEach((product: any)=> aggioEstimatedPurchasesPerProdcut.push(product.aggioEstimatedPurchases))
      const totalPrevYearDollar = _.sum(prevYearDollarPerProdcut)
      const totalAggioEstimatedPurchases= _.sum(aggioEstimatedPurchasesPerProdcut)
  
      return withTotal
        ? field === "calulatedSOW" ? baseValues.concat(Number((totalPrevYearDollar/totalAggioEstimatedPurchases)*100)) : baseValues.concat(_.sumBy(products, (product: any) => product[field]))
        : baseValues;
    }
  
  const rowInfo = metadata.columnInfo.map(
    (columnConfig: any, index: number) => {
      return {
        dependency: columnConfig.dependency
          ? columnConfig.dependency
          : 'independent',
        rowType: columnConfig.renderFormat,
        rowDependency: _.get(columnConfig, 'rowDependency'),
        boldLastColumn: true,
        rowTitle: columnConfig.header,
        values: getValues(columnConfig.field, metadata.hasRowTotals)
      };
    }
  );

  return {
    colTitles,
    rowInfo,
    canSortColumns: metadata.canSortColumns
  };
};
