import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      width: '65%',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    dense: {
      marginTop: theme.spacing(2)
    },
    margin: {
      margin: theme.spacing(2)
    },
    formButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    save: {
      marginRight: '10px'
    },
    cancel: {}
  })
);

const FormInputTemplate = (props: any) => {
  const formId = props.formId;
  const formField = props.formField;
  const applicationForms = props.applicationForms;
  const setFormInputValues = props.setFormInputValues;
  const classes = useStyles();
  const isRequired = props.isRequired;
  const form = props.form;

  let value = _.get(
    applicationForms,
    `stagedApplicationForms.${formId}.formFields.${formField}.value`,
    ''
  );

  const initialDate = new Date();

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormInputValues(formId, formField, event.target.value, isRequired);
  };

  const handleCurrencyChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isNaN(Number(event.target.value))) {
      return setFormInputValues(formId, formField, Number(event.target.value));
    }
  };

  const handleDateChange = (date: Date | null) => {
    return setFormInputValues(
      formId,
      formField,
      date != null ? date.toLocaleDateString() : `${date}`,
      false
    );
  };

  useEffect(() => {
    setFormInputValues(formId, formField, value, isRequired);
  }, formId);

  if (form.inputType === 'date') {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          clearable
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          invalidLabel={''}
          invalidDateMessage={<span></span>}
          defaultValue={initialDate.toLocaleDateString()}
          label={form.inputName}
          value={value}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          style={{ width: '50%' }}
        />
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <TextField
      multiline={form.inputType === 'multiLine' ? true : false}
      error={isRequired && (value === '' || null) ? true : false}
      required={form.isRequired ? true : false}
      id={
        isRequired && (value === '' || null)
          ? 'outlined-error'
          : 'outlined-required'
      }
      label={form.inputName}
      className={
        form.inputType === 'currency'
          ? clsx(classes.margin, classes.textField)
          : classes.textField
      }
      margin="normal"
      variant="outlined"
      value={value}
      onChange={
        form.inputType === 'currency' ? handleCurrencyChange() : handleChange()
      }
      InputProps={{
        startAdornment:
          form.inputType === 'currency' ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : null
      }}
      style={{
        width: form.inputType === 'multiLine' ? '100%' : '40%'
      }}
    />
  );
};

export default FormInputTemplate;
