import paymentsJson from './demo/payments.json';
import programsJson from './demo/programs.json';
import _ from 'lodash';

const getByRetailer = _.groupBy(paymentsJson, (item: any) => item.retailer);

const getByActorType = _.groupBy(paymentsJson, (item: any) => item.actorType);

const isRetailer = (filterSelected: any) =>
  _.includes(Object.keys(getByRetailer), filterSelected);

const getDataByRetailersOrGrower = (actorType: any, filterSelected: any) =>
  isRetailer(filterSelected)
    ? _.groupBy(getByActorType[actorType], (item: any) => item.retailer)
    : _.groupBy(getByActorType[actorType], (item: any) => item.grower);

const handleNullRetailersOrGrower = (filterSelected: any) =>
  isRetailer(filterSelected)
    ? getDataByRetailersOrGrower('RETAILER', filterSelected)[filterSelected]
      ? getDataByRetailersOrGrower('RETAILER', filterSelected)[filterSelected]
      : getDataByRetailersOrGrower('RETAILER', filterSelected)[
          'AGRI-CHEM LLC (HOPKINSVILLE, KY)'
        ]
    : getDataByRetailersOrGrower('GROWER', filterSelected)[filterSelected]
    ? getDataByRetailersOrGrower('GROWER', filterSelected)[filterSelected]
    : getDataByRetailersOrGrower('GROWER', filterSelected)['Jessica Anderson'];

const getRowInfo = (filterSelected: any) => {
  return handleNullRetailersOrGrower(filterSelected).map((item: any) => ({
    dependency: 'independent',
    rowType: 'multiType',
    isBold: false,
    cellFormats: isRetailer(filterSelected)
      ? [
          'string',
          'string',
          'string',
          'number',
          'dollar',
          'date',
          'string',
          'dollar'
        ]
      : ['string', 'number', 'dollar'],
    rowTitle: item.programName,
    values: isRetailer(filterSelected)
      ? [
          item.retailer,
          '-',
          item.product,
          item.productValue,
          item.programEarnings,
          item.checkDate,
          item.checkNo,
          item.checkAmount
        ]
      : [item.product, item.productValue, item.programEarnings]
  }));
};

const actualEarningsTableData = (metadata: any) => (filterSelected: any) =>
  Object.assign(
    {},
    {
      rowInfo: getRowInfo(filterSelected),
      colTitles: metadata.columnInfo.map((colTitle: any) => colTitle.header),
      canSortColumns: false
    }
  );

const getByProgramActorType = _.groupBy(
  programsJson,
  (item: any) => item.actorType
);

const getByActorTypeAndProgramName = (actorType: any) =>
  _.groupBy(getByProgramActorType[actorType], (item: any) => item.name);

const variousProgramsData = (filerSelected: any) =>
  isRetailer(filerSelected)
    ? Object.values(getByActorTypeAndProgramName('RETAILER')).map(
        (program: any, index: number) => ({
          title: program[0].name,
          id: index,
          name: program[0].name,
          subscribed: index === 0 ? true : false,
          description: program[0].description,
          details: {
            requirements: {
              variety: false,
              quantityPer: true,
              numberUnique: null,
              minQuantityPer: null
            },
            products: program.map((detail: any) => [
              {
                product: detail.product,
                tiers: [
                  {
                    tier: detail.tier,
                    reward: detail.reward,
                    rewardLabel: detail.rewardLabel,
                    minUnits: detail.minUnits
                  }
                ]
              }
            ])
          }
        })
      )
    : Object.values(getByActorTypeAndProgramName('GROWER')).map(
        (program: any, index: number) => ({
          title: program[0].name,
          id: index,
          name: program[0].name,
          subscribed: index === 0 ? true : false,
          description: program[0].description,
          details: {
            requirements: {
              variety: false,
              quantityPer: true,
              numberUnique: null,
              minQuantityPer: null
            },
            products: program.map((detail: any) => [
              {
                product: detail.product,
                tiers: [
                  {
                    tier: detail.tier,
                    reward: detail.reward,
                    rewardLabel: detail.rewardLabel,
                    minUnits: detail.minUnits
                  }
                ]
              }
            ])
          }
        })
      );

export { actualEarningsTableData, variousProgramsData };
