import { tieredProgramData, productInfo } from './';
import * as _ from 'lodash';

const findNextProgram = (currentProgram: any, programs: any[]) => {
  const sortedPrograms = _.sortBy(programs, 'minUnits');
  const currentIndex = sortedPrograms.indexOf(currentProgram);
  return sortedPrograms[currentIndex + 1]
    ? sortedPrograms[currentIndex + 1]
    : sortedPrograms[currentIndex];
};

const getCurrentProgram = (programs: any, currentUnits: number) => {
  return programs.reduce((prev: any, next: any) => {
    return next.minUnits > prev.minUnits && currentUnits > next.minUnits
      ? next
      : prev;
  });
};

const makeData = (
  productData: any,
  programData: any,
  applicationTables: any
) => {
  return productData.map((product: any) => {
    const programs = programData.filter(
      (program: any) => program.product === product.productName
    );
    const currentProgram = getCurrentProgram(
      programs,
      product.currentYearUnits
    );
    const estimatedUnits =
      applicationTables &&
      applicationTables['estimatedearnings'] &&
      applicationTables['estimatedearnings'][product.productName]
        ? applicationTables['estimatedearnings'][product.productName][
            'Estimated Units'
          ]
        : product.currentYearUnits;
    const estimatedProgram = getCurrentProgram(programs, estimatedUnits);
    const nextProgram = findNextProgram(currentProgram, programs);
    return [
      product.productName,
      product.currentYearUnits,
      currentProgram.name,
      currentProgram.rewardLabel,
      currentProgram.reward * product.currentYearUnits,
      Math.max(nextProgram.minUnits - product.currentYearUnits, 0),
      estimatedUnits,
      estimatedProgram.name,
      estimatedProgram.rewardLabel,
      estimatedProgram.reward * estimatedUnits
    ];
  });
};

export const makeEstimatedProgramEarningsData = (applicationTables: any) => {
  const data = makeData(productInfo, tieredProgramData, applicationTables);
  return {
    colTitles: [
      'Crop',
      'YTD Units',
      'Reward Level',
      '$/Unit',
      'Actual Earnings',
      'Units to Next Level',
      'Estimated Units',
      'Reward Level',
      '$/Unit',
      'Estimated Earnings'
    ],
    canSortColumns: true,
    rowInfo: data.map((rowData: any) => {
      return {
        dependency: 'independent',
        rowType: 'multiType',
        cellFormats: [
          'number',
          'string',
          'string',
          'dollar',
          'number',
          'slider',
          'string',
          'string',
          'dollar'
        ],
        rowTitle: rowData[0],
        values: [
          rowData[1],
          rowData[2],
          rowData[3],
          rowData[4],
          rowData[5],
          rowData[6],
          rowData[7],
          rowData[8],
          rowData[9]
        ]
      };
    })
  };
};
