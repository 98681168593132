import { connect } from 'react-redux';
import { setSelectedTab } from '../actions';
import NavBarTemplate from './NavBarTemplate';

const mapStateToProps = (state: any) => ({
  selectedTab: state.navigation.selectedTab
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedTab: (newTab: any) => dispatch(setSelectedTab(newTab))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarTemplate);
