import React from 'react';
import InputTableItemContainer from '../components/InputTableItemContainer';
import PieTableItemTemplate from '../components/PieTableItemTemplate';
import TableCell from '@material-ui/core/TableCell';
import { getSOWValue } from '../data';
import Slider from '../components/Slider';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import renderFormat from './renderFormat';
import HorizontalBar from '../components/HorizontalBar';

const getRowTotals = (tableId: any, rowId: any, applicationTables: any) => {
  return _.get(applicationTables, `${tableId}.${rowId}.rowTotal`, 0);
};

const getInputGrandTotals = (tableId: any, applicationTables: any) => {
  return _.get(applicationTables, `${tableId}.columnTotals.rowTotal`, 0);
};

const getSOWPieColors = (value: number) => {
  if (value <= 25) {
    return ['#EB5858', '#F5F5F5'];
  } else if (value > 25 && value < 39) {
    return ['#ffbf3f', '#F5F5F5'];
  } else if (value >= 39) {
    return ['#84bd00', '#F5F5F5'];
  }
};

const getSOWLabelColors = (value: number) => {
  if (value <= 25) {
    return '#EB5858';
  } else if (value > 25 && value < 39) {
    return '#ffbf3f';
  } else if (value >= 39) {
    return '#84bd00';
  }
};

const getPercentChangePieColors = (value: number) => {
  if (value < 0) {
    return ['#EB5858', '#F5F5F5'];
  } else {
    return ['#84bd00', '#F5F5F5'];
  }
};

const getProductColors = (
  config: any,
  cellType: string,
  currentYearValue: number,
  previousYearValue: any
) => {
  const getConfigRenderFormat = config.columnInfo.map(
    (format: any) => format.renderFormat
  );
  const getCellType = getConfigRenderFormat.find(
    (type: any) => type.type === cellType
  );

  if (currentYearValue < previousYearValue - previousYearValue * 0.1) {
    return getCellType.color[0].color;
  } else if (
    currentYearValue < previousYearValue &&
    currentYearValue >= previousYearValue - previousYearValue * 0.1
  ) {
    return getCellType.color[1].color;
  } else if (currentYearValue >= previousYearValue) {
    return getCellType.color[2].color;
  }
};

const getColumnTotals = (
  tableId: any,
  columnId: any,
  applicationTables: any
) => {
  return _.get(applicationTables, `${tableId}.columnTotals.${columnId}`, 0);
};

const getValue = (
  tableId: any,
  rowId: any,
  columnId: string,
  applicationTables: any
) => {
  return _.get(applicationTables, `${tableId}.${rowId}.${columnId}`, 0);
};

const renderRow = (
  row: any,
  rowIndex: any,
  data: any,
  colTitles: string[],
  tableId: any,
  applicationTables: any,
  handleClick: any,
  customerFilter: any
) => {
  const borderStylez = data.rowBordersOnly
    ? {
        borderTop: '1px solid rgb(245, 245, 245)',
        borderBottom: '1px solid rgb(245, 245, 245)'
      }
    : { border: '1px #F5F5F5 solid' };

  const cells = colTitles.map((colTitle: any, index: number) =>
    index < colTitles.length - 1 ? 'keep' : 'drop'
  );
  switch (row.rowType) {
    case 'number':
      return row.values.map((value: any, cellIndex: any) => {
        // const bold = data.rowInfo.boldLastColumn;
        console.log('number', Intl.NumberFormat('en').format(value))
        return (
          <TableCell
            id="numberCell"
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="right"
          >
            {Intl.NumberFormat('en').format(value)}
          </TableCell>
        );
      });
    case 'stringInput':
      return row.values.map((value: any, cellIndex: any) => {
        return (
          <TableCell
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="center"
          >
            <InputTableItemContainer
              numericalOnly={false}
              defaultValue={value}
              rowId={data.rowInfo[rowIndex].rowTitle}
              columnId={colTitles[cellIndex + 1]}
              tableId={tableId}
            />
          </TableCell>
        );
      });
    case 'numberAndInput':
      return row.values.map((value: any, cellIndex: any) => {
        return (
          <TableCell
            id="planTableCell"
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="center"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <TableCell
                id="planTableCellItems"
                style={{
                  // width: '40%',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    cellIndex === row.values.length - 1 ? 'bold' : 'normal'
                }}
                align="right"
              >
                {value}
              </TableCell>

              <TableCell
                id="planTableCellItems"
                style={{
                  // width: '60%',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  borderLeft: '1px #F5F5F5 solid',
                  margin: '0px 0px 0px 0px',
                  fontWeight:
                    cellIndex === row.values.length - 1 ? 'bold' : 'normal'
                }}
                align="center"
              >
                {' '}
                {cellIndex === row.values.length - 1 ? (
                  isNaN(
                    getRowTotals(tableId, row.rowTitle, applicationTables)
                  ) ? (
                    0
                  ) : (
                    Intl.NumberFormat('en').format(
                      getRowTotals(tableId, row.rowTitle, applicationTables)
                    )
                  )
                ) : (
                  <InputTableItemContainer
                    numericalOnly={true}
                    rowId={data.rowInfo[rowIndex].rowTitle}
                    columnId={colTitles[cellIndex + 1]}
                    tableId={tableId}
                  />
                )}
              </TableCell>
            </div>
          </TableCell>
        );
      });
    case 'columnTotals':
      return row.values.map((value: any, cellIndex: any) => {
        return (
          <TableCell
            id="planTableCell"
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="center"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <TableCell
                id="planTableCellItems"
                style={{
                  // width: '40%',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    rowIndex === data.rowInfo.length - 1 ? 'bold' : 'normal'
                }}
                align="right"
              >
                {Intl.NumberFormat('en').format(value)}
              </TableCell>

              <TableCell
                id="planTableCellItems"
                style={{
                  // width: '60%',
                  borderLeft: '1px #F5F5F5 solid',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  margin: '0px 0px 0px 0px',
                  fontWeight:
                    rowIndex === data.rowInfo.length - 1 ? 'bold' : 'normal'
                }}
                align="center"
              >
                {colTitles[cellIndex + 1] === 'Total'
                  ? Intl.NumberFormat('en').format(
                      getInputGrandTotals(tableId, applicationTables)
                    )
                  : Intl.NumberFormat('en').format(
                      getColumnTotals(
                        tableId,
                        colTitles[cellIndex + 1],
                        applicationTables
                      )
                    )}
              </TableCell>
            </div>
          </TableCell>
        );
      });

    case 'input':
      return cells
        .filter((indicator: string) => indicator === 'keep')
        .map((emptyString: string, cell: number) => {
          if (cell === colTitles.length - 2) {
            return (
              <TableCell
                id="inputCell"
                key={rowIndex + '-' + '-' + cell}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: cell === colTitles.length - 2 ? 'bold' : 'normal'
                }}
                align="right"
              >
                {' '}
                <span style={{ padding: '0px 10px' }}>
                  {Intl.NumberFormat('en').format(
                    getRowTotals(tableId, row.rowTitle, applicationTables)
                  )}
                </span>
              </TableCell>
            );
          }
          return (
            <TableCell
              id="inputCell"
              key={rowIndex + '-' + '-' + cell}
              style={{
                ...borderStylez,
                fontFamily:
                  'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                fontWeight: cell === colTitles.length - 2 ? 'bold' : 'normal'
              }}
              align="center"
            >
              <InputTableItemContainer
                numericalOnly={true}
                rowId={data.rowInfo[rowIndex].rowTitle}
                columnId={colTitles[cell + 1]}
                tableId={'operations'}
              />
            </TableCell>
          );
        });
    case 'pie':
      return row.values.map((value: any, cellIndex: any) => {
        let pieValue = 0;
        if (
          row.dependency === 'dependent' &&
          cellIndex < colTitles.length - 2
        ) {
          pieValue = getSOWValue(
            colTitles[cellIndex + 1],
            getValue(
              tableId,
              row.rowDependency,
              colTitles[cellIndex + 1],
              applicationTables
            ),
            customerFilter
          );
        } else if (row.dependency === 'dependent') {
          pieValue = getSOWValue(
            colTitles[cellIndex + 1],
            getRowTotals(tableId, row.rowDependency, applicationTables),
            customerFilter
          );
        } else {
          pieValue = value;
        }

        return (
          <TableCell
            id="pieCell"
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="center"
          >
            <PieTableItemTemplate
              configPieColors={getSOWPieColors(pieValue)}
              configLabelColors={getSOWLabelColors(pieValue)}
              value={pieValue}
            />
          </TableCell>
        );
      });
    case 'multiType':
      return row.values.map((value: any, cellIndex: any) => {
        switch (row.cellFormats[cellIndex]) {
          case 'string':
          case 'leftAlignString':
          case 'date':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  textAlign:
                    row.cellFormats[cellIndex] === 'leftAlignString'
                      ? 'left'
                      : row.cellFormats[cellIndex] === 'rightAlignString'
                      ? 'right'
                      : 'center',
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    row.isBold ||
                    row.values[0] === 'Grand Total' ||
                    data.colTitles === 'Total'
                      ? 'bold'
                      : 'normal'
                }}
                align="center"
              >
                {value}
              </TableCell>
            );
          case 'timeAgo':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    row.isBold ||
                    row.values[0] === 'Grand Total' ||
                    data.colTitles === 'Total'
                      ? 'bold'
                      : 'normal'
                }}
                align="center"
              >
                {moment.duration(-moment().diff(value)).humanize(true)}
              </TableCell>
            );
          case 'medStringDate':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  color: 'rgba(136, 136, 136, 0.62)',
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    row.isBold ||
                    row.values[0] === 'Grand Total' ||
                    data.colTitles === 'Total'
                      ? 'bold'
                      : 'normal'
                }}
                align="center"
              >
                {moment(value).format('D MMM')}
              </TableCell>
            );
          case 'number':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    row.isBold ||
                    row.values[0] === 'Grand Total' ||
                    cellIndex === row.values.length - 1
                      ? 'bold'
                      : 'normal'
                }}
                align="right"
              >
                {' '}
                {renderFormat(value, 'int')}
              </TableCell>
            );
          case 'dollarAbbreviated':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  border: '1px #F5F5F5 solid',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="right"
              >
                {' '}
                {renderFormat(value, 'currencyAbbreviated')}
              </TableCell>
            );
          case 'dollar':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="right"
              >
                {Intl.NumberFormat('en', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                }).format(value)}
              </TableCell>
            );
          case 'link':
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                // onClick={() => handleClick(row.values[cellIndex])}
                onClick={() => handleClick(0, 0, value)}
                style={{
                  ...borderStylez,
                  color: 'rgb(0, 163, 224)',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="center"
              >
                <Link
                  style={{
                    color: 'rgb(0, 163, 224)'
                  }}
                  component={RouterLink}
                  to={'/customer-enablement'}
                >
                  {value}
                </Link>
              </TableCell>
            );
          case 'shareOfWallet':
            return (
              <TableCell
                id="pieCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    cellIndex === row.values.length - 1 ? 'bold' : 'normal'
                }}
                align="center"
              >
                <PieTableItemTemplate
                  configPieColors={getSOWPieColors(value)}
                  configLabelColors={getSOWLabelColors(value)}
                  value={value}
                />
              </TableCell>
            );
          case 'slider':
            return (
              <TableCell key={rowIndex + '-' + value + '-' + cellIndex}>
                <Slider
                  value={value}
                  rowId={data.rowInfo[rowIndex].rowTitle}
                  columnId={colTitles[cellIndex + 1]}
                  tableId={tableId}
                />
              </TableCell>
            );
          case 'coloredBarWithDollarsLabels':
            const getCellFormatIndexForBar = _.findIndex(
              row.cellFormats,
              (item: any) => {
                return item === 'coloredBarWithDollarsLabels';
              }
            );

            const barValueArr = data.rowInfo.map(
              (item: any) => item.values[getCellFormatIndexForBar]
            );

            const dollarsMax = Math.max(...barValueArr);
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  border: '1px #F5F5F5 solid',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="left"
              >
                <HorizontalBar
                  rowIndex={rowIndex}
                  value={value}
                  max={dollarsMax * 6}
                  label={renderFormat(value, 'currencyAbbreviated')}
                  width={200}
                  tableId={tableId}
                  color={
                    getProductColors(
                      data.metadata,
                      'coloredBarWithDollarsLabels',
                      value,
                      row.values[getCellFormatIndexForBar + 1]
                    )[0]
                  }
                />
              </TableCell>
            );
          case 'coloredBarWithUnitLabels':
            const getCellFormatUnitsForBar = _.findIndex(
              row.cellFormats,
              (item: any) => {
                return item === 'coloredBarWithUnitLabels';
              }
            );

            const barValueArray = data.rowInfo.map(
              (item: any) => item.values[getCellFormatUnitsForBar]
            );

            const unitsMax = Math.max(...barValueArray);
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  border: '1px #F5F5F5 solid',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="left"
              >
                <HorizontalBar
                  rowIndex={rowIndex}
                  value={value}
                  max={unitsMax * 6}
                  label={renderFormat(value, 'stringInteger')}
                  width={200}
                  tableId={tableId}
                  color={
                    getProductColors(
                      data.metadata,
                      'coloredBarWithDollarsLabels',
                      value,
                      row.values[getCellFormatUnitsForBar + 1]
                    )[0]
                  }
                />
              </TableCell>
            );
          case 'coloredDonut':
            const getCellFormatIndex = _.findIndex(
              row.cellFormats,
              (item: any) => {
                return (
                  item === 'coloredBarWithDollarsLabels' ||
                  item === 'coloredBarWithUnitLabels'
                );
              }
            );
            return (
              <TableCell
                id="pieCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  border: '1px #F5F5F5 solid',
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: 'normal'
                }}
                align="center"
              >
                <PieTableItemTemplate
                  configPieColors={getProductColors(
                    data.metadata,
                    'coloredDonut',
                    row.values[getCellFormatIndex],
                    row.values[getCellFormatIndex + 1]
                  )}
                  configLabelColors={
                    getProductColors(
                      data.metadata,
                      'coloredDonut',
                      row.values[getCellFormatIndex],
                      row.values[getCellFormatIndex + 1]
                    )[0]
                  }
                  value={value}
                />
              </TableCell>
            );
          case 'percentChangeDonut':
            return (
              <TableCell
                id="pieCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  border: '1px #F5F5F5 solid',

                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight:
                    cellIndex === row.values.length - 1 ? 'bold' : 'normal'
                }}
                align="center"
              >
                <PieTableItemTemplate
                  configPieColors={getPercentChangePieColors(value)}
                  configLabelColors={getPercentChangePieColors(value)[0]}
                  value={value}
                />
              </TableCell>
            );
          default:
            return (
              <TableCell
                id="multiTypeCell"
                key={rowIndex + '-' + value + '-' + cellIndex}
                style={{
                  ...borderStylez,
                  fontFamily:
                    'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
                  fontWeight: row.isBold ? 'bold' : 'normal'
                }}
                align="center"
              >
                {value}
              </TableCell>
            );
        }
      });
    default:
      return row.values.map((value: any, cellIndex: any) => {
        return (
          <TableCell
            key={rowIndex + '-' + value + '-' + cellIndex}
            style={{
              display: 'flex',
              ...borderStylez,
              fontFamily:
                'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
              fontWeight:
                cellIndex === row.values.length - 1 ? 'bold' : 'normal'
            }}
            align="center"
          >
            <span style={{ flexGrow: 2 }}></span>
            {value}
          </TableCell>
        );
      });
  }
};

export default renderRow;
