import { connect } from 'react-redux';
import { setFormInputValuesState, cancelFormValues } from '../actions';
import FormTemplate from './FormTemplate';
import { getApplicationForms } from '../selectors';

const mapStateToProps = (state: any) => ({
  applicationForms: getApplicationForms(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setFormInputValuesState: () => dispatch(setFormInputValuesState()),
  cancelFormValues: () => dispatch(cancelFormValues())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormTemplate);
