import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList
} from 'recharts';
import '../index.css';

import HorizontalBarYAxis, {
  HorizontalBarLabels,
  HorizontalBarYaxisDollars
} from './HorizontalBarYAxis';

export const getPercentTickColors = (change: any) => {
  return change >= 0 ? '#84bd00' : '#EB5858';
};

const HorizontalBarChartTemplate = (props: any) => {
  const data = props.data.slice(0, 4);
  const barColors = props.barColors;
  const selectedDollarsUnitsToggle = props.selectedDollarsUnitsToggle;
  const comparisonFormat = props.comparisonFormat;

  return (
    <ResponsiveContainer height={120} width={'98%'}>
      <BarChart
        data={data}
        layout="vertical"
        style={{ fontSize: '.65em', width: '100%' }}
        margin={{
          top: 5,
          right: 45,
          left: 40,
          bottom: 5
        }}
      >
        <XAxis type="number" hide />
        <YAxis
          width={80}
          yAxisId={0}
          orientation="left"
          tick={<HorizontalBarLabels />}
          stroke={'#000'}
          tickSize={0}
          axisLine={false}
          type="category"
          dataKey="name"
        />
        <YAxis
          yAxisId={1}
          orientation="right"
          tick={
            comparisonFormat === 'dollars' ? (
              <HorizontalBarYaxisDollars />
            ) : (
              <HorizontalBarYAxis />
            )
          }
          tickSize={0}
          axisLine={false}
          type="category"
          dataKey="change"
          minTickGap={0}
        ></YAxis>
        <Bar maxBarSize={6} dataKey="units" fill={'#84bd00'} radius={20}>
          <LabelList
            dataKey="units"
            position="right"
            formatter={(value: any) =>
              selectedDollarsUnitsToggle.checked
                ? Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(value)
                : Intl.NumberFormat('en').format(value)
            }
          />
          {barColors}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChartTemplate;
