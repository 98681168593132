import { connect } from 'react-redux';
import { setTableInputValues } from '../actions';
import ContactLineTemplate from './ContactLineTemplate';
import { getApplicationTables } from '../selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    applicationTables: getApplicationTables(state),
    selectedFilter: state.filters.selectedFilter,
    data: ownProps.data
      ? ownProps.data
      : ownProps.getData(state.filters.selectedFilter)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setTableInputValues: (
    tableId: string,
    rowId: string,
    columnId: string,
    value: string
  ) => dispatch(setTableInputValues(tableId, rowId, columnId, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactLineTemplate);
