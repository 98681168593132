import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import '../index.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      backgroundColor: '#FFF'
    },
    cardHeader: {
      display: 'flex',
      paddingBottom: '0px',
      paddingTop: '10px',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    cardHeaderController: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFF',
      flexDirection: 'column'
    }
  })
);

const VerticalBarChartTemplate = (props: any) => {
  const classes = useStyles();
  const data = props.data;
  const kpiTitle = props.kpiTitle;

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeaderController}>
        <CardHeader
          className={classes.cardHeader}
          titleTypographyProps={{
            style: {
              fontSize: '.75em',
              display: 'flex',
              paddingBottom: '10px'
            }
          }}
          title={kpiTitle}
        />
      </div>
      <ResponsiveContainer height={180} width={'100%'}>
        <BarChart
          data={data}
          layout="horizontal"
          style={{ fontSize: '.75em', width: '100%' }}
          margin={{
            right: 20
          }}
        >
          <XAxis type="category" dataKey="month" />
          <YAxis
            width={30}
            yAxisId={0}
            orientation="left"
            stroke={'#000'}
            tickSize={0}
            tick={{
              transform: 'translate(-10, 0)'
            }}
            type="number"
            dataKey="touchpoint"
          />
          <Tooltip active itemStyle={{ textTransform: 'capitalize' }} />
          <Bar
            maxBarSize={10}
            dataKey="touchpoint"
            fill={'#63666A'}
            radius={20}
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default VerticalBarChartTemplate;
