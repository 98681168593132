const setSelectedTab = (newTab: any) => ({
  type: 'SET_SELECTED_TAB',
  newTab
});

export const setSelectedCustomerTab = (newCustomerTab: any) => ({
  type: 'SET_SELECTED_CUSTOMER_TAB',
  newCustomerTab
});
export const setSelectedSalesPrioritizationTab = (newTab: any) => ({
  type: 'SET_SELECTED_SALES_PRIORITIZATION_TAB',
  newTab
});

export const setSelectedCustomerTabContent = (newCustomerTabContent: any) => ({
  type: 'SET_SELECTED_CUSTOMER_TAB_CONTENT',
  newCustomerTabContent
});

export const setSelectedYearToggle = (newYearToggle: any) => ({
  type: 'SET_SELECTED_YEAR_TOGGLE',
  newYearToggle
});
const setSelectedDollarsUnitsToggle = (newDollarsUnitsToggle: any) => ({
  type: 'SET_SELECTED_UNITS_DOLLARS_TOGGLE',
  newDollarsUnitsToggle
});

const linkToCustomerTab = (
  topNavTab: number,
  subNavTab: number,
  filterValue: any
) => ({
  type: 'LINK_TO_CUSTOMER_TAB',
  topNavTab,
  subNavTab,
  filterValue
});

export const setStateData = (
  widgetId: string,
  selectedYear: any,
  selectedMeasure: any
) => ({
  type: 'SET_STATE_DATA',
  widgetId,
  selectedYear,
  selectedMeasure
});

const setSelectedCustomerTitleFilter = (newFilter: any, actorType: string) => ({
  type: 'SET_SELECTED_FILTER',
  newFilter,
  actorType
});

const setFilter = (filterName: string, newFilter: any) => ({
  type: 'SET_FILTER',
  filterName,
  newFilter
});

const setPerformanceFilter = (filterName: any) => ({
  type: 'SET_PERFORMANCE_FILTER',
  filterName
});

// const setEstimatedValues = (
//   newEstimatedValue: any,
//   newEstimatedValueArray: any
// ) => ({
//   type: 'SET_ESTIMATE_VALUE',
//   newEstimatedValue,
//   newEstimatedValueArray
// });

// const setPlannedValues = (newPlannedValue: any, newPlannedValueArray: any) => ({
//   type: 'SET_PLAN_VALUE',
//   newPlannedValue,
//   newPlannedValueArray
// });

const getSalesTableData = (filters: any, actorType: string) => ({
  type: 'GET_SALES_TABLE_DATA',
  filters,
  actorType
});

const setTableInputValues = (
  tableId: string,
  rowId: string,
  columnId: string,
  value: string
) => ({
  type: 'SET_TABLE_INPUT_VALUES',
  tableId,
  rowId,
  columnId,
  value
});

const setTableInputValuesState = (newStateObject: any) => ({
  type: 'SET_TABLE_INPUT_VALUES_STATE',
  newStateObject
});

const setFormInputValues = (
  formId: string,
  formField: string,
  value: string,
  isRequired: boolean
) => ({
  type: 'SET_FORM_INPUT_VALUES',
  formId,
  formField,
  value,
  isRequired
});

const setStagedFormInputValuesState = (newStateObject: any) => ({
  type: 'SET_STAGED_FORM_INPUT_VALUES_STATE',
  newStateObject
});

const setFormInputValuesState = () => ({
  type: 'SET_FORM_INPUT_VALUES_STATE'
});

const cancelFormValues = () => ({
  type: 'CANCEL_FORM_VALUES'
});

const setSubscription = (subscriptionId: number, subscribed: boolean) => ({
  type: 'SET_SUBSCRIPTION',
  subscriptionId,
  subscribed
});

export {
  getSalesTableData,
  setSelectedTab,
  setSelectedCustomerTitleFilter,
  setSelectedDollarsUnitsToggle,
  setTableInputValues,
  setTableInputValuesState,
  setFormInputValues,
  setStagedFormInputValuesState,
  setFormInputValuesState,
  cancelFormValues,
  setSubscription,
  linkToCustomerTab,
  setFilter,
  setPerformanceFilter
};
