export const getApplicationTables = (state: any) => state.applicationTables;

export const getApplicationForms = (state: any) => state.applicationForms; // appForms.appForms, appForms.stagedAppForms
export const getApplicationTableValue = (
  state: any,
  tableId: any,
  rowId: number,
  columnId: number
) =>
  state.applicationTables[tableId] &&
  state.applicationTables[tableId][rowId] &&
  state.applicationTables[tableId][rowId][columnId]
    ? state.applicationTables[tableId][rowId][columnId]
    : null;
