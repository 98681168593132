import { takeEvery, put } from 'redux-saga/effects';
import {
  setSelectedTab,
  setSelectedCustomerTab,
  setSelectedCustomerTabContent,
  setSelectedCustomerTitleFilter
} from '../actions/index';
import {
  CustomerFilterData
  // , getAllGrowerInfo
} from '../data';
import _ from 'lodash';
// const growerInfo = _.uniqBy(getAllGrowerInfo(), 'growerName');

const getActorType = (filterValue: any) => {
  // TODO: abstract out hardcoded retailer vs grower when data and config are available
  const retailer = Object.keys(CustomerFilterData)
    .map((customerRollup: any) => CustomerFilterData[customerRollup].locations)
    .flat()
    .find((item: any) => item === filterValue);
  // const grower = growerInfo.find((item: any) => item.growerName === filterValue)
  return !_.isNil(retailer) ? 'RETAILER' : 'GROWER';
};

function* linkToCustomerTab({ topNavTab, subNavTab, filterValue }: any) {
  yield put(
    setSelectedCustomerTitleFilter(filterValue, getActorType(filterValue))
  );
  yield put(setSelectedTab(4)); // customerTab
  yield put(setSelectedCustomerTabContent(subNavTab));
  yield put(setSelectedCustomerTab(topNavTab));
}

function* linkToCustomerTabSaga() {
  yield takeEvery('LINK_TO_CUSTOMER_TAB', linkToCustomerTab);
}

export default linkToCustomerTabSaga;
