import React from 'react';
import { getPercentTickColors } from './HorizontalBarChartTemplate';

export const HorizontalBarLabels = (props: any) => {
  return (
    <g>
      <text
        style={{
          fontSize: '.70em',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
        }}
        x={props.x + -110}
        y={props.y + 4}
      >
        {props.payload.value}
      </text>
    </g>
  );
};

const HorizontalBarYaxis = (props: any) => {
  return (
    <g>
      <text
        style={{
          fontSize: '.85em',
          margin: '10em',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
          textAnchor: 'end'
        }}
        fill={getPercentTickColors(props.payload.value)}
        x={props.x + 90}
        y={props.y + 4}
      >
        {props.payload.value}%
      </text>
    </g>
  );
};

export const HorizontalBarYaxisDollars = (props: any) => {
  return (
    <g>
      <text
        style={{
          fontSize: '.85em',
          margin: '10em',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
          textAnchor: 'end'
        }}
        fill={getPercentTickColors(props.payload.value)}
        x={props.x + 90}
        y={props.y + 4}
      >
        {Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        }).format(props.payload.value)}
      </text>
    </g>
  );
};

export default HorizontalBarYaxis;
