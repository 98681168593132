import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FilterRadio from '../FilterRadio';
import Radio from '@material-ui/core/Radio';

//TODO refactor to remove hard coding and use FilterRadio that already exists by refactoring that file a bit too
const FilterToggleTemplate = (props: any) => {
  const { setFilter, filters, selected, filterName, filterConfig } = props;

  //TODO combine theses two changeSelection functions
  const changeSelectionA = () => {
    setFilter('salesPerformanceUnitsToggle', false);
    setFilter('salesPerformanceDollarsToggle', true);
  };

  const changeSelectionB = () => {
    setFilter('salesPerformanceUnitsToggle', true);
    setFilter('salesPerformanceDollarsToggle', false);
  };

  const getToggleType = () => {
    switch (filterConfig.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={selected}
                color="primary"
                onClick={() => {
                  setFilter(filterName, !selected);
                }}
              />
            }
            label={filterConfig.label}
          />
        );
      case 'radioA':
        return (
          <FormControlLabel
            label={filterConfig.label}
            control={
              <Radio
                value={selected}
                checked={
                  filters['salesPerformanceUnitsToggle'].selected === false &&
                  selected === true
                }
                onClick={() => changeSelectionA()}
              />
            }
          />
        );
      case 'radioB':
        return (
          <FormControlLabel
            label={filterConfig.label}
            control={
              <Radio
                value={selected}
                checked={
                  filters['salesPerformanceDollarsToggle'].selected === false &&
                  selected === true
                }
                onClick={() => changeSelectionB()}
              />
            }
          />
        );
    }
  };
  return <div>{getToggleType()}</div>;
};

export default FilterToggleTemplate;
