import _ from 'lodash';
// parse salesData
// group by product
// aggregate -- _.sumBy(obj.columns)
//
// -- add percent change, add share of wallet

export const productInfo = [
  {
    id: 0,
    productName: 'Grain Corn',
    currentYearUnits: 5300, //red
    previousYearUnits: 7500, //green
    currentYearDollars: 25350, //red
    previousYearDollars: 43000, //green
    currentYearUnitsPercentChange: -30,
    previousYearUnitsPercentChange: 42,
    currentYearDollarPercentChange: -41,
    previousYearDollarPercentChange: 69,
    aggioEstimatedPurchases: 20000
  },
  {
    id: 1,
    productName: 'Soybeans',
    currentYearUnits: 1200, //red
    previousYearUnits: 1300, //green
    currentYearDollars: 9800, //red
    previousYearDollars: 15200, //green
    currentYearUnitPercentChange: -8,
    previousYearUnitPercentChange: 8,
    currentYearDollarPercentChange: -35,
    previousYearDollarPercentChange: 55,
    aggioEstimatedPurchases: 2500
  },
  {
    id: 2,
    productName: 'Sunflower',
    currentYearUnits: 200, //green
    previousYearUnits: 200, //green
    currentYearDollars: 3400, //green
    previousYearDollars: 3400, //green
    currentYearUnitPercentChange: 0,
    previousYearUnitPercentChange: 0,
    currentYearDollarPercentChange: 0,
    previousYearDollarPercentChange: 0,
    aggioEstimatedPurchases: 1500
  },
  {
    id: 3,
    productName: 'Canola',
    currentYearUnits: 190, //red
    previousYearUnits: 200, //green
    currentYearDollars: 1500, //green
    previousYearDollars: 800, //red
    currentYearUnitPercentChange: -5,
    previousYearUnitPercentChange: 5,
    currentYearDollarPercentChange: 87,
    previousYearDollarPercentChange: -47,
    aggioEstimatedPurchases: 1000
  }
];

export const oldGrowerInfo = [
  {
    id: 0,
    name: 'Bradley Davidson',
    location: 'Clinton, IA',
    product: [
      {
        id: 0,
        productName: 'Grain Corn',
        previousYearSales: 7500,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 1,
        productName: 'Soybeans',
        previousYearSales: 15000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 2,
        productName: 'Sunflower',
        previousYearSales: 10000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 3,
        productName: 'Canola',
        previousYearSales: 10000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      }
    ]
  },
  {
    id: 1,
    name: 'Leon Chambers',
    location: 'Kansas City, KS',
    product: [
      {
        id: 0,
        productName: 'Grain Corn',
        previousYearSales: 2700,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 1,
        productName: 'Soybeans',
        previousYearSales: 13000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 2,
        productName: 'Sunflower',
        previousYearSales: 11000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 3,
        productName: 'Canola',
        previousYearSales: 12000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      }
    ]
  },
  {
    id: 2,
    name: 'Sebastian Howard',
    location: 'Ankeny, IA',
    product: [
      {
        id: 0,
        productName: 'Grain Corn',
        previousYearSales: 3300,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 1,
        productName: 'Soybeans',
        previousYearSales: 14000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 2,
        productName: 'Sunflower',
        previousYearSales: 12000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 3,
        productName: 'Canola',
        previousYearSales: 12000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      }
    ]
  },
  {
    id: 3,
    name: 'Arthur Booth',
    location: 'Des Moines, IA',
    product: [
      {
        id: 0,
        productName: 'Grain Corn',
        previousYearSales: 2600,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 1,
        productName: 'Soybeans',
        previousYearSales: 13000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 2,
        productName: 'Sunflower',
        previousYearSales: 11000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      },
      {
        id: 3,
        productName: 'Canola',
        previousYearSales: 12000,
        previousYearUnits: 2000,
        currentYearUnits: 3000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000,
        previousYearValue: 3000
      }
    ]
  }
];

export const sellerInfo = [
  {
    id: 0,
    name: 'Steven Marlowe',
    toWhom: [
      {
        id: 0,
        name: 'Bradley Davidson',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1020,
            previousYearUnit: 1030,
            currentYearDollars: 2040,
            previousYearDollars: 2040,
            currentYearOrders: 3030,
            currentYearShipped: 1540,
            currentYearValue: 4050
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1010,
            previousYearUnit: 1090,
            currentYearDollars: 2100,
            previousYearDollars: 2200,
            currentYearOrders: 3009,
            currentYearShipped: 1530,
            currentYearValue: 4020
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1400,
            previousYearUnit: 1060,
            currentYearDollars: 2700,
            previousYearDollars: 2300,
            currentYearOrders: 3006,
            currentYearShipped: 1530,
            currentYearValue: 4070
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1020,
            previousYearUnit: 1060,
            currentYearDollars: 2040,
            previousYearDollars: 2200,
            currentYearOrders: 3009,
            currentYearShipped: 1530,
            currentYearValue: 4009
          }
        ]
      },
      {
        id: 1,
        name: 'Leon Chambers',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1040,
            previousYearUnit: 1006,
            currentYearDollars: 2090,
            previousYearDollars: 2900,
            currentYearOrders: 3007,
            currentYearShipped: 1570,
            currentYearValue: 4008
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1004,
            currentYearDollars: 2300,
            previousYearDollars: 2000,
            currentYearOrders: 3040,
            currentYearShipped: 1400,
            currentYearValue: 3000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1500,
            previousYearUnit: 2000,
            currentYearDollars: 2300,
            previousYearDollars: 2040,
            currentYearOrders: 3004,
            currentYearShipped: 1540,
            currentYearValue: 4040
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1030,
            currentYearDollars: 2020,
            previousYearDollars: 2030,
            currentYearOrders: 3003,
            currentYearShipped: 1530,
            currentYearValue: 4003
          }
        ]
      },
      {
        id: 2,
        name: 'Sebastian Howard',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 2000,
            previousYearUnit: 1400,
            currentYearDollars: 2080,
            previousYearDollars: 2080,
            currentYearOrders: 3080,
            currentYearShipped: 1580,
            currentYearValue: 4088
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1022,
            previousYearUnit: 1220,
            currentYearDollars: 2220,
            previousYearDollars: 2022,
            currentYearOrders: 3022,
            currentYearShipped: 1520,
            currentYearValue: 4022
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1230,
            previousYearUnit: 1033,
            currentYearDollars: 2032,
            previousYearDollars: 2320,
            currentYearOrders: 3032,
            currentYearShipped: 1523,
            currentYearValue: 4040
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1030,
            previousYearUnit: 1050,
            currentYearDollars: 2070,
            previousYearDollars: 2500,
            currentYearOrders: 3004,
            currentYearShipped: 1530,
            currentYearValue: 4004
          }
        ]
      },
      {
        id: 3,
        name: 'Arthur Booth',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1003,
            previousYearUnit: 1023,
            currentYearDollars: 2045,
            previousYearDollars: 2540,
            currentYearOrders: 3050,
            currentYearShipped: 1550,
            currentYearValue: 4054
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1034,
            previousYearUnit: 1065,
            currentYearDollars: 2060,
            previousYearDollars: 2060,
            currentYearOrders: 3070,
            currentYearShipped: 1580,
            currentYearValue: 4034
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1034,
            previousYearUnit: 1000,
            currentYearDollars: 2034,
            previousYearDollars: 2230,
            currentYearOrders: 3034,
            currentYearShipped: 1530,
            currentYearValue: 4023
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1300,
            previousYearUnit: 1003,
            currentYearDollars: 2400,
            previousYearDollars: 2050,
            currentYearOrders: 3000,
            currentYearShipped: 1540,
            currentYearValue: 4040
          }
        ]
      }
    ],
    products: [
      {
        id: 0,
        productName: 'Grain Corn',
        currentYearUnits: 1020, //red
        previousYearUnits: 920, //green
        currentYearDollars: 5000, //red
        previousYearDollars: 4300,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000 //green
      },
      {
        id: 1,
        productName: 'Soybeans',
        currentYearUnits: 200, //red
        previousYearUnits: 130, //green
        currentYearDollars: 980, //red
        previousYearDollars: 1520,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 2,
        productName: 'Sunflower',
        currentYearUnits: 20, //green
        previousYearUnits: 20, //green
        currentYearDollars: 340, //green
        previousYearDollars: 340,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 3,
        productName: 'Canola',
        currentYearUnits: 19, //red
        previousYearUnits: 20, //green
        currentYearDollars: 150, //green
        previousYearDollars: 80,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      }
    ]
  },
  {
    id: 1,
    name: 'Melissa Allova',
    toWhom: [
      {
        id: 0,
        name: 'Bradley Davidson',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 1,
        name: 'Leon Chambers',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 2,
        name: 'Sebastian Howard',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 3,
        name: 'Arthur Booth',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      }
    ],
    products: [
      {
        id: 0,
        productName: 'Grain Corn',
        currentYearUnits: 1983,
        previousYearUnits: 840,
        currentYearDollars: 5050,
        previousYearDollars: 4000,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 1,
        productName: 'Soybeans',
        currentYearUnits: 220,
        previousYearUnits: 170,
        currentYearDollars: 880,
        previousYearDollars: 1120,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 2,
        productName: 'Sunflower',
        currentYearUnits: 30,
        previousYearUnits: 40,
        currentYearDollars: 340,
        previousYearDollars: 380,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 3,
        productName: 'Canola',
        currentYearUnits: 29,
        previousYearUnits: 40,
        currentYearDollars: 200,
        previousYearDollars: 100,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      }
    ]
  },
  {
    id: 2,
    name: 'Eva Matthews',
    products: [
      {
        id: 0,
        productName: 'Grain Corn',
        currentYearUnits: 2220,
        previousYearUnits: 1030,
        currentYearDollars: 5090,
        previousYearDollars: 4900,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 1,
        productName: 'Soybeans',
        currentYearUnits: 400,
        previousYearUnits: 330,
        currentYearDollars: 1980,
        previousYearDollars: 1420,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 2,
        productName: 'Sunflower',
        currentYearUnits: 10,
        previousYearUnits: 13,
        currentYearDollars: 140,
        previousYearDollars: 180,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      },
      {
        id: 3,
        productName: 'Canola',
        currentYearUnits: 49,
        previousYearUnits: 40,
        currentYearDollars: 250,
        previousYearDollars: 120,
        currentYearOrders: 3000,
        currentYearShipped: 1500,
        currentYearValue: 4000
      }
    ],
    toWhom: [
      {
        id: 0,
        name: 'Bradley Davidson',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 1,
        name: 'Leon Chambers',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 2,
        name: 'Sebastian Howard',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      },
      {
        id: 3,
        name: 'Arthur Booth',
        products: [
          {
            id: 0,
            productName: 'Grain Corn',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 1,
            productName: 'Soybeans',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 2,
            productName: 'Sunflower',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          },
          {
            id: 3,
            productName: 'Canola',
            currentYearUnits: 1000,
            previousYearUnit: 1000,
            currentYearDollars: 2000,
            previousYearDollars: 2000,
            currentYearOrders: 3000,
            currentYearShipped: 1500,
            currentYearValue: 4000
          }
        ]
      }
    ]
  }
];

export const programDataRevised = [
  {
    product: 'Grain Corn',
    tiers: [
      {
        tier: 'Silver',
        reward: 5,
        label: '$5/Unit',
        minUnits: 0
      },
      {
        tier: 'Gold',
        reward: 10,
        label: '$10/Unit',
        minUnits: 1000
      },
      {
        tier: 'Diamond',
        reward: 15,
        label: '$15/Unit',
        minUnits: 2000
      }
    ]
  },
  {
    product: 'Soybean',
    tiers: [
      {
        tier: 'Silver',
        reward: 5,
        label: '$5/Unit',
        minUnits: 0
      },
      {
        tier: 'Gold',
        reward: 10,
        label: '$10/Unit',
        minUnits: 1000
      },
      {
        tier: 'Diamond',
        reward: 15,
        label: '$15/Unit',
        minUnits: 2000
      }
    ]
  },
  {
    product: 'Canola',
    tiers: [
      {
        tier: 'Silver',
        reward: 5,
        label: '$5/Unit',
        minUnits: 0
      },
      {
        tier: 'Gold',
        reward: 10,
        label: '$10/Unit',
        minUnits: 1000
      },
      {
        tier: 'Diamond',
        reward: 15,
        label: '$15/Unit',
        minUnits: 2000
      }
    ]
  }
];
export const retailerInfo = [
  {
    id: 0,
    aggioRank: 1,
    retailerLocation: '',
    retailerName: 'Seed Company',
    location: 'Cityville, IA',
    productName: 'Corn',
    segment: 'MAINTAIN',
    currentYearUnits: 110,
    previousYearUnits: 164,
    currentYearValue: 5500,
    previousYearValue: 8200,
    shareOfWallet: 29,
    opportunity: 18600
  },
  {
    id: 19,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'West Central Ag',
    location: 'Clinton, IA',
    aggioRank: 2,
    segment: 'GROW',
    currentYearValue: 5500,
    previousYearValue: 7000,
    currentYearUnits: 110,
    previousYearUnits: 140,
    shareOfWallet: 36,
    opportunity: 17500
  },
  {
    id: 1,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'CPS',
    location: 'Ankeny, IA',
    aggioRank: 3,
    segment: 'MAINTAIN',
    currentYearValue: 7000,
    previousYearValue: 6400,
    currentYearUnits: 140,
    previousYearUnits: 128,
    shareOfWallet: 15,
    opportunity: 14200
  },
  {
    id: 2,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Clinton, IA',
    aggioRank: 4,
    segment: 'GROW',
    currentYearValue: 9200,
    previousYearValue: 8900,
    currentYearUnits: 184,
    previousYearUnits: 178,
    shareOfWallet: 20,
    opportunity: 13500
  },
  {
    id: 3,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Seed Company',
    location: 'Cityville, IA',
    aggioRank: 5,
    segment: 'MAINTAIN',
    currentYearValue: 5000,
    previousYearValue: 4300,
    currentYearUnits: 100,
    previousYearUnits: 86,
    shareOfWallet: 16,
    opportunity: 12900
  },
  {
    id: 4,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'West Central Ag',
    location: 'Clinton, IA',
    aggioRank: 6,
    segment: 'GROW',
    currentYearValue: 6500,
    previousYearValue: 2200,
    currentYearUnits: 130,
    previousYearUnits: 44,
    shareOfWallet: 38,
    opportunity: 10600
  },
  {
    id: 5,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'CPS',
    location: 'Ankeny, IA',
    aggioRank: 7,
    segment: 'MAINTAIN',
    currentYearValue: 2800,
    previousYearValue: 3700,
    currentYearUnits: 56,
    previousYearUnits: 74,
    shareOfWallet: 27,
    opportunity: 8900
  },
  {
    id: 6,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Des Moines, IA',
    aggioRank: 8,
    segment: 'GROW',
    currentYearValue: 7400,
    previousYearValue: 6000,
    currentYearUnits: 148,
    previousYearUnits: 120,
    shareOfWallet: 24,
    opportunity: 6300
  },
  {
    id: 7,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'CPS',
    location: 'Ankeny, IA',
    aggioRank: 9,
    segment: 'MAINTAIN',
    currentYearValue: 8000,
    previousYearValue: 5600,
    currentYearUnits: 160,
    previousYearUnits: 112,
    shareOfWallet: 18,
    opportunity: 5400
  },
  {
    id: 8,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Clinton, IA',
    aggioRank: 10,
    segment: 'GROW',
    currentYearValue: 3000,
    previousYearValue: 1800,
    currentYearUnits: 60,
    previousYearUnits: 36,
    shareOfWallet: 8,
    opportunity: 4900
  },
  {
    id: 9,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Seed To Seed',
    location: 'Cityville, IA',
    aggioRank: 11,
    segment: 'MAINTAIN',
    currentYearValue: 3500,
    previousYearValue: 4200,
    currentYearUnits: 190,
    previousYearUnits: 154,
    shareOfWallet: 49,
    opportunity: 17600
  },
  {
    id: 10,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Agrowers Co.',
    location: 'Clinton, IA',
    aggioRank: 12,
    segment: 'GROW',
    currentYearValue: 4500,
    previousYearValue: 6000,
    currentYearUnits: 210,
    previousYearUnits: 240,
    shareOfWallet: 56,
    opportunity: 17800
  },
  {
    id: 11,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Crop Saver',
    location: 'Ankeny, IA',
    aggioRank: 13,
    segment: 'MAINTAIN',
    currentYearValue: 6000,
    previousYearValue: 5400,
    currentYearUnits: 120,
    previousYearUnits: 108,
    shareOfWallet: 25,
    opportunity: 14600
  },
  {
    id: 12,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Clinton, IA',
    aggioRank: 14,
    segment: 'GROW',
    currentYearValue: 9200,
    previousYearValue: 8900,
    currentYearUnits: 184,
    previousYearUnits: 178,
    shareOfWallet: 20,
    opportunity: 13500
  },
  {
    id: 13,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Bonny Plants',
    location: 'Cityville, IA',
    aggioRank: 15,
    segment: 'MAINTAIN',
    currentYearValue: 6000,
    previousYearValue: 5300,
    currentYearUnits: 200,
    previousYearUnits: 96,
    shareOfWallet: 26,
    opportunity: 13000
  },
  {
    id: 14,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'West Central Ag',
    location: 'Clinton, IA',
    aggioRank: 16,
    segment: 'GROW',
    currentYearValue: 6500,
    previousYearValue: 2200,
    currentYearUnits: 130,
    previousYearUnits: 44,
    shareOfWallet: 38,
    opportunity: 10600
  },
  {
    id: 15,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'CPS',
    location: 'Ankeny, IA',
    aggioRank: 17,
    segment: 'MAINTAIN',
    currentYearValue: 2800,
    previousYearValue: 3700,
    currentYearUnits: 56,
    previousYearUnits: 74,
    shareOfWallet: 27,
    opportunity: 8900
  },
  {
    id: 16,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Des Moines, IA',
    aggioRank: 18,
    segment: 'GROW',
    currentYearValue: 7400,
    previousYearValue: 6000,
    currentYearUnits: 148,
    previousYearUnits: 120,
    shareOfWallet: 24,
    opportunity: 6300
  },
  {
    id: 17,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Americrops Intl.',
    location: 'Ankeny, IA',
    aggioRank: 19,
    segment: 'MAINTAIN',
    currentYearValue: 7000,
    previousYearValue: 4600,
    currentYearUnits: 170,
    previousYearUnits: 212,
    shareOfWallet: 18,
    opportunity: 5404
  },
  {
    id: 18,
    productName: 'Corn',
    retailerLocation: '',
    retailerName: 'Ag Tech Iowa',
    location: 'Clinton, IA',
    aggioRank: 20,
    segment: 'GROW',
    currentYearValue: 3000,
    previousYearValue: 1800,
    currentYearUnits: 60,
    previousYearUnits: 36,
    shareOfWallet: 8,
    opportunity: 4900
  }
];
