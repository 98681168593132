import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HorizontalBarChartTemplate from './HorizontalBarChartTemplate';
import { programInfo } from '../data';
import { Cell } from 'recharts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

export const getBarColors = (current: number, previous: number) => {
  return current >= previous ? '#84bd00' : '#EB5858'; //green : red
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    chart: {
      display: 'flex',
      width: '100%',
      paddingLeft: '2%'
    }
  })
);

const YearToggleContentTemplate = (props: any) => {
  const selectedDollarsUnitsToggle = props.selectedDollarsUnitsToggle;
  const selectedYearToggle = props.selectedYearToggle;
  const stateData = props.stateData;

  const showDollarsUnitsToggle = props.showDollarsUnitsToggle;
  const productInfo = props.productInfo;

  const productBarColor = productInfo.map((product: any) => {
    const color =
      selectedYearToggle === 0
        ? selectedDollarsUnitsToggle.checked
          ? getBarColors(
              product.currentYearDollars,
              product.previousYearDollars
            )
          : getBarColors(product.currentYearUnits, product.previousYearUnits)
        : selectedDollarsUnitsToggle.checked
        ? getBarColors(product.previousYearDollars, product.currentYearDollars)
        : getBarColors(product.previousYearUnits, product.currentYearUnits);
    return <Cell key={product} fill={color} />;
  });

  const programBarColor = programInfo.map((program: any) => {
    const color =
      selectedYearToggle === 0
        ? getBarColors(program.currentYearDollars, program.previousYearDollars)
        : getBarColors(program.previousYearDollars, program.currentYearDollars);
    return <Cell key={program} fill={color} />;
  });

  const setBarColors = (switchToggle: boolean) => {
    return switchToggle ? productBarColor : programBarColor;
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TabPanel value={selectedYearToggle} index={selectedYearToggle}>
        <div className={classes.chart}>
          <HorizontalBarChartTemplate
            selectedDollarsUnitsToggle={selectedDollarsUnitsToggle}
            data={stateData}
            barColors={setBarColors(showDollarsUnitsToggle)}
            comparisonFormat={props.comparisonFormat}
          />
        </div>
      </TabPanel>
    </div>
  );
};
export default YearToggleContentTemplate;
