import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Slider, TextField } from '@material-ui/core';
// import * as _ from 'lodash';

const SliderTemplate = (props: any) => {
  const { value, tableId, rowId, columnId, setTableInputValues } = props;
  const [sliderValue, setSliderValue] = useState(value);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      slider: {
        color: 'green'
      },
      input: {
        borderBottom: '1px'
      }
    })
  );

  const marks = [
    {
      value: 1000
    },
    {
      value: 2000
    }
  ];

  const classes = useStyles();

  const updateValue = (newValue: string) =>
    setTableInputValues(tableId, rowId, columnId, newValue);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '250px' }}>
      <Slider
        className={classes.slider}
        value={sliderValue}
        defaultValue={0}
        min={0}
        max={8000}
        step={1}
        valueLabelDisplay={'auto'}
        marks={marks}
        style={{ backgroundColor: 'transparent' }}
        onChange={(ev: any, val: any) => {
          setSliderValue(val);
          updateValue(val);
        }}
      />
      <TextField
        className={classes.input}
        value={sliderValue}
        style={{ width: '90px', marginLeft: '20px' }}
        onChange={(ev: any) => {
          setSliderValue(ev.target.value);
          updateValue(ev.target.value);
        }}
      />
    </div>
  );
};

export default SliderTemplate;
