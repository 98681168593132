import React from 'react';
import MUIRichTextEditor from 'mui-rte';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      minWidth: 275,
      height: 'fit-content',
      marginBottom: '10px'
    },
    editor: {
      border: '1px #F5F5F5 solid',
      minHeight: '40vh'
    },
    button: {
      display: 'grid',
      justifyContent: 'end',
      paddingRight: '20px',
      marginBottom: '10px'
    }
  })
);

const save = (data: any) => {
  console.log('data', data);
};

const EditableTextBoxTemplate = () => {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          textAlign: 'left',
          paddingTop: '30px',
          fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif'
        }}
      >
        Tactics{' '}
      </div>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.editor}>
            <MUIRichTextEditor
              label="Type here..."
              onSave={save}
              inlineToolbar={true}
            />
          </div>
        </CardContent>
        <div className={classes.button}>
          <Button onClick={save} variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default EditableTextBoxTemplate;
