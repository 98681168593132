import React, { Fragment } from 'react';
import { IRouteConfig } from '../components/RoutingController';
import CustomerTabContainer from '../components/CustomerTabContainer';
import CustomerTitleFilterContainer from '../components/CustomerTitleFilterContainer';
import TableContainer from '../components/TableContainer';
import OverviewSubTabContainer from '../components/OverviewSubTabContainer';
import SalesPrioritizationRetailersContainer from '../components/SalesPrioritizationRetailersContainer';
import FormContainer from '../components/FormContainer';
import TableWithQuery from '../components/TableWithQuery';
import ContactLineContainer from '../components/ContactLineContainer';
import CustomerTabSalesContainer from '../components/CustomerTabSalesContainer';
import SubscribeToProgramsContainer from '../components/SubscribeToProgramsContainer';
import { getRelevantRoutes, getRelevantComponents } from '../utils';
import WidgetGridContainer from '../components/WidgetGridContainer';
import LargeWidgetTemplateContainer from '../components/LargeWidgetTemplateContainer';
import TrendWidgetContainer from '../components/TrendWidgetContainer';
import VolumeWidgetContainer from '../components/VolumeWidgetContainer';
import SmallWidgetTemplate from '../components/SmallWidgetTemplate';
import VerticalBarChartContainer from '../components/VerticalBarChartContainer';

import EditableTextBoxTemplate from '../components/EditableTextBoxTemplate';
import {
  getOperationsTableData,
  getPlanTableData,
  getSalesTableData,
  actualEarningsTableData,
  valueAddedTableData,
  newClaimsData,
  newCompetitivesData,
  variousProgramsData,
  salesPrioritizationRetailersTableData,
  salesPrioritizationGrowersTableData,
  makeEstimatedProgramEarningsData,
  // salesPerformanceProductData,
  getSalesPerformanceRetailersTableData,
  getSalesPerformanceGrowersTableData,
  getSalesPerformanceProductsTableData,
  // salesPerformanceGrowerData
  widgetInfo,
  getAccountPlan,
  getShareOfWallet,
  touchPointWidgetData,
  getTrendData,
  getWidgetInfoVolume,
  getRetailersOwnContactTableData,
  getRetailerContactsTableData,
  getRetailerRecentActivityTableData
} from '../data';
import _ from 'lodash';
import { formFieldInfo } from '../data/valueAddedData';
import { getDataForSalesOrPayments } from '../data/overview';

const getData = (mockDataIndicator: string, metadata?: any) => {
  switch (mockDataIndicator) {
    case 'operationsTableData':
      return getOperationsTableData;
    case 'getPlanTableData':
      return getPlanTableData;
    case 'getSalesTableData':
      return getSalesTableData(metadata);
    case 'actualEarningsTableData':
      return actualEarningsTableData(metadata);
    case 'valueAddedTableData':
      return valueAddedTableData;
    case 'getRetailersOwnContactTableData': 
      return getRetailersOwnContactTableData(metadata);
    case 'getRetailerContactsTableData': 
      return getRetailerContactsTableData(metadata);
    case 'getRetailerRecentActivityTableData':
      return getRetailerRecentActivityTableData(metadata);
    case 'newClaimsData':
      return newClaimsData(metadata);
    case 'newCompetitivesData':
      return newCompetitivesData;
    case 'variousPrograms':
      return variousProgramsData;
    case 'salesPrioritizationRetailersTableData':
      return salesPrioritizationRetailersTableData;
    case 'salesPrioritizationGrowersTableData':
      return salesPrioritizationGrowersTableData;
    case 'makeEstimatedProgramEarningsData':
      return makeEstimatedProgramEarningsData;
    case 'formFieldInfo':
      return formFieldInfo;
    case 'salesPerformanceProductsTableData':
      return getSalesPerformanceProductsTableData(metadata);
    case 'salesPerformanceRetailersTableData':
      return getSalesPerformanceRetailersTableData(metadata);
    case 'salesPerformanceGrowersTableData':
      return getSalesPerformanceGrowersTableData(metadata);
    case 'getAccountPlan':
      return getAccountPlan;
    case 'getShareOfWallet':
      return getShareOfWallet;
    case 'touchPointWidgetData':
      return touchPointWidgetData;
    case 'getWidgetInfoSales':
      return getDataForSalesOrPayments;
    case 'getWidgetInfoPayments':
      return getDataForSalesOrPayments;
    case 'getTrendData':
      return getTrendData(metadata);
    case 'getWidgetInfoVolume':
      return getWidgetInfoVolume;
  }
};

export const getComponents = (
  routeConfigItem: IRouteConfig,
  makeRoutes: any,
  actorType: string,
) => {
  const rci = routeConfigItem;
  const currentActorType = actorType;
  let components = getRelevantComponents(
    rci,
    currentActorType,
  );
  const getRoute = () => {
    switch (rci.type) {
      case 'WidgetGrid':
        if (components.length > 0) {
          const reactComponents = components.map((componentConfig: any) =>
            getComponent(componentConfig)
          );
          return (
            <WidgetGridContainer
              components={reactComponents}
              metadata={rci.metadata}
            >
              {reactComponents.map(
                (reactComponent: React.FunctionComponent, index: number) => {
                  return <Fragment key={index}>{reactComponent}</Fragment>;
                }
              )}
            </WidgetGridContainer>
          );
        }
      case 'thoroughfare':
      case 'content':
        if (components.length > 0) {
          const reactComponents = components.map((componentConfig: any) =>
            getComponent(componentConfig)
          );
          return (
            <Fragment>
              {reactComponents.map(
                (reactComponent: React.FunctionComponent, index: number) => {
                  return <Fragment key={index}>{reactComponent}</Fragment>;
                }
              )}
            </Fragment>
          );
        }
      default:
        return <div>thoroughfare or content, nothing here</div>;
    }
  };

  const getComponent = (component: any) => {
    const data = getData(
      _.get(component, 'metadata.fetchData.mockData', null),
      component.metadata
    );
    switch (component.type) {
      case 'Filter':
        return <CustomerTitleFilterContainer />;
      case 'Tabs':
        return (
          <CustomerTabContainer
            path={`/${component.metadata.path}`}
            navId={component.metadata.navId}
            nestedRoutes={makeRoutes(
              getRelevantRoutes(
                routeConfigItem,
                currentActorType,
              ),
              currentActorType,
            )}
          />
        );
      case 'Table':
        if (typeof data === 'function') {
          return (
            <TableContainer
              getData={data}
              subtitles={component.metadata.tableSubtitles}
              tableId={component.metadata.tableId}
            />
          );
        } else {
          return (
            <TableContainer
              data={data}
              subtitles={component.metadata.tableSubtitles}
              tableId={component.metadata.tableId}
            />
          );
        }
      case 'TableWithButtons':
        return (
          <SalesPrioritizationRetailersContainer
            getData={data}
            subtitles={false}
            tableId={component.metadata.tableId}
          />
        );
      case 'contactLine':
        return (
          <ContactLineContainer
            getData={data}
            tableId={component.metadata.tableId}
          />
        );
      case 'Dashboard':
        return <OverviewSubTabContainer />;
      case 'Text':
        return <EditableTextBoxTemplate />;
      case 'TableWithCheckboxes':
        return (
          <Fragment>
            <CustomerTabSalesContainer
              getData={data}
              subtitles={false}
              tableId={component.metadata.tableId}
              tableConfig={component}
            />
          </Fragment>
        );
      case 'Panels':
        return <SubscribeToProgramsContainer getData={data} />;

      case 'TableWithQuery':
        return (
          <TableWithQuery
            getData={data}
            subtitles={component.subtitles}
            tableId={component.metadata.tableId}
          />
        );
      case 'Form':
        return (
          <FormContainer
            form={getData(_.get(component, 'metadata.formFields', ''))}
            resetValue={_.get(component, 'metadata.resetValue', '')}
            data={data}
            subtitles={component.subtitles}
            tableId={component.tableId}
          ></FormContainer>
        );
      case 'LargeWidget':
        return (
          <LargeWidgetTemplateContainer
            widget={
              component.metadata.widgetId === 'sales'
                ? widgetInfo[0]
                : widgetInfo[1]
            }
            title={component.title}
            getData={data}
          />
        );
      case 'SmallWidget':
        return <SmallWidgetTemplate getData={data} />;
      case 'VerticalBarChart':
        return (
          <VerticalBarChartContainer
            getData={data}
            kpiTitle={component.title}
          />
        );
      case 'Trend':
        return <TrendWidgetContainer getData={data} title={component.title} />;
      case 'VolumeWidget':
        return <VolumeWidgetContainer getData={data} title={component.title} />;
      default:
        return <div>content: nothing here</div>;
    }
  };

  return getRoute();
};
