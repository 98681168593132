import React, { Fragment } from 'react';
import LargeWidgetTemplateContainer from './LargeWidgetTemplateContainer';
import { widgetInfo, pieWidgetInfo } from '../data';
import { getDataForSalesOrPayments } from '../data/overview';
import SmallWidgetTemplate from './SmallWidgetTemplate';
import Grid from '@material-ui/core/Grid';
import VerticalBarChartTemplate from './VerticalBarChartTemplate';

export const getSOWPieColors = (firstValue: number) => {
  if (firstValue <= 25) {
    return ['#EB5858', '#F5F5F5'];
  } else if (firstValue > 25 && firstValue < 39) {
    return ['#ffbf3f', '#F5F5F5'];
  } else if (firstValue >= 39) {
    return ['#84bd00', '#F5F5F5'];
  } else return ['#EB5858', '#F5F5F5'];
};

const OverviewSubTabTemplate = (props: any) => {
  const data = props.data;

  const getAccountPlanPieColors = (firstValue: number, secondValue: number) => {
    return firstValue >= secondValue
      ? ['#84bd00', '#F5F5F5']
      : ['#EB5858', '#F5F5F5'];
  };

  const accountPieColors = getAccountPlanPieColors(
    pieWidgetInfo[0].widgetData[1].value,
    pieWidgetInfo[0].widgetData[0].value
  );

  const getAccountPlanLabelColors = (
    firstValue: number,
    secondValue: number
  ) => {
    return firstValue >= secondValue ? '#84bd00' : '#EB5858';
  };

  const getSOWLabelColors = (firstValue: number) => {
    return firstValue >= 25 ? '#84bd00' : '#EB5858';
  };

  const accountLabelColors = getAccountPlanLabelColors(
    pieWidgetInfo[0].widgetData[1].value,
    pieWidgetInfo[0].widgetData[0].value
  );

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LargeWidgetTemplateContainer
            widget={widgetInfo[0]}
            getData={getDataForSalesOrPayments}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LargeWidgetTemplateContainer
            widget={widgetInfo[1]}
            getData={getDataForSalesOrPayments}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SmallWidgetTemplate
            updateLink={pieWidgetInfo[0].updateLink}
            kpiTitle={pieWidgetInfo[0].kpiTitle}
            pieColor={accountPieColors}
            kpiValue={pieWidgetInfo[0].widgetData[0].value}
            data={pieWidgetInfo[0].widgetData}
            labelColor={accountLabelColors}
            showPercentChange={pieWidgetInfo[0].showPercentChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SmallWidgetTemplate
            updateLink={pieWidgetInfo[1].updateLink}
            kpiTitle={pieWidgetInfo[1].kpiTitle}
            pieColor={getSOWPieColors(pieWidgetInfo[1].widgetData[0].value)}
            kpiValue={pieWidgetInfo[1].widgetData[0].value}
            data={pieWidgetInfo[1].widgetData}
            labelColor={getSOWLabelColors(pieWidgetInfo[1].widgetData[0].value)}
            showPercentChange={pieWidgetInfo[1].showPercentChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VerticalBarChartTemplate data={data.data} kpiTitle={data.kpiTitle} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OverviewSubTabTemplate;
