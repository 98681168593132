import { getProductInfo } from './generatedBaseData';
import { programInfo } from './';

export const getDataForSalesOrPayments = (
  widgetId: any,
  selectedYearBool: any,
  selectedMeasureCheckbox: any,
  selectedFilter: any
) => {
  const productInfo = getProductInfo(selectedFilter);
  const selectedYear = selectedYearBool === 0 ? 'current' : 'previous';
  const selectedMeasure = selectedMeasureCheckbox.checked ? 'dollars' : 'units';
  switch (widgetId) {
    case 'sales':
      if (selectedYear === 'current') {
        if (selectedMeasure === 'dollars') {
          // currentYearDollarsData
          return productInfo.map(product => ({
            units: product.currentYearDollars,
            name: product.productType,
            change: product.currentYearDollarPercentChange
          }));
        }
        if (selectedMeasure === 'units') {
          return productInfo.map(product => ({
            units: product.currentYearUnits,
            name: product.productType,
            change: product.currentYearUnitPercentChange
          }));
        }
      }
      if (selectedYear === 'previous') {
        if (selectedMeasure === 'dollars') {
          // prevYearDollarsData
          return productInfo.map(product => ({
            units: product.previousYearDollars,
            name: product.productType,
            change: product.previousYearDollarPercentChange
          }));
        }
        if (selectedMeasure === 'units') {
          return productInfo.map(product => ({
            units: product.previousYearUnits,
            name: product.productType,
            change: product.previousYearUnitPercentChange
          }));
        }
      }
    case 'payments':
      if (selectedYear === 'current') {
        return programInfo.map(program => ({
          units: program.currentYearDollars,
          name: program.programName,
          change: program.currentYearPercentChange
        }));
      }
      if (selectedYear === 'previous') {
        return programInfo.map(program => ({
          units: program.previousYearDollars,
          name: program.programName,
          change: program.previousYearPercentChange
        }));
      }
  }
};
