import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import NavBarContainer from './NavBarContainer';
import config from '../config/config.json';
import { getComponents } from '../utils/getComponents';
import { getRelevantRoutes } from '../utils/getRelevant';
import _ from 'lodash';

export interface IAlternativeConfiguration {
  actorType: string;
  useAll: boolean;
  useThese: string[];
}

export interface IRouteConfig {
  urlPath: string;
  title: string;
  icon?: string;
  routesConfigurations?: IAlternativeConfiguration[];
  componentsConfigurations?: IAlternativeConfiguration[];
  routes: IRouteConfig[];
  type: string;
  filter?: any;
  components?: any;
  routeConfigurations?: any;
  componentConfigurations?: any;
  metadata?: any;
}

export interface IRoute {
  tabLabel: string;
  nestedRoutes: IRoute[];
  icon?: any;
  exact: boolean;
  path: string;
  children?: any;
}

const routeConfig = config.routes;

const makeRoutes = (
  routeConfig: IRouteConfig[],
  actorType: string,
): IRoute[] => {
  const routes: any[] = [];
  routeConfig.map((routeConfigItem: IRouteConfig) => {
    const currentActorType = actorType;
    const useTheseRoutes = getRelevantRoutes(
      routeConfigItem,
      currentActorType,
    );
    routes.push({
      tabLabel: routeConfigItem.title,
      icon: routeConfigItem.icon ? routeConfigItem.icon : null,
      exact: routeConfigItem.urlPath !== '' ? false : true,
      path: routeConfigItem.urlPath !== '' ? `/${routeConfigItem.urlPath}` : '',
      children: (
        <Fragment>
          {getComponents(
            routeConfigItem,
            makeRoutes,
            actorType,
          )}
        </Fragment>
      ),
      nestedRoutes: makeRoutes(useTheseRoutes, actorType)
    });
  });
  return routes;
};

const RoutingController = (props: any) => {
  const { actorType} = props;
  const routes = makeRoutes(routeConfig, actorType);
  return (
    <div>
      <NavBarContainer routes={routes} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    actorType: state.filters.actorType  };
};

export default connect(mapStateToProps)(RoutingController);
