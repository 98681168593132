import volumeJSON from './demo/volume.json';
import * as _ from 'lodash';

const getWidgetInfoVolume = () => ({
  // total: makeTotal('volume', 2019),
  // totalPercentChange: makeTotalPercentChange(makeTotal('volume', 2019), makeTotal('volume', 2018)) * 100,
  total: 156,
  totalPercentChange: 15,
  totalByName: makeTotalByName('volume', 2019)
});

// const makeTotal = (field: any, year: number) => {
//   const yearData = volumeJSON.filter((rowData: any) => year === rowData.year);
//   return yearData.reduce((acc: number, next: any) => (Number(acc) + Number(next[field])), 0);
// }

// const makeTotalPercentChange = (numer: number, denom: number) => (numer - denom) / denom;

const makeTotalByName = (field: any, year: number) => {
  return volumeJSON
    .filter((rowData: any) => year === rowData.year)
    .map((row: any) => ({
      name: row.name,
      units: row[field],
      change: row.target
    }));
};

export { getWidgetInfoVolume };
