import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import LineChartTemplate from './LineChartTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 800,
      backgroundColor: '#FFF'
    },
    cardHeader: {
      paddingBottom: '0px'
    },
    cardHeaderController: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#FFF'
    },
    kpiValue: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.5em',
      backgroundColor: '#FFF'
    },
    number: {
      color: 'black',
      fontWeight: 'bold',
      paddingLeft: '16px'
    },
    productPercentChange: {
      display: 'flex',
      flexDirection: 'column'
    },
    toggleControler: {
      display: 'flex',
      width: '100%',
      backgroundColor: '#FFF'
    },
    reportLink: {
      color: '#00a3e0 ',
      textDecoration: 'none',
      fontSize: '0.75em',
      display: 'flex',
      padding: '16px 10px 0px 0px'
    },
    chart: {
      display: 'flex',
      width: '100%',
      paddingLeft: '2%'
    }
  })
);

const TrendWidgetTemplate = (props: any) => {
  const { data, title } = props;
  const percentColor = data.totalPercentChange >= 0 ? '#84bd00' : 'red';

  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardHeaderController}>
        <CardHeader
          className={classes.cardHeader}
          titleTypographyProps={{
            style: {
              fontSize: '.75em',
              display: 'flex',
              paddingBottom: '0px'
            }
          }}
          title={title}
        />
      </div>

      <div className={classes.kpiValue}>
        <span className={classes.number}>
          {`${data.totalPercent.toFixed(0)}%`}
        </span>
        <span
          style={{
            color: percentColor,
            fontSize: '.75em',
            padding: '5px 10px 0 0'
          }}
        >
          {percentColor === '#84bd00' ? (
            <ArrowUpward style={{ fontSize: '1em' }} />
          ) : (
            <ArrowDownward style={{ fontSize: '1em' }} />
          )}
          {`${data.totalPercentChange.toFixed(0)}%`}
        </span>
      </div>
      <LineChartTemplate data={data.trend} />
    </Card>
  );
};

export default TrendWidgetTemplate;
