import { connect } from 'react-redux';
import {
  // getSalesTableData,
  setFilter,
  // setPerformanceFilter,
  linkToCustomerTab
} from '../actions';
import CustomerTabSalesTemplate from './CustomerTabSalesTemplate';

import _ from 'lodash';

//TODO: Nov 8 2019, move sales table and performance tab tables out of state since there is no editable fields/cells
const growerFilter = (state: any) =>
  state.filters.retailerSalesGrowerToggle.selected;
const sellerFilter = (state: any) =>
  state.filters.retailerSalesSellerToggle.selected;
const productCategoryFilter = (state: any) =>
  state.filters.retailerSalesProductCategoryToggle.selected;
const productNameFilter = (state: any) =>
  state.filters.retailerSalesProductNameToggle.selected;
const retailerFilter = (state: any) =>
  state.filters.growerSalesRetailerToggle.selected;
const dollarsFilter = (state: any) =>
  state.filters.salesPerformanceDollarsToggle.selected;
const unitsFilter = (state: any) =>
  state.filters.salesPerformanceUnitsToggle.selected;
const performanceProductTypeFilter = (state: any) =>
  state.filters.salesPerformanceProductTypeToggle.selected;
const performanceProductCategoryFilter = (state: any) =>
  state.filters.salesPerformanceProductCategoryToggle.selected;
const performanceProductNameFilter = (state: any) =>
  state.filters.salesPerformanceProductNameToggle.selected;

const customerFilter = (state: any) => state.filters.selectedFilter;
// const getFilterName = (state: any) => {
//   if (
//     JSON.stringify([dollarsFilter(state), unitsFilter(state), productFilter(state)]) ===
//     JSON.stringify([true, false, false])
//   ) {
//     return 'salesPerformanceDollarsToggle';
//   } else if (
//     JSON.stringify([dollarsFilter(state), unitsFilter(state), productFilter(state)]) ===
//     JSON.stringify([false, true, false])
//   ) {
//     return 'salesPerformanceUnitsToggle';
//   } else if (
//     JSON.stringify([dollarsFilter(state), unitsFilter(state), productFilter(state)]) ===
//     JSON.stringify([true, false, true])
//   ) {
//     return 'salesPerformanceProductDollarsToggle';
//   } else if (
//     JSON.stringify([dollarsFilter(state), unitsFilter(state), productFilter(state)]) ===
//     JSON.stringify([false, true, true])
//   ) {
//     return 'salesPerformanceProductUnitsToggle';
//   } else return 'salesPerformanceDollarsToggle';
// };

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    subtitles: ownProps.subtitles,
    tableId: ownProps.tableId,
    data:
      ownProps.tableId === 'sales'
        ? ownProps.getData(
            [
              customerFilter(state),
              productCategoryFilter(state),
              productNameFilter(state),
              growerFilter(state),
              sellerFilter(state),
              retailerFilter(state)
            ],
            state.filters.actorType
          )
        : ownProps.getData([
            performanceProductTypeFilter(state),
            performanceProductCategoryFilter(state),
            performanceProductNameFilter(state),
            unitsFilter(state),
            dollarsFilter(state)
          ]),
    customerFilter: state.filters.selectedFilter,
    filters: state.filters
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    // getData: (
      // filters: any, actorType: string,
      // filterName?: any
    // ) =>
      // ownProps.tableId === 'sales'
      //   ? dispatch(getSalesTableData(filters, actorType))
      //   :
      // dispatch(setPerformanceFilter(filterName)),
    setFilter: (filterName: string, value: any) =>
      dispatch(setFilter(filterName, value)),
    linkToCustomerTab: (
      topNavTab: number,
      subNavTab: number,
      filterValue: any
    ) => dispatch(linkToCustomerTab(topNavTab, subNavTab, filterValue))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerTabSalesTemplate);
