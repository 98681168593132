import { connect } from 'react-redux';
import { setSelectedCustomerTitleFilter } from '../actions';

import CustomerTitleFilterTemplate from './CustomerTitleFilterTemplate';

const mapStateToProps = (state: any) => ({
  selectedFilter: state.filters.selectedFilter
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedCustomerTitleFilter: (newFilter: any, actorType: string) =>
    dispatch(setSelectedCustomerTitleFilter(newFilter, actorType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerTitleFilterTemplate);
