import React from 'react';
import './App.css';
import TopLevelHeaderTemplate from './components/TopLevelHeaderTemplate';
// import NavBarContainer from './components/NavBarContainer';
import './App.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import RoutingController from './components/RoutingController';

const App: React.FC = () => {
  const customHistory = createBrowserHistory();
  return (
    <div className="App">
      <div className="mainAppContainer">
        <div className="topLevelHeader">
          <TopLevelHeaderTemplate />
        </div>
        <div>
          <Router history={customHistory}>
            {/* <NavBarContainer /> */}
            <RoutingController />
          </Router>
        </div>
      </div>
    </div>
  );
};

export default App;
