import React from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl
} from '@material-ui/core';

const FilterRadio = (props: any) => {
  const { filter, setFilter, setOptions, makeOptions } = props;

  const changeSelection = (value: any) => {
    setFilter(value);
    setOptions(makeOptions(value));
  };

  return (
    <FormControl>
      <RadioGroup
        value={filter}
        onChange={(ev: any) => changeSelection(ev.target.value)}
        row
      >
        <FormControlLabel
          label="Retailer"
          value="RETAILER"
          control={<Radio />}
        />
        <FormControlLabel label="Grower" value="GROWER" control={<Radio />} />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterRadio;
