import React from 'react';
import PieChartTemplate from './PieChartTemplate';
import { ResponsiveContainer } from 'recharts';
import _ from 'lodash';

const PieTableItemTemplate = (props: any) => {
  const value = props.value;
  const configPieColors = props.configPieColors;
  const configLabelColors = props.configLabelColors;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span
        style={{
          color: configLabelColors,
          margin: 'auto 0'
        }}
      >
        {value === -Infinity ? 'N/A' : value.toFixed(2) + '%'}
      </span>

      <ResponsiveContainer width={50} height={50}>
        <PieChartTemplate
          data={[{ name: '', value: value }, { name: '', value: 100 - value }]}
          pieColor={configPieColors}
          kpiValue={value}
          pieSize={{ innerRadius: 10, outerRadius: 15 }}
          pieContainerSize={{ width: 50, height: 50 }}
          label={false}
        />
      </ResponsiveContainer>
    </div>
  );
};

export default PieTableItemTemplate;
