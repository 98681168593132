import _ from 'lodash';
import salesJSON from './demo/sales.json';

const makeSalesTableData = (dataArray: any, metadata: any) => {
  if (dataArray.length === 0) {
    return {};
  }
  const colTitles = (metadata: any) => {
    return dataArray[0] === undefined
      ? dataArray
      : Object.keys(dataArray[0])
          .map((column: any) => {
            const getrelevantColumnInfo = metadata.columnInfo.find(
              (item: any) => item.field === column
            );
            switch (column) {
              case 'growerId':
              case 'sellerId':
              case 'productId':
                // case 'retailer':
                // do not include this column in the colTitles
                return '';
              // case 'total':
              //   return {
              //     title: 'Total',
              //    cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              case 'currentYearShipped':
                return;
              case 'grower':
              case 'seller':
              case 'retailer':
              case 'productType':
              case 'productCategory':
              case 'productName':
              case 'currentYearOrders':
              case 'currentYearProductValue':
              case 'previousYearProductValue':
              case 'percentChange':
              case 'previousYearUnits':
                return {
                  title: getrelevantColumnInfo.header,
                  cellFormats: getrelevantColumnInfo.renderFormat.type,
                  column: column
                };

              // case 'seller':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'retailer':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'productType':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'productCategory':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'productName':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'currentYearOrders':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //    cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'currentYearShipped':
              //   return;
              // case 'currentYearProductValue':
              //   return {
              //     title: getrelevantColumnInfo.header, //FOR CROPS
              //     // title: 'CY Invoices', //FOR POULTRY
              //    cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'previousYearProductValue':
              //   return {
              //     title: getrelevantColumnInfo.header, //FOR CROPS
              //     // title: 'PY Invoices', //FOR POULTRY
              //    cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'percentChange':
              //   return {
              //     title: getrelevantColumnInfo.header, //FOR CROPS
              //     // title: 'Invoices % Change', FOR POULTRY
              //     cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // case 'previousYearUnits':
              //   return {
              //     title: getrelevantColumnInfo.header,
              //    cellFormats: getrelevantColumnInfo.renderFormat.type,
              //     column: column
              //   };
              // default:
              // if you see an unwanted column you probably want to add it to
              // the first few cases so it doesn't include it
              // return { column: column };
            }
          })
          .filter((colTitle: any) => typeof colTitle === 'object');
  };
  const getSalesRowData = (dataPoint: any, metadata: any) => {
    return colTitles(metadata).map(
      (colTitleObj: any) => dataPoint[colTitleObj.column]
    );
  };
  const getCellFormat = (metadata: any) => {
    return colTitles(metadata).map((cell: any) => cell.cellFormats);
  };
  return {
    colTitles: colTitles(metadata),
    canSortColumns: true,
    rowInfo:
      //  addTotalRow(
      //   [
      //     'previousYearUnits',
      //     'currentYearOrders',
      //     'currentYearShipped',
      //     'currentYearProductValue',
      //     'previousYearProductValue',
      //     'percentChange',
      //     'total'
      //   ],
      //   addTotalColumn(
      //     [
      //       'previousYearUnits',
      //       'currentYearOrders',
      //       'currentYearShipped',
      //       'currentYearProductValue',
      //       'previousYearProductValue',
      //       'percentChange'
      //     ],
      //     dataArray
      //   ),
      //   colTitles
      // ).
      dataArray.map((dataPoint: any) => {
        return {
          dependency: 'independent',
          rowType: 'multiType',
          cellFormats: getCellFormat(metadata),
          rowTitle: null,
          boldLastColumn: true,
          values: getSalesRowData(dataPoint, metadata)
        };
      })
  };
};

const getOptionalFieldsFromGroup = (filterArray: any[], dataRow: any): any => {
  if (filterArray.length > 0) {
    const filterArrayToRemoveFrom = [...filterArray];
    const filter = filterArrayToRemoveFrom.pop();
    const filterValue = dataRow.group.split(':').slice(-1);
    const newGroup = dataRow.group
      .split(':')
      .slice(0, -1)
      .join(':');
    return getOptionalFieldsFromGroup(filterArrayToRemoveFrom, {
      [filter.field]: filterValue[0],
      ...dataRow,
      group: newGroup
    });
  }
  return dataRow;
};

export const makeDynamicSalesData = (filterArray: any[], data: any) => {
  // const filter = filterArray.map((filter: any) => filter.field);

  const newData = _(data)
    .groupBy((row: any) =>
      filterArray.map((filter: any) => row[filter.field]).join(':')
    )
    .map((group: any, name: string) => ({
      group: name,
      previousYearProductValue: _.sumBy(group, (sale: any) => sale.py_dollars),
      previousYearUnits: _.sumBy(group, (sale: any) => sale.py_units),
      currentYearOrders: _.sumBy(group, (sale: any) => sale.cy_orders),
      currentYearShipped: _.sumBy(group, (sale: any) => sale.cy_shipped),
      currentYearProductValue: _.sumBy(group, (sale: any) => sale.cy_dollars)
    }))
    .value();

  const returnData = newData.map((row: any) =>
    getOptionalFieldsFromGroup(filterArray, row)
  );

  return returnData;
};

const getSalesTableData = (metadata: any) => (
  filters: any,
  actorType: string
) => {
  if (actorType === 'RETAILER') {
    const [
      customerFilter,
      productCategory,
      productName,
      grower,
      seller,
      ,
    ] = filters;
    const filterForMap = [{ field: 'productType' }];
    if (productCategory) {
      filterForMap.push({ field: 'productCategory' });
    }
    if (productName) {
      filterForMap.push({ field: 'productName' });
    }
    if (grower) {
      filterForMap.push({ field: 'grower' });
    }
    if (seller) {
      filterForMap.push({ field: 'seller' });
    }

    const filteredData = salesJSON.filter(
      (row: any) => row.retailer === customerFilter
    );
    const dataBeforeColumns = makeDynamicSalesData(filterForMap, filteredData);
    return makeSalesTableData(dataBeforeColumns, metadata);
  } else if (actorType === 'GROWER') {
    const [
      customerFilter,
      productCategory,
      productName,
      ,
      ,
      retailer
    ] = filters;
    const filterForMap = [{ field: 'productType' }];
    if (productCategory) {
      filterForMap.push({ field: 'productCategory' });
    }
    if (productName) {
      filterForMap.push({ field: 'productName' });
    }
    if (retailer) {
      filterForMap.push({ field: 'retailer' });
    }
    const filteredData = salesJSON.filter(
      (row: any) => row.grower === customerFilter
    );
    const dataBeforeColumns = makeDynamicSalesData(filterForMap, filteredData);
    return makeSalesTableData(dataBeforeColumns, metadata);
  }
};

export { getSalesTableData };
