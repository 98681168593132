export const formFieldInfo = [
  {
    inputName: 'Initiative Area',
    isRequired: true,
    inputType: 'singleLine'
  },
  {
    inputName: 'Project Title',
    isRequired: true,
    inputType: 'singleLine'
  },
  {
    inputName: 'Project Description',
    isRequired: true,
    inputType: 'multiLine'
  },
  {
    inputName: 'Value Created',
    isRequired: false,
    inputType: 'currency'
  },
  {
    inputName: 'Cost Savings',
    isRequired: false,
    inputType: 'currency'
  },
  {
    inputName: 'Start Date',
    isRequired: false,
    inputType: 'date'
  },
  {
    inputName: 'End Date',
    isRequired: false,
    inputType: 'date'
  },
  {
    inputName: 'Resources',
    isRequired: false,
    inputType: 'singleLine'
  },
  {
    inputName: 'Status',
    isRequired: false,
    inputType: 'singleLine'
  },
  {
    inputName: 'Notes',
    isRequired: false,
    inputType: 'multiLine'
  }
];

const valueAddedTableData = {
  colTitles: [
    'Initiative Area',
    'Project Title',
    'Project Description',
    'Value Created',
    'Cost Savings',
    'Start Date',
    'End Date',
    'Resourses',
    'Status',
    'Notes',
    ''
  ],
  canSortColumns: false,

  rowInfo: [
    {
      dependency: 'independent',
      rowType: 'multiType',
      isBold: false,
      cellFormats: [
        'string',
        'string',
        'string',
        'string',
        'string',
        'string',
        'string',
        'string',
        'string',
        'string'
      ],
      rowTitle: '',
      values: []
    }
  ]
};

export default valueAddedTableData;
