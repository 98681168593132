import retailerContacts from './demo/retailerContacts.json';
import retailerRecentActivity from './demo/retailerRecentActivity.json';

export const getRetailerContactData = (customerFilter: string) => {
  return retailerContacts.filter(
    (contact: any) => contact.retailer === customerFilter || contact.grower === customerFilter
  );
};
export const getRetailerRecentActivityData = (customerFilter: string) => {
  return retailerRecentActivity.filter(
    (activity: any) => activity.retailer === customerFilter || activity.grower === customerFilter
  );
};

export const getRetailersOwnContactTableData = (metadata: any) => (
  customerFilter: string
) => {
  const relevantContacts: any = getRetailerContactData(customerFilter);
  if (!relevantContacts || relevantContacts.length === 0) {
    return {
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      rowInfo: {},
      canSortColumns: false
    }
  }
  if (metadata && metadata.columnInfo) {
    // skip the column which is the row title
    const rowValues = metadata.columnInfo.map(
      (column: any) => relevantContacts[0][column.field]
    );
    const rowInfo = [
      {
        dependency: 'independent',
        rowType: 'multiType',
        cellFormats: metadata.columnInfo.map(
          (column: any) => column.renderFormat
        ),
        values: rowValues
      }
    ];

    return {
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      rowInfo,
      canSortColumns: metadata.canSortColumns
    };
  }
};

export const getRetailerContactsTableData = (metadata: any) => (
  customerFilter: string
) => {
  const relevantContacts = getRetailerContactData(customerFilter);
  if (!relevantContacts || relevantContacts.length === 0) {
    return {
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      rowInfo: {},
      canSortColumns: false
    }
  }
  if (metadata && metadata.columnInfo) {
    const rowInfo = relevantContacts.map((contact: any, index: number) => {
      // skip the column which is the row title
      const rowValues = metadata.columnInfo
        .filter((column: any) => column.field !== 'name')
        .map((column: any) => contact[column.field]);
      return {
        dependency: 'independent',
        rowType: 'stringInput',
        cellFormats: metadata.columnInfo.map(
          (column: any) => column.renderFormat
        ),
        values: rowValues,
        rowTitle: contact[metadata.columnInfo[0].field]
      };
    });
    return {
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      rowInfo,
      canSortColumns: metadata.canSortColumns
    };
  }
};
export const getRetailerRecentActivityTableData = (metadata: any) => (
  customerFilter: string
) => {
  const relevantActivity = getRetailerRecentActivityData(customerFilter);
  if (!relevantActivity || relevantActivity.length === 0) {
    return {
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      rowInfo: {},
      canSortColumns: false
    }
  }
  if (metadata && metadata.columnInfo) {
    const rowInfo = relevantActivity.map((activity: any, index: number) => {
      // skip the column which is the row title
      const rowValues = metadata.columnInfo.map(
        (column: any) => activity[column.field]
      );
      return {
        dependency: 'independent',
        rowType: 'multiType',
        cellFormats: metadata.columnInfo.map(
          (column: any) => column.renderFormat
        ),
        values: rowValues
      };
    });
    return {
      tableHeader: metadata.title,
      colTitles: [],
      rowBordersOnly: true,
      rowInfo,
      canSortColumns: metadata.canSortColumns
    };
  }
};
