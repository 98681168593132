import { select, put, debounce } from 'redux-saga/effects';
import { setTableInputValuesState } from '../actions';
import { getApplicationTables } from '../selectors';

import _ from 'lodash';

const getColumnTotal = (
  newStateColumnValuesWithoutRowTotals: any,
  columnId: string
) => {
  const columnTotal = _.sum(
    newStateColumnValuesWithoutRowTotals.map((row: any) =>
      _.get(row, `${columnId}`, 0)
    )
  );
  return columnTotal;
  // newState[tableId] the current table
  // object.values(currentTable) each value is an object, this gives us all of them in a list/array
  // map over the list of objects, and check to see if the column exists return col value or 0
  // and that whole thing is wrapped in a sum
};

function* setTableInput({ tableId, rowId, columnId, value }: any) {
  // use the parameters passed to construct a state object which has the new values set where neeessary
  // new state {} = ...oldState, updateMe: newValue
  const applicationTables = yield select(getApplicationTables); // get current/old state.applicationTables
  const table = _.has(applicationTables, `${tableId}`)
    ? applicationTables[tableId]
    : {};
  const row = _.has(applicationTables, `${tableId}.${rowId}`)
    ? applicationTables[tableId][rowId]
    : {};

  const newState = yield Object.assign(
    {},
    {
      ...applicationTables,
      [tableId]: {
        ...table,
        [rowId]: {
          ...row,
          [columnId]: isNaN(value) ? value : Number(value)
        }
      }
    }
  );
  const newStateRow = _.has(newState, `${tableId}.${rowId}`)
    ? newState[tableId][rowId]
    : {};

  const newStateRowValuesWithoutRowTotals = Object.keys(newStateRow)
    .filter((keyName: string) => keyName !== 'rowTotal')
    .map((keyName: string) => newStateRow[keyName]);

  const newStateRowsWithoutColumnTotals = Object.keys(newState[tableId])
    .filter((keyName: string) => keyName !== 'columnTotals')
    .map((keyName: string) => newState[tableId][keyName]);

  const newStateWithUpdatedRowTotals = yield Object.assign(
    {},
    {
      ...newState,
      [tableId]: {
        ...(_.has(newState, `${tableId}`) ? newState[tableId] : {}),
        rowArray: newStateRowValuesWithoutRowTotals,

        [rowId]: {
          ...newStateRow,
          rowTotal: _.sum(newStateRowValuesWithoutRowTotals)
        },
        columnTotals: {
          ...(_.has(newState, `${tableId}.columnTotals`)
            ? newState[tableId].columnTotals
            : {}),
          [columnId]: getColumnTotal(newStateRowsWithoutColumnTotals, columnId)
        }
      }
    }
  );

  const newStateWithRowTotalRow = _.has(
    newStateWithUpdatedRowTotals,
    `${tableId}.${rowId}`
  )
    ? newStateWithUpdatedRowTotals[tableId][rowId]
    : {};
  const newStateRows = Object.keys(newStateWithUpdatedRowTotals[tableId])
    .filter((keyName: string) => keyName !== 'columnTotals')
    .map((keyName: string) => newStateWithUpdatedRowTotals[tableId][keyName]);

  // const newStateColumnTotal = _.has(
  //   newStateWithUpdatedRowTotals,
  //   `${tableId}.${rowId}.columnTotals.${columnId}`
  // )
  //   ? newStateWithUpdatedRowTotals[tableId][rowId].columnTotals[columnId]
  //   : {};

  const newStateWithUpdatedColumnAndRowTotals = yield Object.assign(
    {},
    {
      ...newStateWithUpdatedRowTotals,
      [tableId]: {
        ...(_.has(newStateWithUpdatedRowTotals, `${tableId}`)
          ? newStateWithUpdatedRowTotals[tableId]
          : {}),
        rowArray: newStateRowValuesWithoutRowTotals,
        [rowId]: {
          ...newStateWithRowTotalRow
        },
        columnTotals: {
          ...(_.has(newStateWithUpdatedRowTotals, `${tableId}.columnTotals`)
            ? newStateWithUpdatedRowTotals[tableId].columnTotals
            : {}),
          rowTotal: getColumnTotal(newStateRows, 'rowTotal')
        }
      }
    }
  );
  yield put(setTableInputValuesState(newStateWithUpdatedColumnAndRowTotals)); //this puts the newState object into redux state from this file
  return 5;
}

function* setTableInputSaga() {
  yield debounce(500, 'SET_TABLE_INPUT_VALUES', setTableInput);
  // yield takeEvery('SET_TABLE_INPUT_VALUES', setTableInput);
}

export default setTableInputSaga;
