import { connect } from 'react-redux';
import { setTableInputValues } from '../actions';
import InputTableItemTemplate from './InputTableItemTemplate';
import { getApplicationTables } from '../selectors';
const mapStateToProps = (state: any) => ({
  // estimatedValue: state.estimatedValueInput.estimatedValue,
  // estimatedValueArray: state.estimatedValueInput.estimatedValueArray,
  // plannedValue: state.plannedValueInput.plannedValue,
  // plannedValueArray: state.plannedValueInput.plannedValueArray,
  applicationTables: getApplicationTables(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  // setEstimatedValues: (newEstimatedValue: any, newEstimatedValueArray: any) =>
  //   dispatch(setEstimatedValues(newEstimatedValue, newEstimatedValueArray)),
  // setPlannedValues: (newPlannedValue: any, newPlannedValueArray: any) =>
  //   dispatch(setPlannedValues(newPlannedValue, newPlannedValueArray)),
  setTableInputValues: (
    tableId: string,
    rowId: string,
    columnId: string,
    value: string
  ) => dispatch(setTableInputValues(tableId, rowId, columnId, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputTableItemTemplate);
