import { connect } from 'react-redux';
import { setTableInputValues } from '../actions';
import TableTemplate from './TableTemplate';
import { getApplicationTables } from '../selectors';

const mapStateToProps = (state: any, ownProps: any) => ({
  applicationTables: getApplicationTables(state),
  data: ownProps.getData(
    getApplicationTables(state),
    state.filters.selectedFilter
  )
});

const mapDispatchToProps = (dispatch: any) => ({
  setTableInputValues: (
    tableId: string,
    rowId: string,
    columnId: string,
    value: string
  ) => dispatch(setTableInputValues(tableId, rowId, columnId, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableTemplate);
