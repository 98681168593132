import React from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Legend,
  ResponsiveContainer
} from 'recharts';

const LineChartTemplate = (props: any) => {
  const { data } = props;
  const colors = [
    '#bf911b', // brown mustard
    '#ff6600', // orange
    '#ff80ff', // magenta pink/purple
    '#ff3333', // crimson red
    '#99ffe6', // bright turqoise
    '#664400', // dark brown
    '#4d94ff', // blue cornflower
    '#cccc00', // yellow-green puce ?
    '#00b300', // medium green (leaf green)
    '#006600' // dark green (hunters/forest green)
  ];
  const products: any[] = [];
  Object.keys(data[0]).map((product: any) => {
    if (product !== 'year') {
      products.push(product);
    }
  });
  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <ResponsiveContainer width="99%" height={200}>
        <LineChart height={200} width={700} data={data}>
          <XAxis dataKey="year" />
          <YAxis
            domain={[0, 1]}
            interval="preserveEnd"
            tickFormatter={(val: number) => `${val * 100}%`}
          />
          {products.map((product: any, index: number) => {
            return (
              <Line
                type="linear"
                dataKey={product}
                stroke={colors[index]}
                strokeWidth={3}
              />
            );
          })}
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartTemplate;
