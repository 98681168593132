import React from 'react';
import { List, ListItem } from '@material-ui/core';

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

const AlphabetIndex = (props: any) => {
  return (
    <List style={{ display: 'flex', flexDirection: 'row' }}>
      {alphabet.map((letter: string, index: number) => (
        <ListItem
          key={index}
          button={props.options.includes(letter)}
          disabled={!props.options.includes(letter)}
          onClick={() => props.changeIndex(letter)}
        >
          {letter}
        </ListItem>
      ))}
    </List>
  );
};

export default AlphabetIndex;
