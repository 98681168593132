import { connect } from 'react-redux';
import { linkToCustomerTab } from '../actions';
import TableWrapperFiltersGridMapTemplate from './WrapperFiltersGridMapTemplate';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: any, ownProps: any) => ({
  data: ownProps.getData()
});

const mapDispatchToProps = (dispatch: any) => ({
  linkToCustomerTab: (topNavTab: number, subNavTab: number, filterValue: any) =>
    dispatch(linkToCustomerTab(topNavTab, subNavTab, filterValue))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableWrapperFiltersGridMapTemplate));
