import React from 'react';
import '../index.css';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { pieWidgetInfo } from '../data';

const PieChartTemplate = (props: any) => {
  const data = props.data;
  const kpiValue = props.kpiValue;
  const pieColor = props.pieColor;
  const labelColor = props.labelColor;
  const pieSize = props.pieSize;
  const pieContainerSize = props.pieContainerSize;
  const label = props.label;

  return (
    <ResponsiveContainer>
      <PieChart width={pieContainerSize.width} height={pieContainerSize.height}>
        <Pie
          data={data}
          cx={'50%'}
          cy={'50%'}
          innerRadius={pieSize.innerRadius}
          outerRadius={pieSize.outerRadius}
          fill="#8884d8"
          startAngle={90}
          endAngle={450}
          dataKey="value"
        >
          {' '}
          {label ? (
            <Label
              value={`${kpiValue}%`}
              position="center"
              stroke={labelColor}
            ></Label>
          ) : null}
          {pieWidgetInfo.map((widget: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              // fill={pieColor}
              fill={pieColor[index % pieColor.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartTemplate;
