const renderFormat = (
  value: any,
  option: string | undefined,
  condensed?: boolean | undefined,
  dataMin?: any, // TODO: combine all optional parameters into single options parameter as an object
  dataMax?: any
) => {
  switch (option) {
    case 'int':
      return renderInteger(value);
    case 'decimal':
      return renderNumeric(value, 2, condensed);
    case 'stringInteger':
      return renderIntegerAsString(value, condensed);
    case 'currency':
      return renderCurrency(value, condensed);
    case 'date':
      return renderDate(value);
    case 'percentage':
      return renderPercentage(value, condensed);
    case 'currencyAbbreviated':
      if (
        dataMin &&
        dataMax &&
        (Math.round(dataMin / 1000) * 1000 ===
          Math.round(dataMax / 1000) * 1000 ||
          Math.round(dataMin / 1000000) * 1000000 ===
            Math.round(dataMax / 1000000) * 1000000)
      ) {
        return renderCloseCurrencyAbbreviated(value, condensed);
      } else {
        return renderCurrencyAbbreviated(value, condensed);
      }
    default:
      return value;
  }
};

export const formatAllValuesNumeric = (
  value: any,
  option: string | undefined,
  condensed?: boolean | undefined
) => {
  switch (option) {
    case 'int':
    case 'stringInteger':
      return Number(value).toFixed(0);
    case 'decimal':
      return Number(value).toFixed(2);
    case 'currency':
    case 'currencyAbbreviated':
      return `$${Number(value).toFixed(condensed ? 0 : 2)}`;
    case 'date':
      return renderDate(value);
    case 'percentage':
      return renderPercentage(value, condensed);
    default:
      return value;
  }
};

const renderInteger = (value: any, condensed?: boolean) => {
  if (condensed) {
    if (Number(Math.abs(value) >= 1000000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}M`;
      } else {
        return `${Number(Number(value / 1000000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}M`;
      }
    } else if (Number(Math.abs(value) >= 1000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}K`;
      } else {
        return `${Number(Number(value / 1000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}K`;
      }
    } else {
      return Number(Number(value).toFixed(0));
    }
  }
  return value ? Math.round(Number(value)) : value;
};

const renderIntegerAsString = (value: any, condensed?: boolean) => {
  if (condensed) {
    if (Number(Math.abs(value) >= 1000000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}M`;
      } else {
        return `${Number(Number(value / 1000000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}M`;
      }
    } else if (Number(Math.abs(value) >= 1000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}K`;
      } else {
        return `${Number(Number(value / 1000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}K`;
      }
    } else {
      return Number(Number(value).toFixed(0));
    }
  }
  return value ? Math.round(Number(value)).toLocaleString() : value;
};

const renderNumeric = (
  value: number,
  precision: number,
  condensed?: boolean
) => {
  if (condensed) {
    if (Number(Math.abs(value) >= 1000000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}M`;
      } else {
        return `${Number(Number(value / 1000000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}M`;
      }
    } else if (Number(Math.abs(value) >= 1000)) {
      if (Number(value) < 0) {
        return `-${Number(
          Number(Math.abs(value) / 1000).toFixed(0)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}K`;
      } else {
        return `${Number(Number(value / 1000).toFixed(0)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )}K`;
      }
    } else {
      return Number(Number(value).toFixed(0));
    }
  }
  return Number(Number(value).toFixed(precision));
};

const renderCurrency = (value: any, condensed?: boolean) => {
  if (value) {
    return Number(value >= 0)
      ? `$${Number(Number(value).toFixed(condensed ? 0 : 2)).toLocaleString(
          'en-us',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        )}`
      : `-$${Number(
          Math.abs(Number(value)).toFixed(condensed ? 0 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`;
  } else {
    return value;
  }
};

const renderCurrencyAbbreviated = (value: any, condensed?: boolean) => {
  if (value) {
    if (Number(Math.abs(value) >= 1000000)) {
      if (Number(value) < 0) {
        return `-$${Number(
          Number(Math.abs(value) / 1000000).toFixed(condensed ? 0 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}M`;
      } else {
        return `$${Number(
          Number(value / 1000000).toFixed(condensed ? 0 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}M`;
      }
    } else if (Number(Math.abs(value) >= 1000)) {
      if (Number(value) < 0) {
        return `-$${Number(
          Number(Math.abs(value) / 1000).toFixed(condensed ? 0 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}K`;
      } else {
        return `$${Number(
          Number(value / 1000).toFixed(condensed ? 0 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}K`;
      }
    } else {
      return `$${Number(Number(value).toFixed(condensed ? 0 : 2))}`;
    }
  } else {
    return value;
  }
};

const renderCloseCurrencyAbbreviated = (
  value: any,
  condensed?: boolean | undefined
) => {
  if (value) {
    if (Number(Math.abs(value) >= 1000000)) {
      if (Number(value) < 0) {
        return `-$${Number(
          Number(Math.abs(value) / 1000000).toFixed(condensed ? 1 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}M`;
      } else {
        return `$${Number(
          Number(value / 1000000).toFixed(condensed ? 1 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}M`;
      }
    } else if (Number(Math.abs(value) >= 1000)) {
      if (Number(value) < 0) {
        return `-$${Number(
          Number(Math.abs(value) / 1000).toFixed(condensed ? 1 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}K`;
      } else {
        return `$${Number(
          Number(value / 1000).toFixed(condensed ? 1 : 2)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}K`;
      }
    } else {
      return `$${Number(Number(value).toFixed(condensed ? 0 : 2))}`;
    }
  } else {
    return value;
  }
};

const renderDate = (value: any) => {
  // we should switch this to use moment.. but i didn't want to break anything at end of day
  const timestamp = Date.parse(value);
  const date = new Date(value);

  return value && !isNaN(timestamp)
    ? date.toLocaleDateString('en-us', { timeZone: 'UTC' })
    : value;
};

const renderPercentage = (value: any, condensed?: boolean) => {
  return `${((Number(value) + 0.00001) * 100).toFixed(condensed ? 0 : 2)}%`;
};

export default renderFormat;
