import { connect } from 'react-redux';
import { setStateData } from '../actions';

import LargeWidgetTemplate from './LargeWidgetTemplate';

const mapStateToProps = (state: any) => ({
  stateData: state.stateData,
  selectedFilter: state.filters.selectedFilter
});

const mapDispatchToProps = (dispatch: any) => ({
  setStateData: (widgetId: any, selectedYear: any, selectedMeasure: any) =>
    dispatch(setStateData(widgetId, selectedYear, selectedMeasure))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LargeWidgetTemplate);
