import React from 'react'; // { Fragment }
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import CustomerTabContainer from './CustomerTabContainer';
// import SubscribeToProgramsContainer from './SubscribeToProgramsContainer';
// import SalesPrioritizationRetailersContainer from './SalesPrioritizationRetailersContainer';
// import SalesPrioritizationTabContainer from './SalesPrioritizationTabContainer';
// import {
//   faChartLine,
//   faChartBar,
//   faUserCog,
//   faShoppingBasket,
//   faBars
// } from '@fortawesome/free-solid-svg-icons';
// import CustomerTitleFilterContainer from './CustomerTitleFilterContainer';
// import OverviewSubTabContainer from './OverviewSubTabContainer';
// import TableContainer from './TableContainer';
// import TableWithQuery from './TableWithQuery';
// import {
//   operationsTableData,
//   planTableData,
//   getSalesTableData,
//   actualEarningsTableData,
//   valueAddedTableData,
//   newClaimsData,
//   newCompetitivesData,
//   variousPrograms,
//   salesPrioritizationRetailersTableData,
//   salesPrioritizationGrowersTableData,
//   makeEstimatedProgramEarningsData
// } from '../data';
// import SalesContainer from '../components/CustomerTabSalesContainer';
// import EditableTextBoxTemplate from './EditableTextBoxTemplate';
// import FormContainer from './FormContainer';
// import { formFieldInfo } from '../data/valueAddedData';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// interface Route {
//   tabLabel: string;
//   nestedRoutes: Route[];
//   icon?: any;
//   exact: boolean;
//   path: string;
//   children?: any;
// }

export const TabVerticalPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={index !== value}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ marginTop: '5em', width: '90vw' }}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

export const TabHorizontalPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={index !== value}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ paddingTop: '1em' }}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

// const routes: Route[] = [
//   {
//     tabLabel: '',
//     icon: faBars,
//     exact: true,
//     path: '/',
//     children: '',
//     nestedRoutes: []
//   },
//   {
//     tabLabel: 'Sales Performance',
//     icon: faChartLine,
//     exact: false,
//     path: '/sales-performance',
//     children: 'Sales Performance',
//     nestedRoutes: []
//   },
//   {
//     tabLabel: 'Sales Prioritization',
//     icon: faChartBar,
//     exact: false,
//     path: '/sales-prioritization',
//     children: (
//       <SalesPrioritizationTabContainer
//         path="/sales-prioritization"
//         navId={'topNav'}
//       />
//     ),
//     nestedRoutes: [
//       {
//         tabLabel: 'Retailers',
//         exact: true,
//         path: '',
//         children: (
//           <SalesPrioritizationRetailersContainer
//             getData={salesPrioritizationRetailersTableData}
//             subtitles={false}
//             tableId={'salesPrioritizationRetailers'}
//           />
//         ),
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Growers',
//         exact: true,
//         path: '/growers',
//         children: (
//           <SalesPrioritizationRetailersContainer
//             getData={salesPrioritizationGrowersTableData}
//             subtitles={false}
//             tableId={'salesPrioritizationGrowers'}
//           />
//         ),
//         nestedRoutes: []
//       }
//     ]
//   },
//   {
//     tabLabel: 'Customer Enablement',
//     icon: faUserCog,
//     exact: false,
//     path: '/customer-enablement',
//     children: (
//       <Fragment>
//         <CustomerTitleFilterContainer />
//         <CustomerTabContainer path="/customer-enablement" navId={'topNav'} />
//       </Fragment>
//     ),
//     nestedRoutes: [
//       {
//         tabLabel: 'Overview',
//         exact: true,
//         path: '',
//         children: <OverviewSubTabContainer />,
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Account Info',
//         exact: false,
//         path: '/account-info',
//         children: <span>info</span>,
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Account Planning',
//         exact: false,
//         path: '/account-planning',
//         children: (
//           <CustomerTabContainer
//             path="/customer-enablement/account-planning"
//             navId={'accountSubNav'}
//           />
//         ),
//         nestedRoutes: [
//           {
//             tabLabel: 'Operations Data',
//             exact: true,
//             path: '',
//             children: (
//               <TableContainer
//                 data={operationsTableData}
//                 subtitles={false}
//                 tableId={'operations'}
//               />
//             ),
//             nestedRoutes: []
//           },
//           {
//             tabLabel: 'Plan & Tactics',
//             exact: true,
//             path: '/plan',
//             children: (
//               <React.Fragment>
//                 <TableContainer
//                   data={planTableData}
//                   getData={planTableData}
//                   subtitles={true}
//                   tableId={'plan'}
//                 />
//                 <EditableTextBoxTemplate />
//               </React.Fragment>
//             ),
//             nestedRoutes: []
//           },
//           {
//             tabLabel: 'History',
//             exact: true,
//             path: '/history',
//             children: 'History',
//             nestedRoutes: []
//           }
//         ]
//       },
//       {
//         tabLabel: 'Sales',
//         exact: false,
//         path: '/sales',
//         children: (
//           <SalesContainer
//             getData={getSalesTableData}
//             subtitles={false}
//             tableId={'sales'}
//           />
//         ),
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Claims',
//         exact: false,
//         path: '/claims',
//         children: (
//           <TableContainer
//             getData={newClaimsData}
//             subtitles={false}
//             tableId={'claims'}
//           />
//         ),
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Competitives',
//         exact: false,
//         path: '/competitives',
//         children: (
//           <TableContainer
//             getData={newCompetitivesData}
//             subtitles={false}
//             tableId={'competitives'}
//           />
//         ),
//         nestedRoutes: []
//       },
//       {
//         tabLabel: 'Program Payments',
//         exact: false,
//         path: '/program-payments',
//         children: (
//           <CustomerTabContainer
//             path="/customer-enablement/program-payments"
//             navId={'paymentSubNav'}
//           />
//         ),
//         nestedRoutes: [
//           {
//             tabLabel: 'Programs',
//             exact: true,
//             path: '',
//             children: (
//               <SubscribeToProgramsContainer programs={variousPrograms} />
//             ),
//             nestedRoutes: []
//           },
//           {
//             tabLabel: 'Actual Earnings',
//             exact: true,
//             path: '/actual-earnings',
//             children: (
//               <TableContainer
//                 getData={actualEarningsTableData}
//                 subtitles={false}
//                 tableId={'earnings'}
//               />
//             ),
//             nestedRoutes: []
//           },
//           {
//             tabLabel: 'Estimated Earnings',
//             exact: true,
//             path: '/estimated-earnings',
//             children: (
//               <TableWithQuery
//                 getData={makeEstimatedProgramEarningsData}
//                 subtitles={false}
//                 tableId={'estimatedearnings'}
//               />
//             ),
//             nestedRoutes: []
//           }
//         ]
//       },
//       {
//         tabLabel: 'Value Added',
//         exact: false,
//         path: '/value-added',
//         children: (
//           <FormContainer
//             form={formFieldInfo}
//             resetValue={''}
//             data={valueAddedTableData}
//             subtitles={false}
//             tableId={'valueAdded'}
//           ></FormContainer>
//         ),
//         nestedRoutes: []
//       }
//     ]
//   },
//   {
//     tabLabel: 'Sales Enablement',
//     icon: faShoppingBasket,
//     exact: false,
//     path: '/sales-enablement',
//     children: 'Sales Enablement',
//     nestedRoutes: []
//   }
// ];

export default TabVerticalPanel;
