import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import InputTableItemContainer from '../components/InputTableItemContainer';
export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  footerToolbar: {
    margin: '10px',
    justifyContent: 'center'
  },
  chevronEnabled: {
    border: '1px solid rgba(35, 43, 43, 0.4)',
    backgroundColor: 'white !important',
    color: '#888 !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 20px'
  },
  chevronDisabled: {
    border: '1px solid rgb(182, 182, 182) !important',
    color: 'rgb(182, 182, 182) !important',
    backgroundColor: 'unset !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 20px'
  },
  enabled: {
    border: '1px solid rgba(35, 43, 43, 0.4)',
    backgroundColor: 'white !important',
    color: '#888 !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 5px'
  },
  current: {
    border: '1.5px solid white',
    backgroundColor: 'rgb(4, 106, 56) !important',
    color: 'white !important',
    boxShadow: '0px 0px 2px rgba(0,0,0,1) !important',
    marginTop: '-24px',
    margin: '0px 5px',
    fontWeight: 'bold'
  },
  float: {
    marginTop: '24px',
    padding: '0px 5px',
    color: '#888'
  }
}));

const ContactLineTemplate = (props: any) => {
  const tableId = props.tableId;
  const data = props.data;

  if (_.isNil(data) || _.isNil(data.rowInfo) || _.isNil(data.rowInfo[0])) {
    return <div>No data was found.</div>;
  }
  const row = data.rowInfo[0].values;

  return (
    <div style={{ padding: '20px' }}>
      <Paper style={{ overflowX: 'auto', boxShadow: 'none' }}>
        <AppBar
          style={{ backgroundColor: 'rgb(245, 245, 245)', boxShadow: 'none' }}
          position="static"
        >
          <Toolbar>
            {row.map((value: any, cellIndex: number) => {
              const flexGrow =
                cellIndex === row.length - 1 ? {} : { flexGrow: 1 };
              const width =
                cellIndex === 0 || cellIndex === row.length - 1
                  ? { width: '20%' }
                  : { width: '60%' };
              const padding =
                cellIndex === 0 || cellIndex === row.length - 1
                  ? { padding: '0px' }
                  : { paddingLeft: '20px', paddingRight: '20px' };
              return (
                <div
                  key={tableId + `${row[0]}` + cellIndex}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    ...flexGrow,
                    alignItems: 'baseline',
                    ...width,
                    ...padding,
                    color: 'rgb(99, 102, 106)'
                  }}
                >
                  <Typography variant="h6">
                    {data.colTitles[cellIndex]}
                  </Typography>
                  <div
                    style={{
                      width: '100%'
                    }}
                  >
                    <InputTableItemContainer
                      style={{ backgroundColor: 'red' }}
                      numericalOnly={false}
                      defaultValue={value}
                      rowId={'singleRow' + row[0]}
                      columnId={data.colTitles[cellIndex]}
                      tableId={tableId + `${row[0]}`}
                    />
                  </div>
                </div>
              );
            })}
          </Toolbar>
        </AppBar>
      </Paper>
    </div>
  );
};

export default ContactLineTemplate;
