import React from 'react';
import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import _ from 'lodash';
// when expanded plus button shoudl switch to minus
// subscribe status with check
// subscribe button
const useStyles = makeStyles({
  icon: {
    color: 'rgba(255, 255, 255, .6)',
    fontSize: '1.8em',
    marginTop: '-4px',
    padding: '0px 7px'
  },
  displayIcon: {
    color: 'rgba(255, 255, 255, .6)',
    fontSize: '1.8em',
    right: '15px',
    position: 'absolute'
  },
  hideIcon: {
    display: 'none'
  },
  typographyButtonWrapper: {
    textAlign: 'right'
  },
  button: {
    '&:hover': {
      backgroundColor: 'lightgray'
    },
    padding: '7px 15px 4px 15px',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(4, 106, 56)',
    fontWeight: 'bold',
    letterSpacing: '.1em'
  },
  description: {
    paddingBottom: '5px'
  }
});

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    marginBottom: '10px'
  },
  expanded: {
    marginBottom: '10px !important'
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(99, 102, 106, .7)',
    minHeight: 56,
    borderRadius: '4px',
    color: 'rgb(255, 255, 255)'
  },
  content: {
    borderRadius: '4px 4px 0px 0px'
  },
  expanded: {
    backgroundColor: 'rgb(99, 102, 106)',
    borderRadius: '4px 4px 0px 0px'
  }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    color: 'rgb(99, 102, 106)',
    fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
    textAlign: 'left',
    paddingBottom: '5px',
    margin: '10px 0',
    display: 'block'
  }
}))(MuiExpansionPanelDetails);

const SubscribeToProgramsTemplate = (props: any) => {
  const classes = useStyles();
  const programs = props.data;
  const subscriptions = props.subscriptions;
  const setSubscription = props.setSubscription;
  const [expanded, setExpanded] = React.useState(-1);
  const handleChange = (panel: number) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : -1);
  };

  return (
    <div>
      {programs.map((program: any, index: number) => {
        return (
          <div>
            <ExpansionPanel
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <ExpansionPanelSummary>
                <Typography>{program.title}</Typography>
                <div>
                  {subscriptions[program.id] ? (
                    <div style={{ display: 'flex' }}>
                      <RemoveIcon className={classes.icon} />
                      <Typography>Subscribed</Typography>
                      <CheckIcon className={classes.icon} />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <RemoveIcon className={classes.icon} />
                      <Typography>Subscribe Now</Typography>
                    </div>
                  )}
                </div>
                <ControlPointIcon
                  className={
                    expanded !== index ? classes.displayIcon : classes.hideIcon
                  }
                />
                <RemoveCircleOutlineIcon
                  className={
                    expanded === index ? classes.displayIcon : classes.hideIcon
                  }
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className={classes.description}>
                  {program.description}
                </Typography>
                <Typography className={classes.typographyButtonWrapper}>
                  <Button
                    color="primary"
                    onClick={() =>
                      setSubscription(program.id, !subscriptions[program.id])
                    }
                    className={classes.button}
                  >
                    {subscriptions[program.id]
                      ? 'Unsubscribe'
                      : 'Subscribe Now'}
                  </Button>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </div>
  );
};

export default SubscribeToProgramsTemplate;
