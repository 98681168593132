import { connect } from 'react-redux';
import SliderTemplate from './SliderTemplate';
import { setTableInputValues } from '../actions';

const mapDispatchToProps = (dispatch: any) => ({
  setTableInputValues: (
    tableId: string,
    rowId: string,
    columnId: string,
    value: string
  ) => dispatch(setTableInputValues(tableId, rowId, columnId, value))
});

export default connect(
  null,
  mapDispatchToProps
)(SliderTemplate);
