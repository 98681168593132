import React from 'react'; // { useEffect }
import TableTemplate from './TableTemplate';
import FilterToggleContainer from './filters/FilterToggleContainer';
// import _ from 'lodash';
import FormGroup from '@material-ui/core/FormGroup';

const CustomerTabSalesTemplate = (props: any) => {
  // const getData = props.getData;
  const subtitles = props.subtitles;
  const tableId = props.tableId;
  const data = props.data;
  // const growerFilter = props.filters.retailerSalesGrowerToggle.selected;
  // const sellerFilter = props.filters.retailerSalesSellerToggle.selected;
  // const productCategoryFilter =
  // props.filters.retailerSalesProductCategoryToggle.selected;
  // const productNameFilter =
  // props.filters.retailerSalesProductNameToggle.selected;
  // const retailerFilter = props.filters.growerSalesRetailerToggle.selected;
  // const dollarsFilter = props.filters.salesPerformanceDollarsToggle.selected;
  // const unitsFilter = props.filters.salesPerformanceUnitsToggle.selected;
  // const productFilter = props.filters.salesPerformanceProductToggle.selected;
  // const customerFilter = props.customerFilter;
  const toggleFilters =
    props.tableConfig && props.tableConfig.filters
      ? props.tableConfig.filters
      : [];

  const linkToCustomerTab = props.linkToCustomerTab;
  // const getFilterName = () => {
  //   if (
  //     JSON.stringify([dollarsFilter, unitsFilter, productFilter]) ===
  //     JSON.stringify([true, false, false])
  //   ) {
  //     return 'salesPerformanceDollarsToggle';
  //   } else if (
  //     JSON.stringify([dollarsFilter, unitsFilter, productFilter]) ===
  //     JSON.stringify([false, true, false])
  //   ) {
  //     return 'salesPerformanceUnitsToggle';
  //   } else if (
  //     JSON.stringify([dollarsFilter, unitsFilter, productFilter]) ===
  //     JSON.stringify([true, false, true])
  //   ) {
  //     return 'salesPerformanceProductDollarsToggle';
  //   } else if (
  //     JSON.stringify([dollarsFilter, unitsFilter, productFilter]) ===
  //     JSON.stringify([false, true, true])
  //   ) {
  //     return 'salesPerformanceProductUnitsToggle';
  //   } else return 'salesPerformanceDollarsToggle';
  // };

  // useEffect(() => {
  //   props.filters.actorType === 'RETAILER'
  //     ? getData(
  //         [
  //           // customerFilter,
  //           // productFilter,
  //           // unitsFilter,
  //           // dollarsFilter,
  //           // productCategoryFilter,
  //           // productNameFilter,
  //           // growerFilter,
  //           // sellerFilter
  //         ],
  //         props.filters.actorType,
  //         getFilterName()
  //       )
  //     : getData(
  //         [
  //           // customerFilter,
  //           // productFilter,
  //           // unitsFilter,
  //           // dollarsFilter,
  //           // productCategoryFilter,
  //           // productNameFilter,
  //           // retailerFilter
  //         ],
  //         props.filters.actorType,
  //         getFilterName()
  //       );
  // }, [
  //   customerFilter,
  //   props.filters.actorType,
  //   productFilter,
  //   unitsFilter,
  //   dollarsFilter,
  //   productCategoryFilter,
  //   productNameFilter,
  //   growerFilter,
  //   sellerFilter,
  //   retailerFilter
  // ]);
  return (
    <div>
      <FormGroup row>
        {toggleFilters &&
          toggleFilters.map((filterName: string) => {
            return <FilterToggleContainer filterName={filterName} />;
          })}
      </FormGroup>
      <TableTemplate
        linkToCustomerTab={linkToCustomerTab}
        data={data}
        subtitles={subtitles}
        tableId={tableId}
      />
    </div>
  );
};

export default CustomerTabSalesTemplate;
