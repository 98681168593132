import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import FormInputContainer from './FormInputContainer';
import TableTemplate from './TableTemplate';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      width: '65%',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      scrollBehavior: 'smooth'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    dense: {
      marginTop: theme.spacing(2)
    },
    margin: {
      margin: theme.spacing(2)
    },
    formButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    save: {
      marginRight: '10px'
    },
    cancel: {}
  })
);

const FormTemplate = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState({
    isOpen: false,
    formId: 0
  });
  const [cancel, setCancel] = React.useState(false);
  const [errorMessage, getErrorMessage] = React.useState(false);
  const [newFormId, setNewFormId] = React.useState(0);
  const form = props.form;
  const subtitles = props.subtitles;
  const tableId = props.tableId;
  const data = props.data;
  const applicationForms = props.applicationForms;
  const setFormInputValuesState = props.setFormInputValuesState;
  const cancelFormValues = props.cancelFormValues;
  // const setFormInputValues = props.setFormInputValues;
  const getCommittedApplicationForms = _.has(
    applicationForms,
    `applicationForms`
  )
    ? applicationForms.applicationForms
    : {};

  const handleEdit = (event: any) => {
    const result = getCommittedApplicationForms[event.currentTarget.id];
    setOpen({
      isOpen: true,
      formId: result ? event.currentTarget.id : newFormId
    });
  };

  const handleDelete = (event: any) => {
    const result = getCommittedApplicationForms[event.currentTarget.id];

    const deleteRow = (formId: any) => {
      if (formId === event.currentTarget.id) {
        delete applicationForms.applicationForms[formId];
      }
    };

    if (result) {
      deleteRow(event.currentTarget.id);
    }

    return setNewFormId(newFormId + 1);
  };

  const tableDataFromInputValues = Object.assign(
    {},
    {
      ...data,
      rowInfo: Object.keys(getCommittedApplicationForms).map((formId: any) => ({
        dependency: 'independent',
        rowType: 'multiType',
        isBold: false,
        cellFormats: [
          'string',
          'string',
          'string',
          'dollar',
          'dollar',
          'string',
          'string',
          'string',
          'string',
          'string'
        ],
        rowTitle: '',
        values: [
          applicationForms.applicationForms[formId].rowArray,
          [
            <React.Fragment>
              <IconButton id={formId} onClick={handleEdit}>
                <EditIcon id={formId} style={{ paddingRight: '5px' }} />
              </IconButton>
              <IconButton id={formId} onClick={handleDelete}>
                <DeleteIcon id={formId} />
              </IconButton>
            </React.Fragment>
          ]
        ].flat()
      }))
    }
  );

  const handleNewRowButtonClick = () => {
    setOpen({ isOpen: true, formId: newFormId });
    setNewFormId(newFormId + 1);
  };

  const handleSave = () => {
    const getisRequiredValueArray = _.has(
      applicationForms.stagedApplicationForms,
      `${open.formId}.formFields`
    )
      ? applicationForms.stagedApplicationForms[open.formId].formFields
      : [];

    const isRequiredValueArray = Object.values(getisRequiredValueArray);
    const getValidation = isRequiredValueArray.map((fieldValidation: any) => {
      return fieldValidation.value === '' && fieldValidation.required === true
        ? 'error'
        : 'no error';
    });
    const getError = (error: any) => error === 'error';
    const result = getValidation.filter(getError);
    if (result.length > 0) {
      setOpen({ isOpen: true, formId: open.formId });
      getErrorMessage(true);
    } else if (result.length <= 0) {
      setFormInputValuesState();
      setOpen({ isOpen: false, formId: open.formId });
    }
  };

  const handleCancel = () => {
    cancelFormValues();
    setCancel(true);
    setOpen({ isOpen: false, formId: open.formId });
  };

  return (
    <div>
      <TableTemplate
        data={tableDataFromInputValues ? tableDataFromInputValues : data}
        subtitles={subtitles}
        tableId={tableId}
      />
      <div style={{ paddingTop: '20px' }}>
        <Button
          onClick={handleNewRowButtonClick}
          variant="contained"
          color="primary"
        >
          Add <AddIcon style={{ paddingLeft: '10px' }} />
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.isOpen}
        onClose={handleCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open.isOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Initiatives</h2>
            {errorMessage ? (
              <span style={{ color: 'red' }}>
                Please fill out required fields*
              </span>
            ) : null}
            <form className={classes.container} noValidate autoComplete="off">
              {form.map((info: any, index: number) => {
                return (
                  <FormInputContainer
                    form={info}
                    rowId={index}
                    formId={open.formId}
                    formField={info.inputName}
                    cancel={cancel}
                    isRequired={info.isRequired}
                  ></FormInputContainer>
                );
              })}
            </form>
            <div className={classes.formButtonContainer}>
              <Button
                className={classes.save}
                onClick={() => handleSave()}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                className={classes.cancel}
                onClick={() => handleCancel()}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FormTemplate;
