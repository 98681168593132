import {
  productInfo
  //  oldGrowerInfo
} from './baseDataArrays';
import {
  getAllGrowerInfo,
  getProductInfo,
  getProgramData
} from './generatedBaseData';
import {
  getRetailerContactsTableData,
  getRetailerRecentActivityTableData,
  getRetailersOwnContactTableData
} from './accountInfoData';
import { getOperationsTableData } from './operationsTableData';
import { getSalesTableData } from './salesData';
import { getPlanTableData } from './planTableData';
import valueAddedTableData from './valueAddedData';
import {
  salesPrioritizationGrowersTableData,
  salesPrioritizationRetailersTableData
} from './salesPrioritizationData';
import { makeEstimatedProgramEarningsData } from './programEarnings';
import {
  actualEarningsTableData,
  variousProgramsData
} from './programDetailAndActualEarningsData';
import { getTrendData } from './performanceOverview';
import { getWidgetInfoVolume } from './volume';

import _ from 'lodash';
import { reduceToSum } from '../utils';
import salesJson from './demo/sales.json';
import touchpointsJson from './demo/touchpoints.json';
import claimsJson from './demo/claims.json';
import competitivesJson from './demo/competitives.json';
import paymentsJson from './demo/payments.json';
import config from '../config/config.json';

import aggioLogo from '../assets/aggioLogo.png';
import BI from '../assets/BI.png';

import {
  getSalesPerformanceRetailersTableData,
  getSalesPerformanceGrowersTableData,
  getSalesPerformanceProductsTableData
} from './salesPerformanceData';
// const configRoutes = config.routes;

export const logo = config.logo === 'bivi' ? BI : aggioLogo;

const customerEnablementIndex = _.findIndex(
  config.routes,
  (item: any) => item.title === 'Customer Enablement'
);

const accountPlanningIndex = config.routes[
  customerEnablementIndex
].routes.findIndex((item: any) => item.title === 'Account Planning');
const programPaymentsIndex = config.routes[
  customerEnablementIndex
].routes.findIndex((item: any) => item.title === 'Program Payments');
const salesIndex = config.routes[customerEnablementIndex].routes.findIndex(
  (item: any) => item.title === 'Sales'
);

const userInfo = [
  {
    firstName: config.userInfo.firstName,
    lastName: config.userInfo.lastName,
    businessRole: 'Retailer'
  }
];

const baseProgramInfo = paymentsJson.map((programDetail: any) => ({
  programName: programDetail.programName,
  currentYearDollars: programDetail.currentYearDollars,
  previousYearDollars: programDetail.previousYearDollars,
  currentYearPercentChange: (
    ((programDetail.currentYearDollars - programDetail.previousYearDollars) /
      programDetail.previousYearDollars) *
    100
  ).toFixed(2),
  previousYearPercentChange: (
    ((programDetail.previousYearDollars - programDetail.currentYearDollars) /
      programDetail.currentYearDollars) *
    100
  ).toFixed(2)
}));

const programInfo = baseProgramInfo.slice(0, 4);

// const programInfo = [
//   {
//     programName: 'Morristown',
//     currentYearDollars: 13200,
//     previousYearDollars: 5913,
//     currentYearPercentChange: 123,
//     previousYearPercentChange: -55
//   },
//   {
//     programName: 'Chattanooga',
//     currentYearDollars: 14300,
//     previousYearDollars: 6938,
//     currentYearPercentChange: 106,
//     previousYearPercentChange: -49
//   },
//   {
//     programName: 'Dalton',
//     currentYearDollars: 7350,
//     previousYearDollars: 4619,
//     currentYearPercentChange: 59,
//     previousYearPercentChange: -37
//   },
//   {
//     programName: 'Gadsden',
//     currentYearDollars: 4400,
//     previousYearDollars: 848,
//     currentYearPercentChange: 418,
//     previousYearPercentChange: -20
//   }
// ];

// const programInfo = [
//   {
//     programName: 'Retail Advantage Program',
//     currentYearDollars: 24500,
//     previousYearDollars: 13200,
//     currentYearPercentChange: 85,
//     previousYearPercentChange: -46
//   },
//   {
//     programName: 'Preferred Retailer Program',
//     currentYearDollars: 28000,
//     previousYearDollars: 14300,
//     currentYearPercentChange: 95,
//     previousYearPercentChange: -49
//   },
//   {
//     programName: 'Portfolio Stocking Offer',
//     currentYearDollars: 14000,
//     previousYearDollars: 7350,
//     currentYearPercentChange: 90,
//     previousYearPercentChange: -46
//   },
//   {
//     programName: 'Grower Development',
//     currentYearDollars: 8900,
//     previousYearDollars: 4400,
//     currentYearPercentChange: 102,
//     previousYearPercentChange: -51
//   }
// ];

const userDropDownItems = [{ label: 'Settings' }, { label: 'Log Out' }];

const getAccountPlan = () => pieWidgetInfo[0];
const getShareOfWallet = () => pieWidgetInfo[1];

const pieWidgetInfo = [
  {
    id: 'accountPlanning',
    kpiTitle: 'Account Plan',
    widgetData: [
      { name: 'Group A', value: 23 },
      { name: 'Group B', value: 77 }
    ],
    updateLink: {
      pathname: '/customer-enablement/account-planning',
      state: {
        selectedTab: customerEnablementIndex + 1,
        selectedTopNavTab: accountPlanningIndex,
        selectedSubNavTab: 0
      }
    },
    percentChange: null,
    showPercentChange: false
  },
  {
    id: 'wallet',
    kpiTitle: 'Share of Wallet',
    widgetData: [
      { name: 'Group A', value: 15 },
      { name: 'Group B', value: 85 }
    ],
    updateLink: {
      pathname: '/customer-enablement/account-planning',
      state: {
        selectedTab: customerEnablementIndex + 1,
        selectedTopNavTab: accountPlanningIndex,
        selectedSubNavTab: 0
      }
    },
    percentChange: 10,
    showPercentChange: true
  }
];

const getTouchPoints = _.groupBy(touchpointsJson, (item: any) => item.retailer);

const handleNullTouchPointRetailer = (retailer: any) =>
  getTouchPoints[retailer]
    ? getTouchPoints[retailer]
    : getTouchPoints['NATURCHEM INC (LAKE CITY, KY)'];

const getTouchPointsByRetailer = (retailer: any) =>
  handleNullTouchPointRetailer(retailer).map((item: any) => ({
    month: item.month,
    touchpoint: item.pointsOfContact
  }));

const touchPointWidgetData = (retailer: any) =>
  Object.assign(
    {},
    {
      data: getTouchPointsByRetailer(retailer),
      id: 'touchpoints',
      kpiTitle: 'Touchpoints'
    }
  );

// const touchPointWidget = {
//   id: 'touchpoints',
//   kpiTitle: 'Touchpoints',
//   data: [
//     { month: 'Jan', touchpoint: 0 },
//     { month: 'Feb', touchpoint: 2 },
//     { month: 'Mar', touchpoint: 0 },
//     { month: 'Apr', touchpoint: 0 },
//     { month: 'May', touchpoint: 5 },
//     { month: 'Jun', touchpoint: 0 },
//     { month: 'Jul', touchpoint: 4 },
//     { month: 'Aug', touchpoint: 0 },
//     { month: 'Sep', touchpoint: 6 },
//     { month: 'Oct', touchpoint: 3 },
//     { month: 'Nov', touchpoint: 0 },
//     { month: 'Dec', touchpoint: 0 }
//   ]
// };

// const getGrowerInputIds = oldGrowerInfo.map((grower: any) => {
//   return grower.product.map((product: any) => ({
//     id: grower.id + `${product.id}`
//   }));
// });

// export const growerInputIds = () => {
//   let row1 = getGrowerInputIds[0];
//   let row2 = getGrowerInputIds[1];
//   let row3 = getGrowerInputIds[2];
//   let row4 = getGrowerInputIds[3];
//   return row1.concat(row2, row3, row4);
// };

// const titles = productInfo.map(name => name.productName);
// titles.unshift('');

// console.log("testing",(getOperationsTableData("KOCH FOODS").rowInfo.filter((item:any)=> item.rowTitle === 'Prev Year Sales')))

// export const getSOWValues = (estimatedPurchases: any) => {
//   const computedData = estimatedPurchases.map((value: number) => ({
//     estimatedPurchases: value
//   }));

//   productInfo.map((product: any, i: number) =>
//     Object.assign(computedData[i], { sales: product.previousYearUnits })
//   );

//   const totalSales = productInfo
//     .map((product: any, i: number) => product.previousYearUnits)
//     .reduce(reduceToSum);
//   const totalPurchases = estimatedPurchases.reduce(reduceToSum);

//   computedData.push({ sales: totalSales, estimatedPurchases: totalPurchases });
//   computedData.forEach((dataPoint: any, i: number) =>
//     Object.assign(computedData[i], {
//       shareOfWallet:
//         100 - (dataPoint.sales / dataPoint.estimatedPurchases) * 100
//     })
//   );

//   return computedData.map((dataPoint: any) => dataPoint.shareOfWallet);
// };

export const getSOWValue = (
  columnId: any,
  estimatedPurchases: any,
  customerFilter: any
) => {
  const productInformation = getProductInfo(customerFilter);
  if (estimatedPurchases === 0) return 0;
  if (columnId === 'Total') {
    const totalPreviousYearUnitsAllProducts = _.sum(
      productInformation.map((product: any) => product.previousYearUnits)
    );
    return (totalPreviousYearUnitsAllProducts / estimatedPurchases) * 100;
  }
  const product = productInformation.find(
    (product: any) => product.productType === columnId
  );
  
  const previousYearUnits = product ? product.previousYearUnits : 0;
  return (previousYearUnits / estimatedPurchases) * 100;
};

export const getInputValues = (input: any) => {
  return input + 'modified';
};

// const formattedGrowerPrevSales = oldGrowerInfo.map(grower => {
//   return grower.product.map(sales =>
//     Intl.NumberFormat('en').format(sales.previousYearSales)
//   );
// });

// const growerPrevSales = oldGrowerInfo.map(grower => {
//   return grower.product
//     .map(sales => sales.previousYearSales)
//     .reduce(reduceToSum);
// });

// const getGrowerTotals = (i: number) => {
//   return getRowData(
//     formattedGrowerPrevSales[i],
//     Intl.NumberFormat('en').format(growerPrevSales[i])
//   );
// };

// const getProductTotals = (i: any) => {
//   return oldGrowerInfo.map(
//     (grower: any) => grower.product[i].previousYearSales
//   );
// };

// const getGrandTotals = [
//   getProductTotals(0).reduce(reduceToSum),
//   getProductTotals(1).reduce(reduceToSum),
//   getProductTotals(2).reduce(reduceToSum),
//   getProductTotals(3).reduce(reduceToSum)
// ].reduce(reduceToSum);

// const planTableData = {
//   colTitles: titles,
//   canSortColumns: false,
//   rowInfo: [
//     {
//       dependency: 'independent',
//       rowType: 'numberAndInput',
//       isBold: false,
//       boldLastColumn: true,
//       cellType: 'numberAndInput',
//       rowTitle: 'Bradley Davidson',
//       values: getGrowerTotals(0)
//     },
//     {
//       dependency: 'independent',
//       rowType: 'numberAndInput',
//       isBold: false,
//       boldLastColumn: true,
//       rowTitle: 'Leon Chambers',
//       values: getGrowerTotals(1)
//     },
//     {
//       dependency: 'independent',
//       rowType: 'numberAndInput',
//       isBold: false,
//       boldLastColumn: true,
//       rowTitle: 'Sebastian Howard',
//       values: getGrowerTotals(2)
//     },
//     {
//       dependency: 'independent',
//       rowType: 'numberAndInput',
//       isBold: false,
//       boldLastColumn: true,
//       rowTitle: 'Arthur Booth',
//       values: getGrowerTotals(3)
//     },
//     {
//       dependency: 'dependent',
//       rowType: 'columnTotals',
//       isBold: true,
//       boldLastColumn: true,
//       rowTitle: 'Total',
//       values: [
//         getProductTotals(0).reduce(reduceToSum),
//         getProductTotals(1).reduce(reduceToSum),
//         getProductTotals(2).reduce(reduceToSum),
//         getProductTotals(3).reduce(reduceToSum),
//         getGrandTotals
//       ]
//     }
//   ]
// };

// const configClaimsFetchData: any =
//   configRoutes[2].routes[2].components[0].metadata.fetchData;
// const configClaimsColTitles = configClaimsFetchData.colTitles;
// const getClaimsColTitles = configClaimsColTitles.map(
//   (colTitle: any) => colTitle.header
// );

const isRetailer = (filterSelected: any) =>
  _.includes(
    Object.keys(_.groupBy(claimsJson, (item: any) => item.retailer)),
    filterSelected
  )
    ? true
    : false;

const newClaimsData = (metadata: any) => (filterSelected: any) => {
  const getClaims = isRetailer(filterSelected)
    ? _.groupBy(claimsJson, (item: any) => item.retailer)
    : _.groupBy(claimsJson, (item: any) => item.claimant);
  const customerNames = Object.keys(getClaims);
  const totalArr: any[] = [];
  const getFieldByColumn = (column: any) =>
    getClaims[filterSelected].map((item: any) => item[column]);
  customerNames.forEach((item: any) =>
    totalArr.push(
      Object.assign(
        {},
        {
          retailer: item,
          claimant: 'Grand Total',
          originalCrop: '-',
          units: _.sum(getFieldByColumn('units')),
          settlement: _.sum(getFieldByColumn('settlement')),
          status: '-',
          paidDate: '-',
          checkNo: 0,
          claimantCity: '-',
          claimantState: '-'
        }
      )
    )
  );
  const totalsByCustomer = _.groupBy(totalArr, (item: any) => item.retailer);
  const handleNullClaims = getClaims[filterSelected]
    ? getClaims[filterSelected]
    : isRetailer
    ? getClaims['NATURCHEM INC (LAKE CITY, KY)']
    : getClaims['Bradley Davidson'];

  const handleNullClaimsWithTotals = totalsByCustomer[filterSelected]
    ? totalsByCustomer[filterSelected]
    : isRetailer
    ? totalsByCustomer['NATURCHEM INC (LAKE CITY, KY)']
    : totalsByCustomer['Bradley Davidson'];
  const arrWithValuesAndTotals = [
    handleNullClaims,
    handleNullClaimsWithTotals
  ].flat();
  const getClaimsValueArr = (item: any) => {
    const arr = Object.values(item);
    arr.shift();
    arr.shift();
    return arr;
  };

  const getClaimsBy = arrWithValuesAndTotals.map((item: any) => ({
    dependency: 'independent',
    rowType: 'multiType',
    isBold: false,
    cellFormats: [
      'string',
      'number',
      'number',
      'string',
      'string',
      'string',
      'string',
      'string'
    ],
    rowTitle: item.claimant,
    values: getClaimsValueArr(item)
  }));

  return Object.assign(
    {},
    {
      rowInfo: getClaimsBy,
      colTitles: metadata.columnInfo.map((column: any) => column.header),
      canSortColumns: true
    }
  );
};

// const claimsTableData = {
//   colTitles: [
//     'Claimant Name',
//     'Original Crop',
//     'Units',
//     'Replant Plan',
//     'Replant Units',
//     'Settlement Amount',
//     'Status',
//     'Paid Date',
//     'Check Number',
//     'Claimant City',
//     'Claimant State'
//   ],
//   canSortColumns: true,
//   rowInfo: [
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'number',
//         'string',
//         'string',
//         'string',
//         'number',
//         'string',
//         'string',
//       ],
//       rowTitle: 'Briney, Marc',
//       values: [
//         'Grain Corn',
//         25,
//         '-',
//         0,
//         7250.0,
//         'Paid',
//         '7/25/2018',
//         522918,
//         '-',
//         '-'
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: true,
//       cellFormats: [
//         'string',
//         'number',
//         'string',
//         'number',
//         'dollar',
//         'string',
//         'date',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Grand Total',
//       values: ['-', 25, '-', 0, 7250.0, '-', '-', '-', '-', '-']
//     }
//   ]
// };

// const actualEarningsTableData = {
//   colTitles: [
//     'Program Name',
//     'Earning Point Name',
//     'Earning Point ST',
//     'Crop',
//     'Product Value',
//     'Program Earnings',
//     'Check Date',
//     'Check Number',
//     'Check Amount'
//   ],
//   canSortColumns: false,
//   rowInfo: [
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'string',
//         'string',
//         'number',
//         'dollar',
//         'date',
//         'string',
//         'dollar'
//       ],
//       rowTitle: 'Grower Development Funds',
//       values: [
//         'Nutrien AG Solutions Inc (Sidney, IL)',
//         '',
//         'Grain Corn',
//         23528,
//         98466.0,
//         '8/20/2018',
//         'Credit Account 62003',
//         0
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       cellFormats: [
//         'string',
//         'string',
//         'string',
//         'number',
//         'dollar',
//         'date',
//         'string',
//         'dollar'
//       ],
//       rowTitle: 'Grower Development Funds',
//       values: [
//         'Nutrien AG Solutions Inc (Sidney, IL)',
//         '',
//         'Grain Corn',
//         23528,
//         98466.0,
//         '10/15/2018',
//         'Credit Account 62003',
//         0
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'string',
//         'string',
//         'number',
//         'dollar',
//         'date',
//         'string',
//         'dollar'
//       ],
//       rowTitle: 'Grower Development Funds',
//       values: [
//         'WU-Brandt Consolidated (Cropsey, IL)',
//         '',
//         'Grain Corn',
//         5913,
//         64013.0,
//         '8/20/2018',
//         'Credit Account 62003',
//         0
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'string',
//         'string',
//         'number',
//         'dollar',
//         'date',
//         'string',
//         'dollar'
//       ],
//       rowTitle: 'Grower Development Funds',
//       values: [
//         'WU-Brandt Consolidated (Cropsey, IL)',
//         '',
//         'Grain Corn',
//         1971,
//         59130.0,
//         '9/20/2018',
//         'Credit Account 62003',
//         70622.0
//       ]
//     }
//   ]
// };

const getCompetitives = _.groupBy(
  competitivesJson,
  (item: any) => item.retailer
);

const handleNullCompetitivesRetailer = (retailer: any) =>
  getCompetitives[retailer]
    ? getCompetitives[retailer]
    : getCompetitives['AGRI-CHEM LLC (HOPKINSVILLE, KY)'];

// const getCompetitivesColTitles = (retailer: any) => {
//   const getArr = handleNullCompetitivesRetailer(retailer)[0];
//   const arr = Object.keys(getArr);
//   arr.shift();
//   return arr;
// };

// const getCompetitivesValues = (item: any) => {
//   // growerName: item.name,

//   const arr = Object.values(item);
//   arr.shift();
//   arr.shift();
//   return arr;
// };

const getCompetitivesByRetailer = (retailer: any) =>
  handleNullCompetitivesRetailer(retailer).map((item: any) => ({
    dependency: 'independent',
    rowType: 'multiType',
    isBold: false,
    cellFormats: [
      'string',
      'string',
      'dollar',
      'dollar',
      'dollar',
      'string',
      'string',
      'string',
      'dollar',
      'string',
      'string',
      'string',
      'string'
    ],
    // rowTitle: item.GROWER,
    values: [
      item.name,
      item.product,
      6000,
      item.dollarPerUnit,
      5000,
      item.status,
      item.checkDate,
      item.checkNumber,
      item.checkAmount,
      item.paymentType,
      item.type,
      item.requestNumber,
      item.competitiveNumber
    ]
  }));

const newCompetitivesData = (retailer: any) =>
  Object.assign(
    {},
    {
      rowInfo: getCompetitivesByRetailer(retailer),
      colTitles: [
        'Grower Name',
        'Product',
        'Current Purchases',
        'Dollars per Unit',
        'Earnings',
        'Status',
        'Check Date',
        'Check Number',
        'Check Amount',
        'Payment Type',
        'Type',
        'Request Number',
        'Competitive Number'
      ],
      // getCompetitivesColTitles(retailer),
      canSortColumns: true
    }
  );

// const competitivesTableData = {
//   colTitles: [
//     'Grower Name',
//     'Product Description L50',
//     'Current Purchases',
//     '$/Unit',
//     'Earnings',
//     'Status',
//     'Check Date',
//     'Check Number',
//     'Check Amount',
//     'Payment Type',
//     'Type',
//     'Request Number',
//     'Competitive Number'
//   ],
//   canSortColumns: true,
//   rowInfo: [
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'string',
//         'dollar',
//         'string',
//         'string',
//         'date',
//         'number',
//         'dollar',
//         'string',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Tim Snider',
//       values: [
//         'Grain Corn',
//         1200,
//         40.07,
//         40084.0,
//         'PAID',
//         '8/22/2018',
//         961116143,
//         82275.0,
//         'Credit on Account',
//         'Competitive Price Response',
//         'U381015-9017335-4',
//         'U381015-9017335-4'
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'number',
//         'dollar',
//         'dollar',
//         'string',
//         'date',
//         'string',
//         'dollar',
//         'string',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Steve Glazik',
//       values: [
//         'Grain Corn',
//         1050,
//         42.76,
//         44898.0,
//         'PAID',
//         '8/22/2018',
//         961116074,
//         44898.0,
//         'Credit on Account',
//         'Competitive Price Response',
//         'U381015-9017328-2',
//         'U381015-9017328-2'
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'number',
//         'dollar',
//         'dollar',
//         'string',
//         'date',
//         'string',
//         'dollar',
//         'string',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Don Hogan',
//       values: [
//         'Grain Corn',
//         832,
//         36.99,
//         39482.0,
//         'PAID',
//         '8/22/2018',
//         961116096,
//         98466.0,
//         'Credit on Account',
//         'Competitive Price Response',
//         'U381015-9017331-2',
//         'U381015-9017331-2'
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'number',
//         'dollar',
//         'dollar',
//         'string',
//         'date',
//         'string',
//         'dollar',
//         'string',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Doug Downs',
//       values: [
//         'Grain Corn',
//         538,
//         6.1,
//         19900.0,
//         'PAID',
//         '8/22/2018',
//         961116096,
//         98466.0,
//         'Credit on Account',
//         'Competitive Price Response',
//         'U381015-9017751-3',
//         'U381015-9017751-3'
//       ]
//     },
//     {
//       dependency: 'independent',
//       rowType: 'multiType',
//       isBold: false,
//       cellFormats: [
//         'string',
//         'number',
//         'dollar',
//         'dollar',
//         'string',
//         'date',
//         'string',
//         'dollar',
//         'string',
//         'string',
//         'string',
//         'string'
//       ],
//       rowTitle: 'Time Snider',
//       values: [
//         'Grain Corn',
//         2995,
//         6.1,
//         18262.0,
//         'PAID',
//         '8/22/2018',
//         961116143,
//         82275.0,
//         'Credit on Account',
//         'Competitive Price Response',
//         'U381015-9017335-4',
//         'U381015-9017335-4'
//       ]
//     }
//   ]
// };

const currentProductDollars = productInfo
  .map(total => total.currentYearDollars)
  .reduce(reduceToSum);
const previousProductDollars = productInfo
  .map(total => total.previousYearDollars)
  .reduce(reduceToSum);
const currentProductUnits = productInfo
  .map(total => total.currentYearUnits)
  .reduce(reduceToSum);
const previousProductUnits = productInfo
  .map(total => total.previousYearUnits)
  .reduce(reduceToSum);
const currentProgramDollars = programInfo
  .map(total => total.currentYearDollars)
  .reduce(reduceToSum);
const previousProgramDollars = programInfo
  .map(total => total.previousYearDollars)
  .reduce(reduceToSum);

const widgetInfo = [
  {
    id: 'sales',
    kpiTitle: { dollars: 'Total Sales', units: 'Total Units' },
    kpiValue: {
      currentUnitNumber: currentProductUnits,
      previousUnitNumber: previousProductUnits,
      currentUnitPercentChange:
        ((currentProductUnits - previousProductUnits) / previousProductUnits) *
        100,
      previousUnitPercentChange:
        ((previousProductUnits - currentProductUnits) / currentProductUnits) *
        100,
      currentDollarNumber: currentProductDollars,
      previousDollarNumber: previousProductDollars,
      currentDollarPercentChange:
        ((currentProductDollars - previousProductDollars) /
          previousProductDollars) *
        100,
      previousDollarPercentChange:
        ((previousProductDollars - currentProductDollars) /
          currentProductDollars) *
        100
    },
    dollarsUnitsToggle: true,
    reportLink: {
      pathname: '/customer-enablement/sales',
      state: {
        selectedTab: customerEnablementIndex + 1,
        selectedTopNavTab: salesIndex,
        selectedSubNavTab: 0
      }
    }
  },
  {
    id: 'payments',
    kpiTitle: { dollars: 'Total Payments', units: null },
    kpiValue: {
      currentDollarNumber: currentProgramDollars,
      previousDollarNumber: previousProgramDollars,
      currentDollarPercentChange:
        ((currentProgramDollars - previousProgramDollars) /
          previousProgramDollars) *
        100,
      previousDollarPercentChange:
        ((previousProgramDollars - currentProgramDollars) /
          currentProgramDollars) *
        100
    },
    dollarsUnitsToggle: false,
    reportLink: {
      pathname: '/customer-enablement/sales',
      state: {
        selectedTab: customerEnablementIndex + 1,
        selectedTopNavTab: programPaymentsIndex,
        selectedSubNavTab: 0
      }
    }
  }
];

const getWidgetInfoSales = () => widgetInfo[0];

const getWidgetInfoPayments = () => widgetInfo[1];

const toggleInfo = ['Current Year', 'Previous Year'];

const basic = _.groupBy(salesJson, (item: any) => item.rollup);

const rollupLevel = Object.keys(basic).map((rollup: any) => ({
  customerName: rollup,
  data: basic[rollup]
}));

const retailers = rollupLevel.map((rollup: any) => ({
  ...rollup,
  locations: Object.keys(_.groupBy(rollup.data, item => item.retailer))
}));

const CustomerFilterData = retailers;

// TODO: this may need to change as we add complexity to programs, but for now it works as a static array here
const tieredProgramData = getProgramData('tiered');

const allProgramData = getProgramData();

export {
  userInfo,
  userDropDownItems,
  CustomerFilterData,
  touchPointWidgetData,
  widgetInfo,
  productInfo,
  toggleInfo,
  programInfo,
  variousProgramsData,
  pieWidgetInfo,
  getOperationsTableData,
  getAccountPlan,
  getShareOfWallet,
  getRetailerContactsTableData,
  getRetailerRecentActivityTableData,
  getRetailersOwnContactTableData,
  getPlanTableData,
  newClaimsData,
  newCompetitivesData,
  actualEarningsTableData,
  valueAddedTableData,
  salesPrioritizationRetailersTableData,
  salesPrioritizationGrowersTableData,
  getSalesTableData,
  tieredProgramData,
  allProgramData,
  makeEstimatedProgramEarningsData,
  getAllGrowerInfo,
  getProductInfo,
  getWidgetInfoSales,
  getWidgetInfoPayments,
  // salesPerformanceProductData,
  // salesPerformanceRetailerData,
  // salesPerformanceGrowerData,
  getSalesPerformanceRetailersTableData,
  getSalesPerformanceGrowersTableData,
  getSalesPerformanceProductsTableData,
  getTrendData,
  getWidgetInfoVolume
};
