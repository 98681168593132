import { combineReducers } from 'redux';
import {
  programInfo,
  allProgramData,
  CustomerFilterData
  //  getRetailersOwnContactTableData
} from '../data';
import { productInfo } from '../data/baseDataArrays';
// import { getSalesTableData } from '../data/salesData';
import { getOperationsTableData } from '../data/operationsTableData';
// import {
//   salesPerformanceRetailerData,
//   salesPerformanceGrowerData
// } from '../data/salesPerformanceProductData';
import _ from 'lodash';
// import config from '../config/config.json';

// state: {
//     navigation: {
//         selectedTab: someTab
//     }
//     filters: {
//       selectedFilter: someFilter
//     }
//   }

const navigation = (state = { selectedTab: 0 }, action: any): any => {
  const newTab = action.newTab;
  switch (action.type) {
    case 'SET_SELECTED_TAB':
      return { ...state, selectedTab: newTab };
    default:
      return { ...state };
  }
};

const customerTab = (state = { selectedCustomerTab: 0 }, action: any): any => {
  const newCustomerTab = action.newCustomerTab;
  switch (action.type) {
    case 'SET_SELECTED_CUSTOMER_TAB':
      return { ...state, selectedCustomerTab: newCustomerTab };
    default:
      return { ...state };
  }
};
const salesPrioritizationTab = (
  state = { selectedTab: 0 },
  action: any
): any => {
  const newSalesTab = action.newTab;
  switch (action.type) {
    case 'SET_SELECTED_SALES_PRIORITIZATION_TAB':
      return { ...state, selectedTab: newSalesTab };
    default:
      return { ...state };
  }
};

const customerTabContent = (state = {}, action: any): any => {
  const newCustomerTabContent = action.newCustomerTab;
  switch (action.type) {
    case 'SET_SELECTED_CUSTOMER_TAB_CONTENT':
      return { ...state, selectedCustomerTabContent: newCustomerTabContent };
    default:
      return { ...state };
  }
};

const yearToggle = (state = { selectedYearToggle: 0 }, action: any): any => {
  const newYearToggle = action.newYearToggle;
  switch (action.type) {
    case 'SET_SELECTED_YEAR_TOGGLE':
      return { ...state, selectedYearToggle: newYearToggle };
    default:
      return { ...state };
  }
};

const dollarsUnitsToggle = (
  state = { selectedDollarsUnitsToggle: { checked: true } },
  action: any
): any => {
  const newDollarsUnitsToggle = action.newDollarsUnitsToggle;
  switch (action.type) {
    case 'SET_SELECTED_UNITS_DOLLARS_TOGGLE':
      return { selectedDollarsUnitsToggle: newDollarsUnitsToggle };
    default:
      return { ...state };
  }
};

const stateData = (
  state = {
    sales: productInfo.map(product => ({
      units: product.currentYearDollars,
      name: product.productName,
      change: product.currentYearDollarPercentChange
    }))
  },
  action: any
): any => {
  const newData = action.newData;
  switch (action.type) {
    case 'SET_STATE_DATA':
      const widgetId = action.widgetId;
      const selectedYear = action.selectedYear === 0 ? 'current' : 'previous';
      const selectedMeasure = action.selectedMeasure.checked
        ? 'dollars'
        : 'units';
      const getData = () => {
        switch (widgetId) {
          case 'sales':
            if (selectedYear === 'current') {
              if (selectedMeasure === 'dollars') {
                // currentYearDollarsData
                return productInfo.map(product => ({
                  units: product.currentYearDollars,
                  name: product.productName,
                  change: product.currentYearDollarPercentChange
                }));
              }
              if (selectedMeasure === 'units') {
                return productInfo.map(product => ({
                  units: product.currentYearUnits,
                  name: product.productName,
                  change: product.currentYearUnitPercentChange
                }));
              }
              return { ...state };
            }
            if (selectedYear === 'previous') {
              if (selectedMeasure === 'dollars') {
                // prevYearDollarsData
                return productInfo.map(product => ({
                  units: product.previousYearDollars,
                  name: product.productName,
                  change: product.previousYearDollarPercentChange
                }));
              }
              if (selectedMeasure === 'units') {
                return productInfo.map(product => ({
                  units: product.previousYearUnits,
                  name: product.productName,
                  change: product.previousYearUnitPercentChange
                }));
              }
              return { ...state };
            }
            return { ...state };
          case 'payments':
            if (selectedYear === 'current') {
              return programInfo.map(program => ({
                units: program.currentYearDollars,
                name: program.programName,
                change: program.currentYearPercentChange
              }));
            }
            if (selectedYear === 'previous') {
              return programInfo.map(program => ({
                units: program.previousYearDollars,
                name: program.programName,
                change: program.previousYearPercentChange
              }));
            }
            break;
          default:
            return { ...state };
        }
      };
      return { ...state, [widgetId]: getData() };
    default:
      return { stateData: newData };
  }
};

// const filters = (state: {}, action: any): any => {
// define your action, parameters are any values needed by the action
// it returns the type of the action, and the parameters
// here you can access the values passed as they are so named in your action
// };
const filters = (
  state = {
    selectedFilter: CustomerFilterData[0].locations[0],
    actorType: 'RETAILER',
    retailerSalesGrowerToggle: {
      selected: false
    },
    retailerSalesSellerToggle: {
      selected: false
    },
    retailerSalesProductCategoryToggle: {
      selected: false
    },
    retailerSalesProductNameToggle: {
      selected: false
    },
    growerSalesRetailerToggle: {
      selected: false
    },
    salesPerformanceDollarsToggle: {
      selected: true
    },
    salesPerformanceUnitsToggle: {
      selected: false
    },
    salesPerformanceProductTypeToggle: {
      selected: false
    },
    salesPerformanceProductCategoryToggle: {
      selected: false
    },
    salesPerformanceProductNameToggle: {
      selected: false
    }
  },
  action: any
): any => {
  const { newFilter, actorType } = action;
  switch (action.type) {
    case 'SET_SELECTED_FILTER':
      return { ...state, selectedFilter: newFilter, actorType };
    case 'SET_FILTER':
      return {
        ...state,
        [action.filterName]: {
          selected: newFilter
        }
      };
    // case 'SET_PERFORMANCE_FILTER':
    //   return {
    //     ...state,
    //     measurementType: action.filterName,
    //     dollarOrUnitsWithProduct:
    //       state.salesPerformanceProductToggle.selected &&
    //       state.salesPerformanceDollarsToggle.selected
    //         ? 'salesPerformanceProductDollarsToggle'
    //         : 'salesPerformanceProductUnitsToggle'
    //   };
    default:
      return { ...state };
  }
};

const makeInitialProgramSubscriptionState = () => {
  const defaultState = { subscriptions: {} };
  allProgramData.forEach((program: any) => {
    Object.assign(defaultState, {
      subscriptions: {
        ...defaultState.subscriptions,
        [program.id]: program.subscribed
      }
    });
  });
  return { ...defaultState };
};

const programs = (
  state = makeInitialProgramSubscriptionState(),
  action: any
): any => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      const newState = Object.assign(
        {},
        {
          ...state,
          subscriptions: {
            ...state.subscriptions,
            [action.subscriptionId]: action.subscribed
          }
        }
      );
      return newState;
    default:
      return { ...state };
  }
};
// const filters = (state: {}, action: any): any => {
//   // define your action, parameters are any values needed by the action
//   // it returns the type of the action, and the parameters
//   // here you can access the values passed as they are so named in your action
// };

const applicationTables = (
  state = {
    // sales: {
    //   data: getOperationsTableData(
    //     'Bradley Davidson'
    //   )
    // }
  },
  action: any
): any => {
  const newState = action.newStateObject;
  // const components = config.routes.map((item: any) =>
  //   _.has(item, 'routes')
  //     ? item.routes.map((route: any) =>
  //         _.has(route, 'components') ? route.components : []
  //       )
  //     : []
  // );

  // const dataByFilter = components[0][2].filter(
  //   (item: any) => item.measurementType === action.filterName
  // );

  switch (action.type) {
    case 'SET_SELECTED_FILTER':
      //    const currentSeller = _.get(state, 'sales.seller', false);
      //    const currentGrower = _.get(state, 'sales.grower', false);
      //    const updatedFilterData = getSalesTableData(
      //      currentGrower,
      //      currentSeller,
      //      action.newFilter
      //    );
      const updatedOperationsTableData = getOperationsTableData(
        action.newFilter
      );
      // const updatedOwnContactTableData = getRetailersOwnContactTableData(
      //   action.newFilter
      // )
      return {
        ...state,
        //  sales: {
        //    ..._.get(state, 'sales', {}),
        //    data: updatedFilterData
        //  },
        operations: {
          ..._.get(state, 'operations', {}),
          data: updatedOperationsTableData
        }
      };
    // case 'SET_PERFORMANCE_FILTER':
    //    const currentSeller = _.get(state, 'sales.seller', false);
    //    const currentGrower = _.get(state, 'sales.grower', false);
    //    const updatedFilterData = getSalesTableData(
    //      currentGrower,
    //      currentSeller,
    //      action.newFilter
    //    );

    // const updatedsalesPerformanceRetailerData = salesPerformanceRetailerData(
    //   components[0][2][0].metadata
    // );
    // const updatedsalesPerformanceGrowerData = salesPerformanceGrowerData(
    //   components[0][2][0].metadata
    // );

    // return {
    //   ...state,
    //   //  sales: {
    //   //    ..._.get(state, 'sales', {}),
    //   //    data: updatedFilterData
    //   //  },
    //   performance: {
    //     ..._.get(state, 'performance', {}),
    //     retailerData: updatedsalesPerformanceRetailerData(action.filterName),
    //     growerData: updatedsalesPerformanceGrowerData(action.filterName)
    //   }
    // };
    case 'SET_TABLE_INPUT_VALUES_STATE':
      return newState;
    // case 'GET_SALES_TABLE_DATA':
    //   const newSalesTableData = getSalesTableData(
    //     action.filters,
    //     action.actorType
    //   );
    //   return {
    //     ...state,
    //     sales: {
    //       ..._.get(state, 'sales', {}),
    //       data: newSalesTableData,
    //       grower: action.grower,
    //       seller: action.seller
    //     }
    //   };
    default:
      return state;
  }
};

const applicationForms = (state = {}, action: any): any => {
  const newState = action.newStateObject;
  switch (action.type) {
    case 'SET_STAGED_FORM_INPUT_VALUES_STATE':
      // { stagedApplicationForms: {}, applicationForms: {}}
      return newState;
    case 'SET_FORM_INPUT_VALUES_STATE':
      const stagedValues = _.get(state, 'stagedApplicationForms', {});
      const committed = Object.assign(
        {},
        {
          ...state,
          applicationForms: stagedValues
        }
      );
      return committed;
    case 'CANCEL_FORM_VALUES':
      const currentlyCommitted = _.get(state, 'applicationForms', {});
      const clearedStaging = Object.assign(
        {},
        {
          ...state,
          stagedApplicationForms: currentlyCommitted
        }
      );
      return clearedStaging;
    default:
      return state;
  }
};

export default combineReducers({
  navigation,
  filters,
  customerTab,
  salesPrioritizationTab,
  yearToggle,
  dollarsUnitsToggle,
  stateData,
  customerTabContent,
  applicationTables,
  applicationForms,
  programs
});
