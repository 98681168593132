import React, { useState } from 'react';
import TablePaginationTemplate from './PaginationActionsTemplate';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableFooter from '@material-ui/core/TableFooter'
import ToolBar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { renderRow } from '../utils';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  footerToolbar: {
    margin: '10px',
    justifyContent: 'center'
  },
  chevronEnabled: {
    border: '1px solid rgba(35, 43, 43, 0.4)',
    backgroundColor: 'white !important',
    color: '#888 !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 20px'
  },
  chevronDisabled: {
    border: '1px solid rgb(182, 182, 182) !important',
    color: 'rgb(182, 182, 182) !important',
    backgroundColor: 'unset !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 20px'
  },
  enabled: {
    border: '1px solid rgba(35, 43, 43, 0.4)',
    backgroundColor: 'white !important',
    color: '#888 !important',
    boxShadow: 'unset',
    marginTop: '-24px',
    margin: '0px 5px'
  },
  current: {
    border: '1.5px solid white',
    backgroundColor: 'rgb(4, 106, 56) !important',
    color: 'white !important',
    boxShadow: '0px 0px 2px rgba(0,0,0,1) !important',
    marginTop: '-24px',
    margin: '0px 5px',
    fontWeight: 'bold'
  },
  float: {
    marginTop: '24px',
    padding: '0px 5px',
    color: '#888'
  }
}));

const TableTemplate = (props: any) => {
  const subtitles = props.subtitles;
  const tableId = props.tableId;
  const applicationTables = props.applicationTables;
  const data = props.data;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const linkToCustomerTab = props.linkToCustomerTab;
  const [activeColumn, setActiveColumn] = useState(
    !_.isNil(data) && !_.isNil(data.colTitles) && data.colTitles.length > 0
      ? data.colTitles.length[1]
      : ''
  );
  const [sortDirection, setSortDirection] = useState('asc');
  const hasPagination = props.hasPagination;
  const selectedFilter = props.selectedFilter;
  const classes = useStyles1();
  if (_.isNil(data) || _.isNil(data.rowInfo)) {
    return <div>No data was found.</div>;
  }
  const handleSort = (title: string) => {
    setActiveColumn(title);
    return sortDirection === 'asc'
      ? setSortDirection('desc')
      : setSortDirection('asc');
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const colTitles = data.colTitles.map((colTitle: any) => {
    if (typeof colTitle === 'object') {
      return colTitle.title;
    }
    return colTitle;
  });

  return (
    <div style={{ padding: '20px' }}>
      {data.tableHeader && (
        <Typography
          style={{
            textAlign: 'left',
            color: '#888',
            fontFamily:
              'Century Gothic, CenturyGothic, AppleGothic, sans-serif ',
            fontSize: '18px',
            padding: '7px 0px'
          }}
        >
          {data.tableHeader}
        </Typography>
      )}
      <Paper style={{ overflowX: 'auto', marginBottom: '30px'  }}>
        <Table id="table">
          <TableHead>
            <TableRow>
              {colTitles.map((title: any, index: number) => {
                return (
                  <TableCell
                    id="tableTitles"
                    key={title}
                    style={{
                      width: tableId === 'plan' ? '10%' : '',
                      fontSize: '11px',
                      border: '1px #F5F5F5 solid',
                      backgroundColor:
                        title !== 'Aggio Rank' ? '#63666a' : 'rgb(132, 189, 0)',
                      color: '#FFF',
                      fontFamily:
                        'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
                    }}
                    align="center"
                    sortDirection={
                      data.canSortColumns
                        ? sortDirection === 'asc'
                          ? 'desc'
                          : 'asc'
                        : false
                    }
                  >
                    {data.canSortColumns ? (
                      <TableSortLabel
                        hideSortIcon={true}
                        active={activeColumn === title}
                        direction={sortDirection === 'desc' ? 'asc' : 'desc'}
                        onClick={() => handleSort(title)}
                        style={{
                          fontSize: '11px',
                          backgroundColor:
                            title !== 'Aggio Rank'
                              ? '#63666a'
                              : 'rgb(132, 189, 0)',
                          color: '#FFF',
                          fontFamily:
                            'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
                        }}
                      >
                        <span>
                          {title}
                          {activeColumn !== title ? (
                            <FontAwesomeIcon
                              icon={faExchangeAlt}
                              style={{
                                paddingLeft: '10px',
                                paddingBottom: '5px',
                                fontSize: '14px',
                                color: '#888',
                                transform: 'rotate(90deg)'
                              }}
                            />
                          ) : null}
                        </span>
                      </TableSortLabel>
                    ) : (
                      <span
                        style={{
                          fontWeight: title === 'Total' ? 'bold' : 'normal'
                        }}
                      >
                        {' '}
                        {title}{' '}
                      </span>
                    )}
                    {subtitles ? (
                      index === 0 ? null : (
                        <React.Fragment>
                          <div style={{ display: 'flex' }}>
                            <TableCell
                              id="tablesubtitles"
                              style={{
                                width: '40%',
                                fontSize: '11px',
                                paddingTop: '10px',
                                border: '1px #F5F5F5 solid',
                                color: '#63666a',
                                backgroundColor: '#FFF',
                                fontFamily:
                                  'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
                              }}
                              align="center"
                            >
                              Prev Year
                            </TableCell>
                            <TableCell
                              id="tablesubtitles"
                              style={{
                                width: '60%',
                                paddingTop: '10px',
                                fontSize: '11px',

                                border: '1px #F5F5F5 solid',
                                color: '#63666a',
                                backgroundColor: '#FFF',
                                fontFamily:
                                  'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
                              }}
                              align="center"
                            >
                              Plan
                            </TableCell>
                          </div>
                        </React.Fragment>
                      )
                    ) : null}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rowInfo
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, rowIndex: number) => {
                return (
                  <TableRow
                    style={{ minWidth: 600, width: '80px' }}
                    key={
                      row.rowTitle
                        ? row.rowTitle
                        : row.values[0] + '-' + rowIndex
                    }
                  >
                    {row.rowTitle && (
                      <TableCell
                        id="rowTitle"
                        style={{
                          fontWeight: row.isBold ? 'bold' : 'normal',
                          border: '1px #F5F5F5 solid',
                          fontFamily:
                            'Century Gothic, CenturyGothic, AppleGothic, sans-serif '
                        }}
                        component="th"
                      >
                        <span
                          style={{
                            fontWeight:
                              row.rowTitle === 'Total' ? 'bold' : 'normal'
                          }}
                        >
                          {row.rowTitle}
                        </span>
                      </TableCell>
                    )}
                    {renderRow(
                      row,
                      rowIndex,
                      data,
                      colTitles,
                      tableId,
                      applicationTables,
                      linkToCustomerTab,
                      selectedFilter
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      {hasPagination && (
        <ToolBar className={classes.footerToolbar}>
          <TablePaginationTemplate
            page={page}
            count={data.rowInfo.length}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
          />
        </ToolBar>
      )}
    </div>
  );
};

export default TableTemplate;
