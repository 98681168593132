import { connect } from 'react-redux';
import { setFormInputValues } from '../actions';
import FormInputTemplate from './FormInputTemplate';
import { getApplicationForms } from '../selectors';

const mapStateToProps = (state: any) => ({
  applicationForms: getApplicationForms(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setFormInputValues: (
    formId: string,
    formField: string,
    value: string,
    isRequired: boolean
  ) => dispatch(setFormInputValues(formId, formField, value, isRequired))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormInputTemplate);
