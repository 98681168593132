import { connect } from 'react-redux';
import { setSelectedCustomerTitleFilter } from '../actions';
import { touchPointWidgetData } from '../data';

import OverviewSubTabTemplate from './OverviewSubTabTemplate';

const mapStateToProps = (state: any) => ({
  selectedFilter: state.filters.selectedFilter,
  data: touchPointWidgetData(state.filters.selectedFilter)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedCustomerTitleFilter: (newFilter: any, actorType: string) =>
    dispatch(setSelectedCustomerTitleFilter(newFilter, actorType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewSubTabTemplate);
