import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toggleInfo } from '../data';
// import { createMuiTheme } from '@material-ui/core/styles';

// import { ThemeProvider } from '@material-ui/styles';

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

// const customTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#00a3e0'
//     },
//     secondary: {
//       main: '#000'
//     }
//   }
// });

const useStyles = makeStyles(customTheme =>
  createStyles({
    root: {
      flexGrow: 1,
      maxHeight: '305x',
      paddingBottom: '5px',
      backgroundColor: '#FFF'
    }
  })
);

const YearToggleTemplate = (props: any) => {
  const selectedYearToggle = props.selectedYearToggle;
  const setSelectedYearToggle = props.setSelectedYearToggle;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedYearToggle(newValue);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '70%', maxHeight: '30px' }}>
        {/* <ThemeProvider theme={customTheme}> */}
        <Tabs
          value={selectedYearToggle}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'black'
            }
          }}
          textColor="secondary"
          aria-label="simple tabs example"
          style={{ maxHeight: '35px', minHeight: '15px' }}
        >
          {toggleInfo.map((tab: any, index: number) => (
            <Tab
              style={{
                display: 'flex',
                padding: '0px',
                flexGrow: 1,
                maxWidth: '50%',
                minWidth: '1em',
                maxHeight: '20px',
                textTransform: 'capitalize'
              }}
              key={tab}
              label={
                <span
                  style={{
                    padding: '0 5px 0 5px',
                    fontSize: '1.60em',
                    fontWeight: 'bold'
                  }}
                >
                  {tab}
                </span>
              }
              {...a11yProps(index)}
            />
          ))}
          n
        </Tabs>
        {/* </ThemeProvider> */}
      </div>
    </div>
  );
};
export default YearToggleTemplate;
