import React, { useState } from 'react';
import TableTemplate from './TableTemplate';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ToolBar from '@material-ui/core/Toolbar';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import { faMapMarkedAlt, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(4, 106, 57)'
    },
    secondary: {
      main: 'rgb(4, 106, 57)'
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: '#F5F5F5'
    },
    toolbarOverride: {
      width: 'inherit',
      paddingRight: '5px',
      paddingLeft: '5px'
    },
    actionBar: {
      flexGrow: 1,
      display: 'inline-flex'
    },
    actionLeft: {
      flexGrow: 1,
      display: 'inline-flex',
      flexDirection: 'row'
    },
    actionRight: {
      display: 'inline-flex',
      paddingLeft: '20px',
      flexDirection: 'row-reverse'
    },
    actions: {
      paddingTop: '7px',
      marginLeft: '5px',
      paddingLeft: '10px',
      color: 'rgb(0, 163, 224)',
      textTransform: 'capitalize'
    },
    actionsSelected: {
      paddingTop: '7px',
      marginLeft: '5px',
      paddingLeft: '10px',
      color: 'rgb(4, 106, 56)',
      textTransform: 'capitalize'
    }
  })
);

const TableWrapperWithFiltersGridMapOptions = (props: any) => {
  const subtitles = props.subtitles;
  const tableId = props.tableId;
  const data = props.data;
  const linkToCustomerTab = props.linkToCustomerTab;
  const classes = useStyles();
  const [isGrid, setIsGrid] = useState(true); // eventually we will want grid view and map view to be links

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <div>
          <ToolBar className={classes.toolbarOverride}>
            <div className={classes.actionBar}>
              <div className={classes.actionLeft}>
                <FilterListIcon
                  style={{
                    color: 'rgba(99, 102, 106, 0.48)',
                    fontSize: '2rem'
                  }}
                />
                <Typography>
                  <Button className={classes.actions}>Filters</Button>
                </Typography>
              </div>
              <div className={classes.actionRight}>
                <Typography>
                  <Button
                    className={
                      !isGrid ? classes.actions : classes.actionsSelected
                    }
                    onClick={() => setIsGrid(!isGrid)}
                  >
                    Grid View
                  </Button>
                </Typography>
                <FontAwesomeIcon
                  icon={faList}
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '7px',
                    fontSize: '23px',
                    color: '#888'
                  }}
                />
              </div>
              <div className={classes.actionRight}>
                <Typography>
                  <Button
                    className={
                      isGrid ? classes.actions : classes.actionsSelected
                    }
                    onClick={() => setIsGrid(!isGrid)}
                  >
                    Map View
                  </Button>
                </Typography>
                <FontAwesomeIcon
                  icon={faMapMarkedAlt}
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '7px',
                    fontSize: '23px',
                    color: '#888'
                  }}
                />
              </div>
            </div>
          </ToolBar>

          <TableTemplate
            hasPagination={true}
            linkToCustomerTab={linkToCustomerTab}
            data={data}
            subtitles={subtitles}
            tableId={tableId}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default TableWrapperWithFiltersGridMapOptions;
