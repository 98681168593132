import React, { Fragment } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { TabVerticalPanel } from './Routes';
import {
  faChartLine,
  faChartBar,
  faUserCog,
  faShoppingBasket,
  faBars
} from '@fortawesome/free-solid-svg-icons';

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: '0',
      backgroundColor: '#F5F5F5'
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: '#046a39',
      display: 'flex',
      maxWidth: '6em',
      minWidth: '6em',
      minHeight: '100vh'
    },
    tabLabel: {
      color: '#fff',
      fontSize: '1.75em',
      textAlign: 'center',
      textTransform: 'none',
      margin: '10px 0 10px 0',
      fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif;'
    },
    content: {
      marginTop: '4em',
      width: '90vw'
    }
  })
);

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'bars':
      return faBars;
    case 'chart-line':
      return faChartLine;
    case 'chart-bar':
      return faChartBar;
    case 'user-cog':
      return faUserCog;
    case 'shopping-basket':
      return faShoppingBasket;
    default:
      return faChartLine;
  }
};

const NavBarTemplate = (props: any) => {
  const { selectedTab, setSelectedTab, location, routes } = props;

  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <Tabs
          id="tabs"
          orientation="vertical"
          variant="scrollable"
          onChange={handleChange}
          value={
            selectedTab && location && location.state
              ? location.state.selectedTab
              : 0
          }
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#fff'
            }
          }}
        >
          <Tab
            style={{ minWidth: ' 6em ' }}
            label={<span className={classes.tabLabel}></span>}
            icon={
              <FontAwesomeIcon
                icon={faBars}
                style={{ fontSize: '4em', color: '#fff' }}
              />
            }
            component={Link}
            to={{
              pathname: '/',
              state: {
                selectedTab: -1,
                selectedTopNavTab: 0,
                selectedSubNavTab: 0
              }
            }}
            {...a11yProps(-1)}
          />
          {routes.map((route: any, index: number) => (
            <Tab
              key={route.tabLabel + '_' + index.toString()}
              style={{ minWidth: ' 6em ' }}
              label={<span className={classes.tabLabel}>{route.tabLabel}</span>}
              icon={
                <FontAwesomeIcon
                  icon={getIcon(route.icon)}
                  style={{ fontSize: '4em', color: '#fff' }}
                />
              }
              component={Link}
              to={{
                pathname: route.path,
                state: {
                  selectedTab: index + 1,
                  selectedTopNavTab: 0,
                  selectedSubNavTab: 0
                }
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        <Switch>
          {routes.map((route: any, index: number) => {
            return (
              <Route
                key={route.path}
                strict
                exact={route.exact}
                path={route.path}
                component={() => (
                  <TabVerticalPanel
                    value={index}
                    index={index}
                    children={route.children}
                  />
                )}
              />
            );
          })}
        </Switch>
      </Fragment>
    </div>
  );
};

export default withRouter(NavBarTemplate);
