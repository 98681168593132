import performanceOverviewJSON from './demo/performanceOverview.json';
import * as _ from 'lodash';

const getTrendData = (metadata: any) => () => ({
  totalPercent: makeTotalPercent(metadata.fetchData.field, 2019),
  totalPercentChange:
    makeTotalPercentChange(
      makeTotalPercent(metadata.fetchData.field, 2019),
      makeTotalPercent(metadata.fetchData.field, 2018)
    ) * 100,
  trend: makeTrend(metadata.fetchData.field)
});

const makeTotalPercent = (field: any, year: number) => {
  const yearData = performanceOverviewJSON.filter(
    (rowData: any) => year === rowData.year
  );
  const data =
    yearData.reduce(
      (acc: number, next: any) => Number(acc) + Number(next[field]),
      0
    ) / yearData.length;
  return data * 100;
};

const makeTotalPercentChange = (numer: number, denom: number) =>
  (numer - denom) / denom;

const makeTrend = (field: any) => {
  const products = _.uniqBy(performanceOverviewJSON, 'product').map(
    (uniqueObj: any) => uniqueObj.product
  );
  const years = _.uniqBy(performanceOverviewJSON, 'year').map(
    (uniqueObj: any) => uniqueObj.year
  );
  const data = years.map((year: any) => {
    return products
      .map((product: any) => ({
        year,
        // @ts-ignore
        [product]: performanceOverviewJSON.find(
          (rowData: any) => rowData.product === product && rowData.year === year
        )[field]
      }))
      .reduce((prev: any, next: any) => ({
        ...prev,
        ...next
      }));
  });
  return data;
};

export { getTrendData };
