import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useStyles1 } from './TableTemplate';
import { Fab, Typography } from '@material-ui/core';

function TablePaginationTemplate(props: any) {
  const classes = useStyles1();

  const { count, page, rowsPerPage, onChangePage } = props;
  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handlePageButtonClick = (newPage: number) => {
    return onChangePage(null, newPage);
  };
  const totalPages = Math.ceil(count / rowsPerPage);

  const getPageButtons = (totalPages: number, page: number) => {
    const buttons: object[] = [];
    const buttonsAndEllipsis = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      buttons.push(
        <Fab
          key={`page-${pageIndex + 1}-button`}
          size="small"
          onClick={() => handlePageButtonClick(pageIndex)}
          aria-label={`page-${pageIndex + 1}-button`}
          className={page === pageIndex ? classes.current : classes.enabled}
        >
          <Typography variant="subtitle2">{pageIndex + 1}</Typography>
        </Fab>
      );
    }
    if (buttons.length <= 6) {
      return buttons;
    }

    if (totalPages - page > 4 && page <= 2) {
      // beginning
      // < 1 2 3 4 .. 35 >
      buttonsAndEllipsis.push(
        buttons.filter((button: any, index: number) => index < 4)
      );
      buttonsAndEllipsis.push(
        <MoreHorizIcon key={'ellipsis'} className={classes.float} />
      );
      buttonsAndEllipsis.push(buttons.slice(buttons.length - 1));
      return buttonsAndEllipsis.flat();
    } else if (totalPages - page > 3) {
      //  middle
      // < 1 ... 17 18 19 ... 35 >
      buttonsAndEllipsis.push(buttons[0]);
      buttonsAndEllipsis.push(
        <MoreHorizIcon key={'ellipsis-0'} className={classes.float} />
      );
      buttonsAndEllipsis.push(
        buttons[page - 1],
        buttons[page],
        buttons[page + 1]
      );
      buttonsAndEllipsis.push(
        <MoreHorizIcon key={'ellipsis-1'} className={classes.float} />
      );
      buttonsAndEllipsis.push(buttons.slice(buttons.length - 1));
      return buttonsAndEllipsis.flat();
    } else {
      // end
      // < 1 ... 32 33 34 35 >
      buttonsAndEllipsis.push(buttons[0]);
      buttonsAndEllipsis.push(
        <MoreHorizIcon key={'ellipsis'} className={classes.float} />
      );
      buttonsAndEllipsis.push(
        buttons.slice(buttons.length - 4, buttons.length - 3),
        buttons.slice(buttons.length - 3, buttons.length - 2),
        buttons.slice(buttons.length - 2, buttons.length - 1),
        buttons.slice(buttons.length - 1)
      );
      return buttonsAndEllipsis.flat();
    }
  };

  return (
    <div className={classes.root}>
      <Fab
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className={
          page !== 0 ? classes.chevronEnabled : classes.chevronDisabled
        }
      >
        <ChevronLeftIcon
          fontSize="large"
          style={{
            color: page !== 0 ? '#888' : 'rgb(182, 182, 182)'
          }}
        />
      </Fab>
      {getPageButtons(totalPages, page)}
      <Fab
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className={
          !(page >= Math.ceil(count / rowsPerPage) - 1)
            ? classes.chevronEnabled
            : classes.chevronDisabled
        }
      >
        <ChevronRightIcon
          fontSize="large"
          style={{
            color: !(page >= Math.ceil(count / rowsPerPage) - 1)
              ? '#888'
              : 'rgb(182, 182, 182)'
          }}
        />
      </Fab>
    </div>
  );
}
export default TablePaginationTemplate;
