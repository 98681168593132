import { getRetailerInfo, getAllGrowerInfo } from './generatedBaseData';

const makeSalesTableData = (dataArray: any) => {
  let isGrower = false;
  const colTitles = Object.keys(dataArray[0])
    .map((column: any) => {
      switch (column) {
        case 'growerId':
        case 'sellerId':
        case 'productId':
        case 'id':
        case 'retailerName':
        case 'location':
        case 'currentYearOrders':
        case 'currentYearShipped':
        case 'previousYearOrders':
        case 'previousYearSales':
        case 'previousYearUnits':
        case 'currentYearUnits':
        case 'retailer':
          // do not include this column in the colTitles
          return '';
        case 'total':
          return { title: 'Total', column: column };
        case 'growerName':
          return { title: 'Grower', column: column };
        case 'sellerName':
          return { title: 'Seller', column: column };
        case 'productName':
          return { title: 'Crop', column: column };
        case 'currentYearOrders':
          return { title: 'CY Orders', column: column };
        case 'currentYearValue':
          return { title: 'CY Value', column: column };
        case 'currentYearShipped':
          return { title: 'CY Shipped Units', column: column };
        case 'previousYearOrders':
          return { title: 'PY Orders', column: column };
        case 'previousYearValue':
          return { title: 'PY Value', column: column };
        case 'previousYearShipped':
          return { title: 'PY Shipped Units', column: column };
        case 'segment':
          return { title: 'Segment', column: column };
        case 'shareOfWallet':
          return { title: 'Share of Wallet', column: column };
        case 'shareOfFarm':
          return { title: 'Share of Farm', column: column };
        case 'opportunity':
          return { title: 'Opportunity', column: column };
        case 'aggioRank':
          console.log('column',column)
          return { title: 'Aggio Rank', column: column };
        case 'retailerLocation':
          return { title: 'Retailer Location', column: column };
        case 'growerLocation':
          isGrower = true;
          return { title: 'Location', column: column };
        default:
          // if you see an unwanted column you probably want to add it to
          // the first few cases so it doesn't include it
          return { column: column };
      }
    })
    .filter((colTitle: any) => typeof colTitle === 'object');
  const getSalesRowData = (dataPoint: any) => {
    return colTitles.map((colTitleObj: any) => dataPoint[colTitleObj.column]);
  };
  const cellFormats = !isGrower
    ? [
        'number',
        'link',
        'string',
        'string',
        'dollar',
        'dollar',
        'shareOfWallet',
        'dollar'
      ]
    : [
        'number',
        'link',
        'string',
        'string',
        'string',
        'dollar',
        'dollar',
        'shareOfWallet',
        'dollar'
      ];
  return {
    colTitles,
    canSortColumns: true,
    rowInfo: dataArray.map((dataPoint: any) => {
      return {
        dependency: 'independent',
        rowType: 'multiType',
        cellFormats: cellFormats,
        rowTitle: null,
        boldLastColumn: true,
        values: getSalesRowData(dataPoint)
      };
    })
  };
};

export const salesPrioritizationRetailersTableData = (
  customerFilter: string
) => {
  const info = getRetailerInfo();
  return makeSalesTableData(
    info
      .concat(info)
      .concat(info)
      .concat(info)
      .map((dataPoint: any, index: number) => ({
        id: index,
        aggioRank: index + 1,
        retailerLocation: dataPoint.retailer,
        ...dataPoint
      }))
  );
};

export const salesPrioritizationGrowersTableData = (customerFilter: string) => {
  const info = getAllGrowerInfo();
  // annoyingly our data is not very diverse, we have repeated
  // grower names between different retailers
  // idk... it means it looks like repeat rows, but really each one
  // is unique by its retailer from the data... idk if it owuld be in real life or not
  return makeSalesTableData(
    info
      .concat(info)
      .concat(info)
      .concat(info)
      .map((grower: any, index: number) => {
        const someNumber = Math.floor(Math.random() * Math.floor(100));
        const opportunity = Math.floor(
          (grower.currentYearValue * 100) /
            ((someNumber !== 0 ? someNumber : 1) / 100)
        );

        return {
          aggioRank: index + 1,
          growerName: grower.growerName,
          growerLocation: grower.location,
          segment: someNumber < 50 && opportunity > 2000 ? 'GROW' : 'MAINTAIN',
          productName: grower.productName,
          currentYearValue: grower.currentYearValue,
          previousYearValue: grower.previousYearSales,
          shareOfFarm: someNumber,
          opportunity: opportunity
        };
      })
      .flat()
  );
};
