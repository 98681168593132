import {
  IAlternativeConfiguration,
  IRouteConfig
} from '../components/RoutingController';

export const getRelevantRoutes = (
  routeConfigItem: IRouteConfig,
  currentActorType: string,
) => {
  const rci = routeConfigItem;
  if (rci.routesConfigurations) {
    // find the routeConfiguration corresponding to the current actor type
    const relevantConfiguration = rci.routesConfigurations.find(
      (configuration: IAlternativeConfiguration) =>
        configuration.actorType === currentActorType
    );
    if (!relevantConfiguration || relevantConfiguration.useAll) {
      return rci.routes;
    }
    return rci.routes.filter(
      (route: any) => relevantConfiguration.useThese.indexOf(route.title) >= 0
    );
  }
  return rci.routes;
};
export const getRelevantComponents = (
  routeConfigItem: IRouteConfig,
  currentActorType: string,
) => {
  const rci = routeConfigItem;
  if (rci.componentsConfigurations) {
    // find the routeConfiguration corresponding to the current actor type

    const relevantConfiguration = rci.componentsConfigurations.find(
      (configuration: IAlternativeConfiguration) => {
        return (
          configuration.actorType === currentActorType
        );
      }
    );
    if (!relevantConfiguration || relevantConfiguration.useAll) {
      return rci.components;
    }
    return rci.components.filter(
      (component: any) =>
        relevantConfiguration.useThese.indexOf(component.name) >= 0
    );
  }
  return rci.components;
};
